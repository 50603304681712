import default_config from './config/default.json';

const getValue = (key, type) => {
  const value = process.env[`REACT_APP_EQP_${key}`] ?? default_config[`REACT_APP_EQP_${key}`];
  return type === 'bool' ? value?.toLowerCase() === 'true' :
    type === 'int' ? parseInt(value) :
      type === 'float' ? parseFloat(value) :
        value;
};

export const getServerURL = () => getValue('BACKEND_URL');
export const getIdleTimeout = () => getValue('IDLE_TIMEOUT', 'int');
export const getPromptTimeout = () => getValue('PROMPT_TIMEOUT', 'int');
export const getHttpRequestTimeout = () => getValue('HTTP_REQUEST_TIMEOUT', 'int');