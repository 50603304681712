import { useLayoutEffect, useEffect, useRef, useCallback, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import './RichTextEditor.css';
import { Box, Tooltip } from '@mui/material';
import Locked from '../../img/Locked';

const toolbarOptions = [
    ['bold', 'italic', 'underline'],  // toggled buttons
    [{ 'indent': '-1' }, { 'indent': '+1' }],  // indent and outdent
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],  // lists
    ['link']  // link button for creating hyperlinks
];

const formats = [
    'bold', 'italic', 'underline',
    'indent',
    'list',
    'link'
    // 'image' and 'video' are omitted to disallow images and videos
];

function RichTextEditor({
    value,
    onChange,
    disabled = false,
    showLockedIcon = false,
    lockedIconColor = undefined,
    lockedIconTooltip = undefined,
    ...otherProps
} = {}) {
    const editorRef = useRef(null);
    const quillRef = useRef(null);
    const Delta = Quill.import('delta');

    //necessary to track, because quill manipulates the DOM (editorRef) after first rendering, and make its children lost for react, generating errors.
    const [isEditorRefDefined, setIsEditorRefDefined] = useState(false);

    // Function to initialize Quill
    const initializeQuill = useCallback(() => {
        if (editorRef.current && !quillRef.current) {
            quillRef.current = new Quill(editorRef.current, {
                theme: 'snow',
                modules: {
                    toolbar: toolbarOptions,
                },
                formats: formats,
                // readOnly: disabled,  // Initialize with the correct disabled state
            });

            quillRef.current.on('text-change', () => {
                const delta = quillRef.current.getContents();
                const html = quillRef.current.getSemanticHTML(); // Get the HTML content
                onChange && onChange({ delta, html });
            });
            setIsEditorRefDefined(true);
        }
    }, []);

    // Cleanup function to destroy Quill instance
    const cleanupQuill = useCallback(() => {
        if (quillRef.current) {
            quillRef.current.off('text-change');
            quillRef.current = null;
        }
    }, []);

    // Initialize and cleanup Quill
    useLayoutEffect(() => {
        initializeQuill();
        return () => {
            cleanupQuill();
        };
    }, [initializeQuill, cleanupQuill]);

    // Handle updates to the value prop
    useEffect(() => {
        const currentContent = quillRef.current.getContents();
        if (value && currentContent) {
            const valueDelta = new Delta(value);
            // Use Delta's `diff` method to compare the current content with the incoming `value`
            const diff = currentContent.diff(valueDelta);
            if (diff.ops.length > 0) {
                quillRef.current.setContents(valueDelta);
            }
        }
    }, [value]);

    useEffect(() => {
        quillRef.current?.enable(!disabled);
    }, [disabled]);


    return (
        <Box sx={{ flexGrow: 1, display: "block" }} className="quill-container">
            <Box ref={editorRef} style={{ width: "100%", position: "relative" }} {...otherProps}>
                {(showLockedIcon && isEditorRefDefined) &&
                    <Tooltip title={lockedIconTooltip ? lockedIconTooltip : ""}>
                        <Box sx={{ position: 'absolute', top: 10, right: 10, padding: '2px' }}>
                            <Locked color={lockedIconColor} />
                        </Box>
                    </Tooltip>
                }
            </Box>
        </Box>
    );
}

const getEmptyDelta = () => {
    return { ops: [] };
}

function isEmptyDelta(delta) {
    if (!delta?.ops) return true;
    if (delta.ops.length === 0) return true;
    return delta.ops.every(op => {
        if (op.insert) {
            return op.insert.trim() === '' || op.insert.trim() === '\n';
        }
        return true;
    });
}

export { RichTextEditor, getEmptyDelta, isEmptyDelta };
