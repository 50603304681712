//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import BarChartIcon from '@mui/icons-material/BarChart';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useForm } from "react-hook-form";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { usePaths } from "../../context/PathsProvider";
import { useHttp } from "../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import HappyFace from "../../img/HappyFace";
import NeutralFace from "../../img/NeutralFace";
import SadFace from "../../img/SadFace";


//*********************************************************************************************************************************************//
//                                                           custom HOOKS imports                                                              //
//*********************************************************************************************************************************************//

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//




const GradeSmiley = ({ grade }) => {
    if (!grade) return null;
    let smileyIcon = undefined;
    if (grade < 2) {
        smileyIcon = <SadFace text={grade?.toFixed(1)} />
    }
    else if (grade < 3) {
        smileyIcon = <NeutralFace text={grade?.toFixed(1)} />
    }
    else {
        smileyIcon = <HappyFace text={grade?.toFixed(1)} />
    }

    return (
        <>
            {smileyIcon}
        </>
    );
}


export { GradeSmiley }