import { useTheme } from "@mui/material";

const NeutralFace = ({ text }) => {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 46" //set viewBox to 0 0 svgXSize svgYSize and svg can be size by parent with CSS
            style={{ display: "block" }} //and set display to block !
        >
            <path
                fill="#FF9E29"
                d="M24 25.796a12.5 12.5 0 1 1 0-25 12.5 12.5 0 0 1 0 25Zm-4.375-13.75a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Zm8.75 0a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Zm-8.125 5a1.25 1.25 0 0 0 0 2.5h7.5a1.25 1.25 0 0 0 0-2.5h-7.5Z"
            />
            {/* <rect width={47} height={19} x={0.708} y={26.51} fill="#FF9E29" rx={9.5} /> */}
            <rect width={47} height={19} x={0.4} y={26.939} fill={theme.palette.orange.primary.main} rx={9.5} />
            <text
                x="50%" // Position the text in the middle of the SVG
                y="37px" // Position the text in the middle of the SVG
                dominantBaseline="middle" // Vertically center the text
                textAnchor="middle" // Horizontally center the text
                fill="#fff" // Text color
                fontSize="16" // Text size, adjust as needed
            >
                {text}
            </text>
        </svg>
    )
}
export default NeutralFace