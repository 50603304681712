import { useTheme } from "@mui/material";

const CircularProgress = ({ value, progressColor, backgroundColor }) => {
    const theme = useTheme();
    const size = 50; // Size of the SVG / circle
    const strokeWidth = 25; // Width of the circle stroke
    const radius = (size - strokeWidth) / 2; // Radius of the circle
    const circumference = radius * 2 * Math.PI; // Circumference of the circle
    const offset = circumference - (value / 100) * circumference; // Offset of the arc

    value = value.toFixed(0);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox='0 0 50 50'
            style={{ display: "block" }}
        >
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                strokeWidth={strokeWidth}
                fill="transparent"
                stroke={backgroundColor}// Light blue portion
            />
            {/* Foreground arc */}
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                strokeWidth={strokeWidth}
                fill="transparent"
                stroke={progressColor}
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                transform={`rotate(-90 ${size / 2} ${size / 2})`} // Start from the top
            />
            {/* Text in the center */}
            <text
                x="50%"
                y="50%"
                dominantBaseline="central"
                textAnchor="middle"
                fontSize="18"
                fill="white"
            >
                {`${value}%`}
            </text>
        </svg >
    );
};

export { CircularProgress };