import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CircularProgressBar = ({ progress, disabled, size = undefined, fontSize = undefined }) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
            <Box position="relative" display="inline-flex">
                <CircularProgress
                    sx={{ color: disabled ? "#e0e0e0" : '#d5d5d5' }} // Background color
                    variant="determinate"
                    value={100}
                    size={size}
                    thickness={size / 10 || undefined}
                />
                <CircularProgress
                    sx={{ color: disabled ? "#b0b0b0" : '#4caf50', position: 'absolute' }} // Green for the completed part
                    variant="determinate"
                    value={progress}
                    size={size}
                    thickness={size / 10 || undefined}
                />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" color="textSecondary" sx={{ fontSize: fontSize }}>
                        {`${Math.round(progress)}%`}
                    </Typography>
                </Box>
            </Box>
        </Box >
    );
};

export { CircularProgressBar };
