


class Observation {
    constructor(o) {
        this.question = o?.question;
        this.rbpp = o?.rbpp;
        this.requested_response = o?.requested_response;
        this.nb_responses = o?.nb_responses;
        this.concerned_activities = o?.concerned_activities;
        this.is_archived = o?.is_archived || false;
        this.immutable_id = o?.immutable_id;
        this.group = o?.group;
        //only for client needs
        this.people = o?.nb_responses === 3;
        this.responseArray = o?.nb_responses ? Array.from({ length: o?.nb_responses }, (_, i) => i + 1) : [];
    }
}

export { Observation };