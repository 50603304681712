
import FileDefault from "../img/FileDefault";
import FileExcel from "../img/FileExcel";
import FilePdf from "../img/FilePdf";
import FileWord from "../img/FileWord";


const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1);
}

const getRandomChar = (charList) => {
    let randomChar = charList[Math.round(Math.random() * charList.length)];
    return randomChar;
}

const shuffleString = (string) => {
    let s = string.split("");
    s.sort(() => {
        return 0.5 - Math.random();
    })
    s = s.join("");
    return s;
}

const generatePassword = () => {
    const numbers = "0123456789";
    const lowercaseLetters = "azertyuiopqsdfghjklmwxcvbn";
    const uppercaseLetters = lowercaseLetters.toUpperCase();
    const specialChars = "?!&%*#";
    const allChars = numbers + lowercaseLetters + uppercaseLetters + specialChars;
    let orderderedPassword = "";
    orderderedPassword = getRandomChar(numbers);
    orderderedPassword += getRandomChar(lowercaseLetters);
    orderderedPassword += getRandomChar(uppercaseLetters);
    orderderedPassword += getRandomChar(specialChars);
    for (let i = 0; i < 4; i++) {
        orderderedPassword += getRandomChar(allChars);
    }
    let password = shuffleString(orderderedPassword);
    return password;
}

const getElapsedTimeInDays = (date1, date2 = undefined) => {
    const day = 86400;
    if (!date2) {
        date2 = Date.now();
    }
    const elapsedTime = (date2 - date1) / 1000;
    if (elapsedTime < 0) return "";
    const elapsedDays = Math.floor(elapsedTime / day);
    return elapsedDays;
}


//return true if key / value pairs are the same, false otherwise
//compareObjectKeyValuePairs only compare first parameter object keys
//takes as parameter an array of keys to ignore that supports dot notation
//ex: keysToIgnore = ['b.d[0]', 'b.d[1].x'];
//if necessary to ignore all the elements of an array, use * like keysToIgnore = ['b.d[*].x'];
//if falsyEquals === true, will evaluate all falsy values as equals
function compareObjectsOld({ obj1, obj2, keysToIgnore = [], falsyEquals = false, path = '' } = {}) {
    for (let key in obj1) {
        const fullPath = path ? `${path}.${key}` : key;
        if (keysToIgnore.includes(fullPath)) {
            continue;  // Skip comparison for ignored keys
        }

        // Check for falsy equality if specified
        if (falsyEquals && !obj1?.[key] && !obj2?.[key]) {
            continue;  // Skip to next key if both values are falsy
        }

        // If the key doesn't exist in obj2 or the values are different, return false
        if (!obj2 || !(key in obj2) || obj1[key] !== obj2[key]) {
            if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
                // If both values are arrays, compare their elements
                if (obj1[key].length !== obj2[key].length) return false;
                for (let i = 0; i < obj1[key].length; i++) {
                    const arrayPath = `${fullPath}[${i}]`;
                    if (!compareObjects({ obj1: obj1[key][i], obj2: obj2[key][i], keysToIgnore: keysToIgnore, falsyEquals: falsyEquals, path: arrayPath })) return false;
                }
            } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object' && obj1[key] !== null && obj2[key] !== null) {
                // If both values are objects, compare them recursively
                if (!compareObjects({ obj1: obj1[key], obj2: obj2[key], keysToIgnore: keysToIgnore, falsyEquals: falsyEquals, path: fullPath })) return false;
            } else {
                // If the values are different and not both arrays or objects, return false
                return false;
            }
        }
    }
    return true;
}

//return true if key / value pairs are the same, false otherwise
//if includeObj2Keys === false, compareObject only compare obj1 keys, obj1 keys and obj2 keys otherwise
//example: obj1 = {key1, key2: {key2.1}}, obj2={key2: {key2.1, key2.2}, key3} => will ignore key3 and key2.2
//takes as parameter an array of keys to ignore that supports dot notation
//ex: keysToIgnore = ['b.d.[0]', 'b.d.[1].x'];
//if an element of keysToIgnore starts with "*." like keysToIgnore = ['*._id'], then _id field will be ignored in all objects in the tree (globaly)
//path only necessary for recusrive reasons. Never call the function with path attribute
//if falsyEquals === true, will evaluate all falsy values as equals
//if checkOrderInArrays === false, the function will check if every item in the first array exists in the second array, regardless of the order
function compareObjects({
    obj1,
    obj2,
    keysToIgnore = [],
    falsyEquals = false,
    path = '',
    checkOrderInArrays = true,
    includeObj2Keys = false
} = {}) {

    function isPrimitive(value) {
        return value !== Object(value);
    }

    //if both are primitives, just make simple comparison
    if (isPrimitive(obj1) && isPrimitive(obj2)) {
        if (falsyEquals && (!obj1 && !obj2)) return true;
        return obj1 === obj2;
    }

    // Handle the case where both are undefined or null or when falsyEquals is true and both are falsy
    if (falsyEquals ? !obj1 && !obj2 : obj1 === obj2) return true;

    // Handle the case where one is undefined/null and the other is not
    if ((obj1 == null && obj2 != null) || (obj1 != null && obj2 == null)) return false;

    //handle if type is different
    if (typeof obj1 !== typeof obj2) return false;

    //handle if only one of them is an array
    if ((Array.isArray(obj1) && !Array.isArray(obj2)) || (!Array.isArray(obj1) && Array.isArray(obj2))) return false;

    // Handle if both are arrays: we wrap both arrays in 2 objects with a single key and rerun the compareObjects function
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        return compareObjects({
            obj1: { __array__: obj1 },
            obj2: { __array__: obj2 },
            keysToIgnore: keysToIgnore,
            falsyEquals: falsyEquals,
            checkOrderInArrays: checkOrderInArrays,
            includeObj2Keys: includeObj2Keys
        });
    }

    const isPathIgnored = (path, key) => keysToIgnore.some(ignoredPath => {
        // Check if the ignored path matches the current path and key
        const fullPath = path ? `${path}.${key}` : key;
        if (ignoredPath.startsWith('*.')) {
            const globalKey = ignoredPath.slice(2);
            return key === globalKey;
        }
        return ignoredPath === fullPath || ignoredPath.startsWith(fullPath + '.');
    });

    const obj1Keys = Object.keys(obj1).filter(key => !isPathIgnored(path, key));
    const obj2Keys = Object.keys(obj2).filter(key => !isPathIgnored(path, key));

    // Quick checks for optimization
    const filteredObj1Keys = obj1Keys.filter(key => falsyEquals ? obj1[key] : obj1[key] !== undefined);
    const filteredObj2Keys = obj2Keys.filter(key => falsyEquals ? obj2[key] : obj2[key] !== undefined);

    if (includeObj2Keys && filteredObj1Keys.length !== filteredObj2Keys.length) return false;
    if (!filteredObj1Keys.every(key => filteredObj2Keys.includes(key))) return false;

    const loopKeys = includeObj2Keys ? Array.from(new Set([...filteredObj1Keys, ...filteredObj2Keys])) : filteredObj1Keys;

    for (let key of loopKeys) {
        const fullPath = path ? `${path}.${key}` : key;

        if (falsyEquals && !obj1[key] && !obj2[key]) continue;

        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
            if (obj1[key].length !== obj2[key].length) return false;
            if (checkOrderInArrays) {
                for (let i = 0; i < obj1[key].length; i++) {
                    const arrayPath = `${fullPath}[${i}]`;
                    if (!compareObjects({
                        obj1: obj1[key][i],
                        obj2: obj2[key][i],
                        keysToIgnore: keysToIgnore,
                        falsyEquals: falsyEquals,
                        path: arrayPath,
                        checkOrderInArrays: checkOrderInArrays,
                        includeObj2Keys: includeObj2Keys
                    })) return false;
                }
            } else {
                let itemToRemoveIndex = undefined;
                for (let item of obj1[key]) {
                    if (itemToRemoveIndex) {
                        obj2[key].splice(itemToRemoveIndex, 1);
                    }
                    itemToRemoveIndex = undefined;
                    if (!obj2[key].some((otherItem, index) => {
                        const isMatch = compareObjects({
                            obj1: item,
                            obj2: otherItem,
                            keysToIgnore: keysToIgnore,
                            falsyEquals: falsyEquals,
                            checkOrderInArrays: checkOrderInArrays,
                            includeObj2Keys: includeObj2Keys
                        });
                        if (isMatch) {
                            itemToRemoveIndex = index;
                        }
                        return isMatch;
                    })) return false;
                }
            }
        } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object' && obj1[key] !== null && obj2[key] !== null) {
            if (!compareObjects({
                obj1: obj1[key],
                obj2: obj2[key],
                keysToIgnore: keysToIgnore,
                falsyEquals: falsyEquals,
                path: fullPath,
                checkOrderInArrays: checkOrderInArrays,
                includeObj2Keys: includeObj2Keys
            })) return false;
        } else if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

const getDateString = (date) => {
    if (!date) return "";
    let tmp = new Date(date);
    return tmp.toLocaleDateString('fr-FR', { year: '2-digit', month: '2-digit', day: '2-digit' });
}

// Normalize searchFilter and originalText to remove accents and handle case insensitivity
const removeAccents = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

//gets originalText and searchFilter string, split string by " " and return jsx with matches highlighted
function highlightText(originalText, searchFilter) {
    if (!searchFilter) return originalText;

    const searchTerms = removeAccents(searchFilter).split(/\s+/).filter(Boolean);
    const normalizedText = removeAccents(originalText);

    // Split the originalText into parts and map over them to insert <mark> tags
    const parts = normalizedText.split(new RegExp(`(${searchTerms.join('|')})`, 'gi'));

    return (
        <>
            {parts.map((part, index) => {
                const isMatch = searchTerms.some(term => term.toLowerCase() === part.toLowerCase());
                return isMatch ? <mark key={index}>{originalText.substr(normalizedText.indexOf(part), part.length)}</mark> : originalText.substr(normalizedText.indexOf(part), part.length);
            })}
        </>
    );
}

function getScrollbarWidth() {
    // Create a temporary div element
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // Add scroll to the div
    document.body.appendChild(outer);

    // Create another inner div
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculate the difference between the outer and inner div width
    // This difference is the scrollbar width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Remove the temporary divs
    outer.parentNode.removeChild(outer);
    return scrollbarWidth;
}

const getFileIcon = (fileType, props = {}) => {
    let { color = "#000000", ...otherProps } = props;
    switch (fileType) {
        case "pdf": return <FilePdf color={color} props={otherProps} />;
        case "doc": return <FileWord color={color} props={otherProps} />;
        case "docx": return <FileWord color={color} props={otherProps} />;
        case "xls": return <FileExcel color={color} props={otherProps} />;
        case "xlsx": return <FileExcel color={color} props={otherProps} />;
        default: return <FileDefault color={color} props={otherProps} />;
    }
}

//path must be result of location.pathname
const parseCustomerHid = (path) => {
    const segments = path.split("/");
    return segments[1];
}


export {
    capitalizeFirstLetter,
    generatePassword,
    getElapsedTimeInDays,
    compareObjects,
    getDateString,
    removeAccents,
    highlightText,
    getScrollbarWidth,
    getFileIcon,
    parseCustomerHid,
};