import { Box } from "@mui/material";


const RadioIcon = () => {

    return (
        // <Box sx={{ width: "24px", height: "24px", marginRight: "6px" }}>
        //     <svg
        //         xmlns="http://www.w3.org/2000/svg"
        //         fill="none"
        //         viewBox="0 0 30 30"
        //         style={{ display: "block" }}
        //     >
        //         <rect width={28} height={28} x={1.208} y={0.5} stroke="currentColor" rx={14} />
        //     </svg>
        // </Box>
        <Box sx={{ width: "30px", height: "24px" }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 37 30"
                fill="none"
                style={{ display: "block" }}
            >
                <rect width={28} height={28} x={1.208} y={0.5} stroke={"currentColor"} rx={14} strokeWidth="2" />
            </svg>
        </Box>
    )
}


export default RadioIcon;