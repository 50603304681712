import { useState, useEffect, forwardRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

const AutocompleteWithAllOption = forwardRef(({
    options,
    getOptionLabel,
    isOptionEqualToValue,
    onChange,
    value,
    label,
    limitTags = 5,
    renderTags,
    onOpen,
    onClose
}, ref) => {
    const [wasAllSelected, setWasAllSelected] = useState(false);

    const allOptions = Array.isArray(options) ? options : Object.keys(options);

    useEffect(() => {
        if (!value?.length || !allOptions?.length) return;
        if (value?.length === allOptions?.length) {
            setWasAllSelected(true);
        } else {
            setWasAllSelected(false);
        }
    }, [value, allOptions])

    return (
        <Autocomplete
            multiple
            limitTags={limitTags}
            disableCloseOnSelect
            options={["All", ...allOptions]}
            getOptionLabel={(option) => {
                if (option === "All") {
                    return value.length === allOptions.length ? "Tout décocher" : "Tout cocher";
                }
                return getOptionLabel(option);
            }}
            isOptionEqualToValue={(option, value) => isOptionEqualToValue ? isOptionEqualToValue(option, value) : option === value}
            onChange={(_, newValue) => {
                let sanitizedValue = newValue.filter(value => value !== "All");
                let isAllSelected = newValue.includes("All");
                if (!wasAllSelected && !isAllSelected) {
                    if (newValue.length === allOptions.length) {
                        setWasAllSelected(true);
                    }
                    onChange(sanitizedValue);
                }
                if (wasAllSelected && !isAllSelected) {
                    setWasAllSelected(false);
                    onChange([]);
                }
                if (wasAllSelected && isAllSelected) {
                    setWasAllSelected(false);
                    onChange(sanitizedValue);
                }
                if (!wasAllSelected && isAllSelected) {
                    setWasAllSelected(true);
                    onChange(allOptions);
                }
            }}
            onOpen={onOpen}
            onClose={onClose}
            value={value.length === allOptions.length ? ['All', ...value] : value}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox checked={selected} />
                    {option === 'All' ? (value.length === allOptions.length ? "Tout décocher" : "Tout cocher") : getOptionLabel(option)}
                </li>
            )}
            renderTags={renderTags}
            renderInput={(params) => {
                let filteredstartAdornment = params?.InputProps?.startAdornment?.filter(e => (e?.props?.label !== "Tout décocher" && e?.props?.label !== "Tout cocher"));
                let filteredParams = { ...params, InputProps: { ...params?.InputProps, startAdornment: filteredstartAdornment } };
                return <TextField {...filteredParams} variant="standard" label={label} inputRef={ref} />
            }}
        />
    );
});

export { AutocompleteWithAllOption };