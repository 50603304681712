
import { useTheme } from "@mui/material";


const ImperativeSign = () => {
    const theme = useTheme();
    const color = theme.palette.pink.secondary.tint1.main;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 23"
            fill="none"
            style={{ display: "block" }}
        >
            <circle cx="12" cy="12" r="11" fill={color} />
            <text
                x="12px"
                y="18px"
                textAnchor="middle"
                fill="white"
                fontSize="16"
                fontWeight="bold"
            >
                !
            </text>
        </svg>
    );
}


export { ImperativeSign }