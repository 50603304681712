//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useLayoutEffect, useEffect, useRef, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';//eval list


//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { usePaths } from "../../../context/PathsProvider";
import { useHttp } from "../../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { AuthorizationChecker } from "../../../components/AuthorizationChecker";
import useFileDownloader from "../../../hooks/useFileDownloader";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../../config";



const EvalActionButtons = () => {

    const { lists, customer_hid, selectedEstablishment, user, isLoggedIn } = useUser();
    const { httpRequest } = useHttp();
    const navigate = useNavigate();
    const { paths } = usePaths();
    const { downloadFileBuffer } = useFileDownloader();

    const [isLoading, setIsLoading] = useState(undefined);
    const [graphData, setGraphData] = useState(undefined);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const referential_ImID = params.get('referential_ImID');

    useLayoutEffect(() => {
        if (!isLoggedIn || !customer_hid || !referential_ImID) return;
        const fetchGraphData = async () => {
            setIsLoading(true);
            let response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${referential_ImID}/dashboard/eval_date`,
                method: 'get',
                showLoading: false,
                withCredentials: true
            });
            if (response.status === 200) {
                setGraphData(response.data);
            }
            setIsLoading(false);
        }
        fetchGraphData();
    }, [customer_hid, referential_ImID, selectedEstablishment, isLoggedIn])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: "100%", height: "100%" }}>
            <Typography sx={{ color: '#666', fontSize: "18", fontWeight: "bold" }}>
                Actions
            </Typography>
            <Box sx={{ display: "block", margin: "auto" }}>
                <AuthorizationChecker
                    module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                    baseAction={lists?.BASE_ACTION_VALUES["RDO"]}
                >
                    <Tooltip
                        title={"Naviguer vers liste évaluations"}
                    >
                        <IconButton
                            onClick={() => {
                                return navigate(paths.evalList.app({
                                    establishment_id: selectedEstablishment._id,
                                    customer_hid: customer_hid,
                                }))
                            }}
                        >
                            <AssignmentIcon />
                        </IconButton>
                    </Tooltip>
                </AuthorizationChecker>
                <AuthorizationChecker
                    module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                    baseAction={lists?.BASE_ACTION_VALUES["RDO"]}
                >
                    <Tooltip
                        title={"Accéder à l'évaluation"}
                    >
                        <IconButton
                            onClick={() => navigate(paths.evalCockpit.app({
                                establishment_id: selectedEstablishment._id,
                                customer_hid: customer_hid,
                                referential_ImID: referential_ImID
                            }))}
                        >
                            {graphData?.is_archived?.value ? <VisibilityIcon /> : <EditIcon />}
                        </IconButton>
                    </Tooltip>
                </AuthorizationChecker>
                <AuthorizationChecker
                    module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                    baseAction={lists?.BASE_ACTION_VALUES["RDO"]}
                >
                    {user?.job_groups?.value?.some(e => (["0", "1"].includes(e))) &&
                        <Tooltip
                            title={"Exporter évaluation"}
                        >
                            <IconButton
                                onClick={async () => {
                                    let response = await httpRequest({
                                        url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${referential_ImID}/export`,
                                        method: 'get',
                                        responseType: "blob",
                                        showLoading: true,
                                        withCredentials: true
                                    });
                                    if (response.status === 200) {
                                        const today = new Date();
                                        const year = today.getFullYear();
                                        const month = String(today.getMonth() + 1).padStart(2, '0');
                                        const day = String(today.getDate()).padStart(2, '0');
                                        const formattedDate = `${year}/${month}/${day}`;
                                        downloadFileBuffer(response.data, `${formattedDate}_autoEval_export.docx`);
                                    }
                                }}
                            >
                                <CloudDownloadIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </AuthorizationChecker>
            </Box>
        </Box>
    );
};

export { EvalActionButtons };