

class UserActionInfo {
    constructor(u) {
        this.by = {
            id: u?.by?.id,
            firstname: u?.by?.firstname,
            lastname: u?.by?.lastname,
            email: u?.by?.email,
            job: u?.by?.job,
            organization: u?.by?.organization
        };
        this.on = u?.on
    }
}

export { UserActionInfo }