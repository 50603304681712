import { useCallback } from 'react';
import { useToast } from '../context/ToastProvider';

function useFileDownloader() {
    const { presentToast } = useToast();
    const downloadFileUrl = useCallback((url) => {
        try {
            const a = document.createElement('a');
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            presentToast({
                message: "Erreur impossible de télécharger le fichier",
                severity: "error"
            });
        }
    }, []);

    const downloadFileBuffer = useCallback((buffer, filename) => {
        try {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const a = document.createElement('a');
            a.href = url;
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            presentToast({
                message: "Erreur impossible de télécharger le fichier",
                severity: "error"
            });
        }
    }, []);

    return { downloadFileUrl, downloadFileBuffer };
}

export default useFileDownloader;