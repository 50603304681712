import { Box } from "@mui/material";


const AddCircleIcon = ({ color }) => {

    return (
        <Box sx={{ width: "24px", height: "24px" }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 30 30"
                style={{ display: "block" }}
            >
                <rect
                    width={28}
                    height={27}
                    x={1.208}
                    y={1}
                    fill="transparent"
                    fillOpacity={0.05}
                    rx={13.5}
                />
                <rect
                    width={28}
                    height={27}
                    x={1.208}
                    y={1}
                    stroke="currentColor"
                    strokeWidth={2}
                    rx={13.5}
                />
                <path
                    fill="currentColor"
                    d="M14.264 17.004V14.7h-2.112v-1.56h2.112v-2.304h1.728v2.304h2.112v1.56h-2.112v2.304h-1.728Z"
                />
            </svg>
        </Box>
    )
}


export default AddCircleIcon;