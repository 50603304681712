//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, createContext, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../context/UserProvider";
import { useHttp } from "../context/HttpProvider";
import { useToast } from "../context/ToastProvider";
import { useDialog } from "../context/DialogProvider";
import { usePaths } from "../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import CONFIG from "../config/default";
import { getElapsedTimeInDays, compareObjectKeyValuePairs } from '../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { getPriorityScore, PaqEntry } from '../models/Paq';






const RadioDialog = ({ isOpen, title, setRadioValue, radioValue, radioList, buttons, dismissRadioDialog }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                aria-labelledby="responsive-dialog-title"
                onClose={dismissRadioDialog}
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={radioValue}
                            onChange={event => { setRadioValue(event?.target?.value) }}
                        >
                            {Object.keys(radioList)?.map((key) => {
                                return (
                                    <FormControlLabel
                                        key={radioList[key]?.value ? radioList[key].value : key}
                                        //If there is a value specified, it is taken into account. Othewise, key is the value
                                        value={radioList[key]?.value ? radioList[key].value : key}
                                        control={<Radio />}
                                        label={radioList[key].label} />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    {buttons?.length > 0 ?
                        buttons?.map((button, index) => {
                            return (
                                <Button
                                    // autoFocus={button?.role === "confirm"}
                                    key={button?.text + index}
                                    onClick={async () => {
                                        if (!button?.handler) dismissRadioDialog()
                                        else {
                                            let handlerReturn = await button.handler(radioValue);
                                            if (handlerReturn === undefined || handlerReturn === true) dismissRadioDialog();
                                        }
                                    }}
                                >
                                    {button?.text}
                                </Button>
                            )
                        })
                        :
                        <Button
                            autoFocus
                            onClick={dismissRadioDialog}
                        >
                            OK
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}

export { RadioDialog }