//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useRef, useCallback, createContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

import RadioIcon from "../../../icons/RadioIcon";
import CheckedRadioIcon from "../../../icons/CheckedRadioIcon";
import AddCircleIcon from "../../../icons/AddCircleIcon";

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { MRT_Localization_FR } from 'material-react-table/locales/fr';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { useHttp } from "../../../context/HttpProvider";
import { useToast } from "../../../context/ToastProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { GradeSmiley } from "../GradeSmiley";
import { CircularProgress } from "../CircularProgress";
import { RichTextEditor, isEmptyDelta } from "../../../components/RichTextEditor/RichTextEditor";
// import { RichTextEditor, isEmptyDelta } from "../../../components/RichTextEditor/RichTextEditor";
import { LinkProofDocDialog } from "./LinkProofDocDialog";
import { AuthorizationChecker } from "../../../components/AuthorizationChecker";

//*********************************************************************************************************************************************//
//                                                             CUSTOM HOOKS imports                                                            //
//*********************************************************************************************************************************************//
import useWindowSize from "../../../hooks/useWindowSize";
import useFileDownloader from "../../../hooks/useFileDownloader";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../../config";
import { getDateString, getFileIcon } from "../../../lib/lib";
import { Accordion, AccordionDetails, AccordionSummary, Chip, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Input, List, ListItemButton, ListItemIcon, ListItemText, Radio, RadioGroup, Slider, TextareaAutosize, Tooltip } from "@mui/material";
import CheckedLocked from "../../../img/CheckedLocked";
import Locked from "../../../img/Locked";



// const FilesListManagerContext = createContext();

const ObservationRenderer = ({ criterion, observation, observationIndex, isCriterionApproved, hidden }) => {


    const { control, formState } = useFormContext();
    const theme = useTheme();
    const { lists, isAuthorized, customer_hid, selectedEstablishment } = useUser();
    const { httpRequest } = useHttp();
    const { presentToast } = useToast();
    const emptyLinkProofDocDialogState = { isOpen: false, responseNumber: undefined, observation_ImID: undefined, filterValue: "" }
    const [linkProofDocDialogState, setLinkProofDocDialogState] = useState(emptyLinkProofDocDialogState);
    const levelRef = useRef(null);
    const proofRef = useRef(null);
    const containerLevelProofRef = useRef(null);
    const windowSize = useWindowSize(); //declared here just to trigger rerender at window resize
    const { downloadFileUrl } = useFileDownloader();

    const elementMargin = theme.spacing(2);
    const elementMarginValue = parseInt(elementMargin);
    const groupMargin = theme.spacing(4);
    const proofListWrapWidth = 300; //in pixels, the min size for the proof list. Below it's wrapped, above it's not.

    const mainColor = theme.palette.forest.primary.main;
    const mainColordisabled = theme.palette.forest.primary.shades["50%"];
    const lockColor = theme.palette.pink.secondary.tint1.main;

    const isReaderOnly = !isAuthorized({ module: lists?.LICENSE_MODULE_VALUES["AEV"], baseAction: lists?.BASE_ACTION_VALUES["WRT"] });


    //init arrays for useWatch hook
    //default values for level field are set in EvalCriterion, in fetchCriterionData function so they are available in useWatch hook before first render
    //otherwise, observationGrade and observation progress are undefined at first render
    let fieldNames = Array.from({ length: observation.nb_responses }, (_, i) => `level/${observation.immutable_id}/${i + 1}`);
    //useWatch defaultValue parameter only accpet a unique value, not an object like useForm defaultValues does...

    let gradeKeys = useWatch({
        control,
        name: fieldNames,
    });


    const getobservationervationGrade = (observation) => {
        if (!observation?.nb_responses || !observation?.immutable_id) return undefined;

        let grades = Array.from({ length: observation.nb_responses }, (_, i) => {
            return lists?.RESPONSE_LEVEL_LIST?.[gradeKeys[i]]?.grade
        })
        let validGrades = grades.filter(Boolean);
        if (!validGrades?.length) return undefined;
        return validGrades.reduce((a, b) => a + b, 0) / validGrades?.length;
    }

    const getObservationProgress = () => {
        if (!observation?.nb_responses || !observation?.immutable_id) return 0;

        let values = Array.from({ length: observation.nb_responses }, (_, i) =>
            gradeKeys[i]
        )
        let validValues = values.filter(Boolean);
        return validValues.reduce((a, b) => a + 100, 0) / observation.nb_responses;
    }

    const areAllGradesDefined = () => {
        return gradeKeys?.every(e => !!e);
    }

    let statusWatch = useWatch({
        control,
        name: `status/${observation?.immutable_id}.value`
    });

    const isObsVerified = () => {
        return statusWatch === lists?.RESPONSE_STATUSES["VERIFIED"];
    }

    const handleClickDownload = async (event, userFile) => {
        event.stopPropagation();
        //fetch signed url for this blob
        let response = await httpRequest({
            url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/user_files/${userFile?.name}/get_signed_url`,
            method: 'get',
            showLoading: true,
            withCredentials: true
        });
        if (response.status === 200) {
            const signedUrl = response.data?.signedUrl;
            if (signedUrl) {
                downloadFileUrl(signedUrl);
            } else {
                presentToast({
                    message: "Erreur impossible de télécharger le fichier",
                    severity: "error"
                })
            }
        }
    }

    return (
        <>
            <Accordion
                sx={{
                    background: theme.palette.greenLemon.primary.shades["5%"],
                    border: `1px solid ${theme.palette.greenLemon.primary.shades["5%"]}`,
                    borderRadius: "2px",
                    marginBottom: groupMargin,
                    display: hidden ? "none" : "",

                }}
                slotprops={{ transition: { unmountOnExit: true } }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: theme.palette.greenLemon.primary.main }} />}
                    sx={{
                        position: 'sticky',
                        top: 48,
                        zIndex: 100,
                        background: 'rgb(244, 247, 234)',
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Typography
                            variant="h7"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                color: mainColor,
                            }}>
                            {criterion?.chapterData?.number}.{criterion?.objectiveData?.number}.{criterion.number}.{observationIndex + 1} {observation.question}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                            <Tooltip title="Note sur 4" arrow>
                                <Box style={{ width: "40px", height: "40px", marginLeft: "12px" }}>
                                    <GradeSmiley grade={getobservationervationGrade(observation)} />
                                </Box>
                            </Tooltip>
                            <Tooltip title={isObsVerified() ? "Le constat est vérifié" : "Taux de remplissage"} arrow>
                                <Box style={{ width: "40px", height: "40px" }}>
                                    {!isObsVerified() ?
                                        <CircularProgress
                                            value={getObservationProgress()}
                                            progressColor={theme.palette.azure.primary.main}
                                            backgroundColor={theme.palette.azure.primary.shades["35%"]}
                                        /> :
                                        < CheckedLocked
                                            checkColor={mainColor}
                                            lockColor={lockColor}
                                        />
                                    }
                                </Box>
                            </Tooltip>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {observation?.rbpp?.html &&
                        <Accordion
                            sx={{
                                background: "rgba(0, 0, 0, 0.03)",
                                "&:hover": {
                                    background: "rgba(0, 0, 0, 0.05)",
                                },
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                borderRadius: 2,
                                marginBottom: groupMargin,
                                '& .MuiAccordionSummary-root': {
                                    paddingTop: 0, // Remove top padding
                                    paddingBottom: 0, // Remove bottom padding
                                },
                                '& .MuiAccordionSummary-content.Mui-expanded': {
                                    margin: '0px', // Adjust this as needed to remove additional spacing when expanded
                                },
                                '& .MuiAccordionDetails-root': {
                                    paddingTop: 0,
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: theme.palette.greenLemon.primary.main }} />}
                            >
                                <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                    <LightbulbIcon sx={{ fontSize: "32px", color: theme.palette.orange.primary.main }} />
                                    <Typography sx={{ marginLeft: "8px" }}>RBPP et réglementation</Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{
                                    fontSize: "0.8rem",
                                    '& p': {
                                        margin: 0
                                    },
                                    '& a': {
                                        color: mainColor
                                    }
                                }} dangerouslySetInnerHTML={{ __html: observation?.rbpp?.html }} />
                            </AccordionDetails>
                        </Accordion>
                    }
                    {observation?.responseArray.map(responseNumber => {
                        return (
                            <Box
                                key={observation?.immutable_id + responseNumber}
                            >
                                {observation?.nb_responses === 3 &&
                                    <Chip
                                        label={`Personne ${responseNumber}`}
                                        sx={{
                                            backgroundColor: mainColor,
                                            color: "white",
                                            marginBottom: "8px"
                                        }}
                                    />
                                }
                                {observation?.requested_response === "text" &&
                                    <Controller
                                        name={`text/${observation?.immutable_id}/${responseNumber}`}
                                        rules={{}}
                                        control={control}
                                        render={({ field }) => {
                                            const { onChange, value } = field;
                                            const disabled = isReaderOnly || isObsVerified()
                                            return (
                                                <RichTextEditor
                                                    value={(value?.delta && !isEmptyDelta(value.delta)) ? value.delta : value.html}
                                                    onChange={({ delta, html }) => {
                                                        onChange({ html: html, delta: delta });
                                                    }}
                                                    // onChange={(content, delta, source, editor) => {
                                                    //     debugger;
                                                    //     let deltaValue = editor.getContents();
                                                    //     onChange({ html: content, delta: deltaValue });
                                                    // }}
                                                    showLockedIcon={disabled}
                                                    lockedIconTooltip={isReaderOnly ? "Votre accès est limité à la lecture" : "Le constat est vérifié"}
                                                    lockedIconColor={lockColor}
                                                    style={{ marginBottom: "16px" }}
                                                    disabled={disabled}
                                                />
                                            )
                                        }}
                                    />
                                }
                                {observation.requested_response === "progress" &&
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: elementMargin, marginBottom: elementMargin }}>
                                        <Controller
                                            name={`progress/${observation?.immutable_id}/${responseNumber}`}
                                            control={control}
                                            render={({ field }) => {
                                                const { onChange, value } = field;
                                                let disabled = !isAuthorized({ module: lists?.LICENSE_MODULE_VALUES["AEV"], baseAction: lists?.BASE_ACTION_VALUES["WRT"] }) || isObsVerified();
                                                return (<>
                                                    <Slider
                                                        onChange={(e, newValue) => onChange(newValue)}
                                                        aria-labelledby="input-slider"
                                                        value={value}
                                                        sx={{
                                                            flex: 1,
                                                            '&.Mui-disabled': {
                                                                color: mainColordisabled,
                                                            }
                                                        }}
                                                        min={0}
                                                        max={100}
                                                        disabled={disabled}
                                                    />
                                                    <Input
                                                        value={value}
                                                        margin="dense"
                                                        disabled={disabled}
                                                        onChange={e => onChange(parseInt(e.target.value))}
                                                        onInput={e => {
                                                            const val = parseInt(e.target.value);
                                                            if (val > 100) {
                                                                e.target.value = 100;
                                                            } else if (val < 0) {
                                                                e.target.value = 0;
                                                            }
                                                        }}
                                                        disableUnderline
                                                        endAdornment={(
                                                            <InputAdornment position="end">
                                                                <span style={{ fontSize: '16px', fontWeight: 'bold', color: disabled ? mainColordisabled : mainColor }}>%</span>
                                                            </InputAdornment>
                                                        )}
                                                        inputProps={{
                                                            step: 1,
                                                            type: 'number',
                                                            'aria-labelledby': 'input-slider',

                                                        }}
                                                        sx={{
                                                            width: '100px',
                                                            padding: '8px 12px',
                                                            borderRadius: '8px',
                                                            border: 'none',
                                                            boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, 0.1)',
                                                            background: 'linear-gradient(120deg, #f0f0f0, #ffffff)',
                                                            outline: 'none',
                                                            '& .MuiInputBase-input': {
                                                                color: mainColor,
                                                                fontWeight: 'bold',
                                                                '&.Mui-disabled': {
                                                                    'WebkitTextFillColor': mainColordisabled,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </>)
                                            }}
                                        />
                                        <Box sx={{ width: "20px" }}>
                                            {(isReaderOnly || isObsVerified()) &&
                                                <Tooltip title={isReaderOnly ? "Votre accès est limité à la lecture" : "Le constat est vérifié"}>
                                                    <Box sx={{ paddingBottom: "4px" }}>
                                                        <Locked color={lockColor} />
                                                    </Box>
                                                </Tooltip>
                                            }
                                        </Box>
                                    </Box>
                                }
                                <Box
                                    display="flex"
                                    flexDirection={containerLevelProofRef?.current?.offsetWidth < (2 * (proofListWrapWidth + elementMarginValue)) ? "column" : "row"}
                                    justifyContent="space-between"
                                    width="100%"
                                    ref={containerLevelProofRef}
                                    marginBottom={elementMargin}
                                    id="proofLevelContainer"
                                >
                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "start",
                                        width: containerLevelProofRef?.current?.offsetWidth > (2 * (proofListWrapWidth + elementMarginValue)) ? `calc(50% - ${elementMargin})` : "100%",
                                        marginBottom: elementMargin
                                    }}
                                        id="levelContainer"
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="start"
                                            ref={levelRef}
                                            id="level"
                                        >
                                            <Controller
                                                name={`level/${observation?.immutable_id}/${responseNumber}`}
                                                control={control}
                                                render={({ field }) => (
                                                    <Box sx={{ display: "flex", width: "100%" }}>
                                                        <RadioGroup {...field}>
                                                            <FormLabel component="legend">
                                                                <Chip
                                                                    label={
                                                                        <Box sx={{
                                                                            display: "flex",
                                                                            alignItems: "center"
                                                                        }}>
                                                                            <Typography sx={{ fontSize: "13px" }}>Cotation</Typography>
                                                                            {(isReaderOnly || isObsVerified()) &&
                                                                                <Tooltip title={isReaderOnly ? "Votre accès est limité à la lecture" : "Le constat est vérifié"}>
                                                                                    <Box sx={{ marginLeft: '8px', display: "flex", paddingBottom: "4px" }}>
                                                                                        <Locked color={lockColor} />
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            }
                                                                        </Box>
                                                                    }
                                                                    sx={{
                                                                        backgroundColor: theme.palette.greenLemon.primary.shades["60%"],
                                                                        color: "white",
                                                                        marginBottom: "8px",
                                                                    }}
                                                                />
                                                            </FormLabel>
                                                            {Object.entries(lists?.RESPONSE_LEVEL_LIST)?.map(([key, value]) => (
                                                                <FormControlLabel
                                                                    key={key}
                                                                    disabled={isReaderOnly || isObsVerified()}
                                                                    value={key}
                                                                    control={
                                                                        <Radio
                                                                            icon={<RadioIcon />}
                                                                            checkedIcon={<CheckedRadioIcon />}
                                                                            sx={{
                                                                                color: mainColor,
                                                                                '&.Mui-disabled': {
                                                                                    color: mainColordisabled,
                                                                                }
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={<Typography>{value?.label}</Typography>}
                                                                    onClick={(event) => {
                                                                        if (event.target.value === field.value) {
                                                                            event.preventDefault(); // Prevents the radio input's native onChange from firing
                                                                            field.onChange(null); // Deselects the radio if the same value is clicked
                                                                        }
                                                                    }}
                                                                    sx={{
                                                                        color: mainColor,
                                                                        '&.Mui-disabled': {
                                                                            color: mainColordisabled,
                                                                        }
                                                                    }}
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    </Box>
                                                )}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "start",
                                        width: containerLevelProofRef?.current?.offsetWidth > (2 * (proofListWrapWidth + elementMarginValue)) ? `calc(50% - ${elementMargin})` : "100%"
                                    }}
                                        id="proofContainer"
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="start"
                                            flexDirection="column"
                                            width="100%"
                                            ref={proofRef}
                                            id="proof"
                                        >
                                            <FormLabel component="legend">
                                                <Chip
                                                    label={
                                                        <Box sx={{
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}>
                                                            <Typography sx={{ fontSize: "13px" }}>Preuves</Typography>
                                                            {(isReaderOnly || isObsVerified()) &&
                                                                <Tooltip title={isReaderOnly ? "Votre accès est limité à la lecture" : "Le constat est vérifié"}>
                                                                    <Box sx={{ marginLeft: '8px', display: "flex", paddingBottom: "4px" }}>
                                                                        <Locked color={lockColor} />
                                                                    </Box>
                                                                </Tooltip>
                                                            }
                                                        </Box>
                                                    }
                                                    sx={{
                                                        backgroundColor: theme.palette.greenLemon.primary.shades["60%"],
                                                        color: "white",
                                                        marginBottom: "8px"
                                                    }}
                                                />
                                            </FormLabel>
                                            <Controller
                                                name={`proof/${observation?.immutable_id}/${responseNumber}`}
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", width: "100%" }}>
                                                            {field?.value?.map((item) => {
                                                                let userFile = item.userFile;
                                                                if (userFile === undefined) {
                                                                    return <></>;
                                                                }
                                                                return (
                                                                    <List
                                                                        sx={{ width: '100%' }}
                                                                        component="nav"
                                                                        key={userFile._id}
                                                                    >
                                                                        <ListItemButton
                                                                            // onClick={() => {
                                                                            //     setLinkProofDocDialogState({
                                                                            //         isOpen: true,
                                                                            //         responseNumber: responseNumber,
                                                                            //         observation_ImID: observation.immutable_id,
                                                                            //         filterValue: userFile.name
                                                                            //     })
                                                                            // }}
                                                                            sx={{ cursor: "default" }}
                                                                        >
                                                                            <Tooltip title={`fichier "${userFile.fileType}"`} arrow>
                                                                                <ListItemIcon sx={{ minWidth: "36px", cursor: "default" }}>
                                                                                    {getFileIcon(userFile.fileType, { color: mainColor, height: "24px" })}
                                                                                </ListItemIcon>
                                                                            </Tooltip>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Tooltip title="Nom du fichier" arrow>
                                                                                        <span style={{ color: mainColor, cursor: "default" }}>{userFile.name}</span>
                                                                                    </Tooltip>
                                                                                }
                                                                                secondary={
                                                                                    <Tooltip title="Dernière modification" arrow>
                                                                                        <span style={{ color: mainColor, cursor: "default" }}>{getDateString(userFile.created?.on)}</span>
                                                                                    </Tooltip>
                                                                                }
                                                                            />
                                                                            <Tooltip title={"Télécharger fichier"} arrow>
                                                                                <IconButton
                                                                                    sx={{ width: "48px", color: mainColor }}
                                                                                    onClick={(event) => {
                                                                                        handleClickDownload(event, userFile);
                                                                                    }}
                                                                                >
                                                                                    <CloudDownloadIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <AuthorizationChecker
                                                                                module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                                                                baseAction={lists?.BASE_ACTION_VALUES["WRT"]}
                                                                            >
                                                                                <Tooltip title={"Dissocier fichier"} arrow>
                                                                                    {/* necessary to wrap in a span otherwise a disabled button triggers an mui warning */}
                                                                                    <span>
                                                                                        <IconButton
                                                                                            sx={{
                                                                                                width: "48px",
                                                                                                color: mainColor,
                                                                                                '&.Mui-disabled': {
                                                                                                    color: mainColordisabled,
                                                                                                }
                                                                                            }}
                                                                                            onClick={(event) => {
                                                                                                event.stopPropagation();
                                                                                                let linked_proof_docs = field.value.filter(el => el.userFile._id !== userFile._id);
                                                                                                field.onChange(linked_proof_docs);
                                                                                            }}
                                                                                            disabled={isObsVerified()}
                                                                                        >
                                                                                            <LinkOffIcon />
                                                                                        </IconButton>
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </AuthorizationChecker>
                                                                        </ListItemButton>
                                                                    </List>
                                                                );
                                                            })}
                                                            <AuthorizationChecker
                                                                module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                                                baseAction={lists?.BASE_ACTION_VALUES["WRT"]}
                                                            >
                                                                <Tooltip title={"Ajouter fichier"} arrow>
                                                                    {/* necessary to wrap in a span otherwise a disabled button triggers an mui warning */}
                                                                    <span>
                                                                        <IconButton
                                                                            sx={{
                                                                                marginLeft: "12px",
                                                                                color: mainColor,
                                                                                '&.Mui-disabled': {
                                                                                    color: `${mainColordisabled} !important`,
                                                                                }
                                                                            }}
                                                                            disabled={isObsVerified()}
                                                                            onClick={() => {
                                                                                setLinkProofDocDialogState({
                                                                                    isOpen: true,
                                                                                    responseNumber: responseNumber,
                                                                                    observation_ImID: observation.immutable_id,
                                                                                    filterValue: ""
                                                                                })
                                                                            }}
                                                                        >
                                                                            <AddCircleIcon />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </AuthorizationChecker>
                                                        </Box>
                                                    )
                                                }
                                                }
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}
                    <AuthorizationChecker
                        module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                        baseAction={lists?.BASE_ACTION_VALUES["VRF"]}
                    >
                        <Controller
                            name={`status/${observation?.immutable_id}.value`}
                            control={control}
                            render={({ field }) => {
                                const disabled = !areAllGradesDefined() || isCriterionApproved;
                                return (
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <Box sx={{ width: "100%" }}>
                                            <FormLabel component="legend">
                                                <Divider>
                                                    <Chip
                                                        label={
                                                            <Box sx={{
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <Typography sx={{ fontSize: "13px" }}>Vérification du constat</Typography>
                                                                {disabled &&
                                                                    <Tooltip title={isCriterionApproved ? `Le critère est approuvé` : `Au moins une cotation manquante`}>
                                                                        <Box sx={{ marginLeft: '8px', display: "flex", paddingBottom: "4px" }}>
                                                                            <Locked color={lockColor} />
                                                                        </Box>
                                                                    </Tooltip>
                                                                }
                                                            </Box>
                                                        }
                                                        sx={{
                                                            backgroundColor: theme.palette.greenLemon.primary.shades["60%"],
                                                            color: "white"
                                                        }}
                                                    />
                                                </Divider>
                                            </FormLabel>
                                        </Box>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={field?.value === lists?.RESPONSE_STATUSES["VERIFIED"]}
                                                        onChange={(event) => event.target.checked ? field.onChange(lists?.RESPONSE_STATUSES["VERIFIED"]) : field.onChange(lists?.RESPONSE_STATUSES["DRAFT"])}
                                                        sx={{
                                                            color: mainColor,
                                                            '&.Mui-disabled': {
                                                                color: mainColordisabled,
                                                            },
                                                        }}
                                                        disabled={disabled}
                                                    />
                                                }
                                                label={<Typography>{field.value === lists?.RESPONSE_STATUSES["DRAFT"] ? "Vérifier" : "Vérifié"}</Typography>}
                                                sx={{
                                                    color: mainColor,
                                                    '&.Mui-disabled': {
                                                        color: mainColordisabled,
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Box>
                                )
                            }}
                        />
                    </AuthorizationChecker>
                </AccordionDetails>
            </Accordion>
            {/* <FilesListManagerContext.Provider
                value={{
                    filesListManager
                }}> */}
            <LinkProofDocDialog
                isOpen={linkProofDocDialogState.isOpen}
                responseNumber={linkProofDocDialogState.responseNumber}
                observation_ImID={linkProofDocDialogState.observation_ImID}
                initFilterValue={linkProofDocDialogState.filterValue}
                onClose={() => setLinkProofDocDialogState(emptyLinkProofDocDialogState)}
            />
            {/* </FilesListManagerContext.Provider> */}
        </>
    );
}

export { ObservationRenderer }