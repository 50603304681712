import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { compareAsc, compareDesc, format, parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from "react";
import { getServerURL } from "../config";
import { useHttp } from "../context/HttpProvider";
import { useUser } from '../context/UserProvider';
import { useTheme } from '@mui/material/styles';

const ToobarNotifications = ({ notifications: initialNotifications, customer_hid }) => {
    const { httpRequest } = useHttp();
    const { isLoggedIn } = useUser();
    const [notifPopover, setNotifPopover] = useState({ isOpen: false, anchorEl: undefined });
    const [notifFilterValues, setNotifFilterValues] = useState(() => ['error', 'warning', 'success']);
    const [notifications, setNotifications] = useState(initialNotifications || []);
    const [nbUnread, setNbUnread] = useState(0);
    const [nbErrors, setNbErrors] = useState(0);
    const [nbWarnings, setNbWarnings] = useState(0);
    const [nbSuccess, setNbSuccess] = useState(0);
    const [selected, setSelected] = useState(false);
    const [dateSort, setDateSort] = useState('down');
    const theme = useTheme();

    const errorColor = theme.palette.pink.secondary.tint1.main;
    const warningColor = theme.palette.orange.primary.main;
    const successColor = theme.palette.greenLemon.primary.main;
    const screenWidth = window.innerWidth;

    useEffect(() => {
        if (!notifications) {
            return;
        }
        setNbUnread(notifications?.filter(n => (!n.read_on)).length);
        setNbErrors(notifications?.filter(n => n.priority === "2").length);
        setNbWarnings(notifications?.filter(n => n.priority === "1").length);
        setNbSuccess(notifications?.filter(n => n.priority === "0").length);
    }, [notifications]);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (!isLoggedIn) {
                clearInterval(interval);
                return;
            }
            const response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/notifications/fetch_toolbar_data`,
                method: "get",
                withCredentials: true,
                showLoading: false
            });
            if (response?.status !== 200) {
                return;
            }
            setNotifications(response.data);
        }, 60000 * 2);

        return () => clearInterval(interval);
    }, [isLoggedIn]);

    const getNotifPriorityColor = (notif) => {
        switch (notif.priority) {
            case "0":
                return successColor;
            case "1":
                return warningColor;
            case "2":
                return errorColor;
            default:
                return "";
        }
    }
    const getNotifPriorityFilterValue = (notif) => {
        switch (notif.priority) {
            case "0":
                return "success";
            case "1":
                return "warning";
            case "2":
                return "error";
            default:
                return "";
        }
    }

    const filteredRows = useMemo(() => {
        if (!notifications) {
            return [];
        }

        let filteredNotifs = [...notifications].filter(n => (notifFilterValues?.includes(getNotifPriorityFilterValue(n))))?.filter(n => {
            if (selected) {
                return !n.read_on;
            }
            return true;
        })?.sort((a, b) => {
            if (dateSort === "up") {
                return compareAsc(parseISO(a.created_on), parseISO(b.created_on));
            } else {
                return compareDesc(parseISO(a.created_on), parseISO(b.created_on));
            }
        });

        return filteredNotifs;

    }, [notifications, notifFilterValues, selected, dateSort]);


    const onClickMarkAllRead = async () => {
        try {
            const response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/notifications/mark_all_read`,
                method: "post",
                withCredentials: true,
                showLoading: false
            });
            if (!response?.status === 200) {
                return;
            }
            setNotifications(response.data);
        } catch (err) {

        }
    }

    const onAccordionChange = async (evt, notif) => {
        if (evt?.currentTarget?.ariaExpanded === 'true') {
            return;
        }
        try {
            const response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/notifications/mark_as_read/${notif._id}`,
                method: "post",
                withCredentials: true,
                showLoading: false
            });
            if (!response?.status === 200) {
                return;
            }
            setNotifications(response.data);
        } catch (err) {

        }
    }

    const onClickMarkUnread = async (notif) => {
        try {
            const response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/notifications/mark_as_unread/${notif._id}`,
                method: "post",
                withCredentials: true
            });
            if (!response?.status === 200) {
                return;
            }
            setNotifications(response.data);
        } catch (err) {

        }
    }

    const onClickDelete = async (notif) => {
        try {
            const response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/notifications/delete/${notif._id}`,
                method: "post",
                withCredentials: true
            });
            if (!response?.status === 200) {
                return;
            }
            setNotifications(response.data);
        } catch (err) {

        }
    }

    return (
        <>
            <IconButton
                sx={{ width: "100%", height: "100%" }}
                color="inherit"
                onClick={(event) => {
                    setNotifPopover({ isOpen: true, anchorEl: event.currentTarget });
                }}
            >
                {nbUnread > 0 ?
                    <Badge
                        badgeContent={nbUnread}
                        max={99}
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: theme => theme.palette.orange.primary.main,
                                color: 'white',
                                fontWeight: 'bold',
                                padding: '0px 4px',
                            }
                        }}
                    >
                        <NotificationsIcon sx={{ color: "white" }} />
                    </Badge>
                    :
                    <NotificationsIcon sx={{ color: "white" }} />
                }
            </IconButton>
            <Popover
                id={"notificationPopover"}
                open={notifPopover.isOpen}
                anchorEl={notifPopover.anchorEl}
                onClose={() => { setNotifPopover({ isOpen: false, anchorEl: undefined }) }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}>
                <Box sx={{ width: "550px", maxWidth: "calc(screenWidth - 64px)", p: 2 }}>
                    <Typography sx={{ width: '100%', textAlign: "center" }} variant="h6" >Notifications</Typography>
                    {/* POPOVER TOOLBAR */}
                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <ToggleButtonGroup size="small"
                            onChange={(e, v) => { setNotifFilterValues(v); }}
                            value={notifFilterValues}
                        >
                            <ToggleButton value="error">
                                <Typography sx={{ color: errorColor, fontWeight: "bold" }}>{nbErrors}</Typography>
                                <PriorityHighIcon sx={{ color: errorColor }} />
                            </ToggleButton>
                            <ToggleButton value="warning">
                                <Typography sx={{ color: warningColor, fontWeight: "bold" }}>{nbWarnings}</Typography>
                                <PriorityHighIcon sx={{ color: warningColor }} />
                            </ToggleButton>
                            <ToggleButton value="success">
                                <Typography sx={{ color: successColor, fontWeight: "bold" }}>{nbSuccess}</Typography>
                                <PriorityHighIcon sx={{ color: successColor }} />
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <ToggleButton
                            value="check"
                            size="small"
                            selected={selected}
                            onChange={() => {
                                setSelected(!selected);
                            }}
                        >
                            <MarkAsUnreadIcon />
                        </ToggleButton>

                        <ToggleButtonGroup
                            value={dateSort}
                            size="small"
                            exclusive
                            onChange={(evt, newVal) => {
                                setDateSort(newVal);
                            }}
                        >

                            <ToggleButton value="down" aria-label="centered">
                                <KeyboardArrowDownIcon />
                            </ToggleButton>
                            <ToggleButton value="up" aria-label="left aligned">
                                <KeyboardArrowUpIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <Button
                            variant="contained"
                            size="small"
                            disabled={nbUnread === 0}
                            sx={{ mr: "0.5em" }}
                            onClick={onClickMarkAllRead}
                        >
                            Marquer tous lus
                        </Button>
                    </div>
                    <div style={{ overflowY: 'auto', maxHeight: 'calc(90vh - 72px)', marginTop: '1em' }}>
                        {/* NOTIFICATIONS LIST */}
                        {filteredRows && filteredRows.length > 0 ? filteredRows.map(n => (
                            <Accordion key={n._id} onChange={(e) => { onAccordionChange(e, n); }}>
                                <AccordionSummary sx={{ paddingLeft: 0 }} expandIcon={<ExpandMoreIcon />}>
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "start", width: '15em' }}>
                                            {!n.read_on && <MarkAsUnreadIcon sx={{ color: "grey" }} />}
                                            <PriorityHighIcon sx={{ color: getNotifPriorityColor(n) }} />
                                            <Typography>{n.title}</Typography>
                                        </div>
                                        <Typography sx={{ width: '10em' }}>{format(new Date(n.created_on), 'dd-MM-yyyy HH:mm:ss')}</Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {n.message}
                                    <Box style={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", marginTop: "12px" }}>
                                        <Button variant="contained" size="small" disabled={!n.read_on} sx={{ mr: "0.5em" }} onClick={() => { onClickMarkUnread(n); }}>Marquer non lu</Button>
                                        <Button variant="contained" size="small" sx={{ mr: "0.5em" }} onClick={() => { onClickDelete(n); }}>Effacer</Button>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))
                            :
                            <Typography sx={{ width: '100%', textAlign: "center" }}> Aucune notification</Typography>
                        }

                    </div>
                </Box>
            </Popover>

        </>
    );
}

export default ToobarNotifications;