import { useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useUser } from '../context/UserProvider';
import { Outlet } from 'react-router';



const AuthorizationChecker = ({ baseAction, module, accesses, fallback, children }) => {

    const { isAuthorized, user, selectedEstablishment } = useUser();
    let isAuthorizedBool = useMemo(() => isAuthorized({ baseAction: baseAction, module: module, accesses: accesses }), [user, selectedEstablishment]);

    return isAuthorizedBool ? <>{children}</> : (<>{fallback}</> || null)
};


export { AuthorizationChecker }