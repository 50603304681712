//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import AlertTitle from '@mui/material/AlertTitle';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//


//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//


function TransitionBottom(props) {
    return <Slide {...props} direction="up" />;
}

const Toast = ({ isOpen, severity, title, message, duration, handleClose }) => {
    //severity must be eight error, warning, info or success
    //isOpn, severity, message and handleClose are mandatory

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={duration || 5000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            TransitionComponent={TransitionBottom}
            onClose={handleClose}
        >
            <Alert elevation={6} variant="filled" severity={severity} onClose={handleClose} sx={{ width: '50vw', minWidth: "400px", '& .MuiAlert-message': { textAlign: "center", width: "100%" } }}>
                <AlertTitle>{title || ""}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );

}

export { Toast }