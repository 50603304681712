//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LinearProgress from "@mui/material/LinearProgress";
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useForm } from "react-hook-form";
import zxcvbn from "zxcvbn";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../context/UserProvider";
import { useHttp } from "../context/HttpProvider";
import { usePaths } from "../context/PathsProvider";
import { useToast } from "../context/ToastProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import eqpLogo from "../img/logo_only.png"
import eqpLogoText from "../img/logo_title.png"

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../config";

const PwdStrengthIndicator = ({ score }) => {
    if (score === undefined) return <></>;
    let label = "";
    let color = "";

    switch (score) {
        case 0:
            color = "red";
            label = "faible";
            break;
        case 1:
            color = "red";
            label = "faible";
            break;
        case 2:
            color = "orange";
            label = "moyen";
            break;
        case 3:
            color = "green";
            label = "fort";
            break;
        case 4:
            color = "green";
            label = "très fort";
            break;
    }

    const getValue = () => {
        let val = score / 4 * 100;
        if (val === 0) val = 5;
        return val;
    }

    return (
        <Box sx={{ width: "300px", height: "30px", display: "flex", flexDirection: "column" }}>
            <LinearProgress
                variant="determinate"
                value={getValue()}
                sx={{
                    borderRadius: 5,
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: color, // This targets the bar color
                    },
                    backgroundColor: "lightGray"
                }}
            />
            {label && (
                <Typography variant="caption" sx={{ color: color, alignSelf: "center", fontWeight: "bold" }}>
                    sécurité: {label}
                </Typography>
            )}
        </Box>
    )
}

const UpdatePwd = () => {
    const { loginUser, customer_hid, user, selectedEstablishment } = useUser();
    const { httpRequest } = useHttp();
    const { paths } = usePaths();
    const { presentToast } = useToast();
    const [showPassword, setShowPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const navigate = useNavigate();
    const { register, handleSubmit, watch, trigger, formState: { errors } } = useForm("onChange");

    const newPwdWatch = watch("new_pwd");

    const newPwdStrength = (newPwdWatch && newPwdWatch.length > 0) ? zxcvbn(newPwdWatch) : { score: undefined };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflowY: "auto" }}>
            <AppBar position="sticky">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Connexion
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column", alignItems: "center", maxHeight: "calc(100vh - 64px)", padding: "0 24px 0 24px" }}
                onSubmit={handleSubmit(async (data) => {
                    let response = await httpRequest({
                        url: `${getServerURL()}/customer/${customer_hid}/update_pwd`,
                        method: "post",
                        data: { current_pwd: btoa(data.current_pwd), new_pwd: btoa(data.new_pwd) },
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true
                    })
                    if (response.status === 200) {
                        await loginUser(customer_hid);
                        navigate(paths.home.app({ customer_hid: customer_hid, establishment_id: selectedEstablishment?._id }));
                    }
                })}
            >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", margin: "48px", gap: "12px" }}>
                    <img src={eqpLogo} alt="Logo EQP" style={{ height: "25vh", width: "auto" }} />
                    <img src={eqpLogoText} alt="Text Logo EQP" style={{ height: "5vh", width: "auto" }} />
                </Box>
                <Typography sx={{ marginBottom: "2vh" }}>
                    Votre mot de passe est temporaire. Afin d'accéder à l'application, veuillez créer votre mot de passe définitif.
                </Typography>
                <TextField
                    type="hidden"
                    value={user?.username}
                    autoComplete="username"
                    InputProps={{
                        style: {
                            display: 'none', // This will hide the entire TextField, not just the input element
                        },
                    }}
                />
                <TextField
                    label="Mot de passe actuel"
                    variant="outlined"
                    type={showCurrentPassword ? "text" : "password"}
                    error={errors.current_pwd ? true : false}
                    helperText={errors.current_pwd?.message}
                    sx={{ width: "300px", margin: "0 auto 2vh auto" }}
                    autoComplete="current-password"
                    name="current_pwd"
                    {...register("current_pwd", { required: "Mot de passe temporaire requis" })}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)} edge="end">
                                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="Nouveau mot de passe"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    error={errors.new_pwd ? true : false}
                    helperText={errors.new_pwd?.message}
                    sx={{ width: "300px", margin: "0 auto 2vh auto" }}
                    autoComplete="new-password"
                    name="new_pwd"
                    {...register("new_pwd",
                        {
                            required: "Nouveau mot de passe requis",
                            minLength: {
                                value: 8,
                                message: "8 caractères minimum"
                            },
                            validate: (val) => zxcvbn(val).score >= 3 || "Niveau de sécurité minimum requis: fort"
                        })
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <PwdStrengthIndicator score={newPwdStrength?.score} />
                <TextField
                    label="Répéter nouveau mot de passe"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    error={errors.new_pwd_repeat ? true : false}
                    helperText={errors.new_pwd_repeat?.message}
                    sx={{ width: "300px", margin: "0 auto 2vh auto" }}
                    autoComplete="new-password"
                    name="new_pwd_repeat"
                    {...register("new_pwd_repeat",
                        {
                            required: "Répéter mot de passe requis",
                            validate: (val) => val === newPwdWatch || "Mots de passes différents"
                        })}
                />
                <Button
                    variant="contained"
                    sx={{ width: "200px", margin: "0 auto 0 auto" }}
                    type="submit"
                >
                    Valider
                </Button>
            </Box>
        </Box>
    );
}

export default UpdatePwd;