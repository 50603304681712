//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import MuiAppBar from '@mui/material/AppBar';
import Autocomplete from "@mui/material/Autocomplete";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import AssessmentIcon from '@mui/icons-material/Assessment';//eval en cours
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HomeIcon from '@mui/icons-material/Home';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AssignmentIcon from '@mui/icons-material/Assignment';//eval list
import DonutSmallIcon from '@mui/icons-material/DonutSmall';//dashboard
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AdsClickIcon from '@mui/icons-material/AdsClick';//cockpit

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { usePaths } from "../context/PathsProvider";
import { useUser } from "../context/UserProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { StyledAppBar } from "./StyledAppBar";
import EqpLogo from "../img/logo_only.png";
import EqpLogoText from "../img/logo_title.png";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import ToobarNotifications from "./ToolbarNotifications";
import { ErrorBoundary } from "react-error-boundary";
import { InputAdornment, Collapse } from "@mui/material";
import { useLeftMenu } from "../context/LeftMenuProvider";






const Main = styled('main', { shouldForwardProp: (prop) => !['open', 'drawerWidth'].includes(prop) })(
    ({ theme, open, drawerWidth }) => ({
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        alignItems: 'stretch',
        paddingTop: theme.appbarHeight,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...((open && drawerWidth) && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${drawerWidth}px`
        }),
    }),
);

const AppBar = styled(StyledAppBar, {
    shouldForwardProp: (prop) => !['open', 'drawerWidth'].includes(prop),
})(({ theme, open, drawerWidth }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...((open && drawerWidth) && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    height: theme.appbarHeight,
    padding: theme.spacing(0, 1),
}));

const CollapsibleListItem = ({ page, children }) => {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const theme = useTheme();

    const handleClick = () => {
        setOpen(!open);
    };

    const isActive = page?.childrenUrls?.includes(location.pathname + location.search);
    const activeColor = theme.palette.forest.secondary.tint3.shades["40%"];
    const activeIconColor = theme.palette.forest.primary.main;

    return (
        <>
            <ListItemButton onClick={handleClick} sx={{ backgroundColor: (isActive && !open) ? activeColor : "inherit" }}>
                <ListItemIcon sx={{ color: (isActive && !open) ? activeIconColor : "" }}>{page.icon}</ListItemIcon>
                <ListItemText primary={<Typography sx={{ color: (isActive && !open) ? activeIconColor : "", fontWeight: (isActive && !open) ? "bold" : "normal" }}>{page.title}</Typography>} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ pl: 1 }}>
                    {children}
                </List>
            </Collapse>
        </>
    );
};

const MenuItemsRenderer = (pages) => {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    const activeColor = theme.palette.forest.secondary.tint3.shades["40%"];
    const activeIconColor = theme.palette.forest.primary.main;

    return pages.map((page, index) => {
        if (page.children && page.children.length > 0) {
            // Parent element with children, render CollapsibleListItem
            return (
                <CollapsibleListItem key={page.title + index} page={page}>
                    {MenuItemsRenderer(page.children)}
                </CollapsibleListItem>
            );
        } else {
            // Regular menu item
            const isActive = page?.url === (location.pathname + location.search);
            return (
                <ListItem key={page.title + index} disablePadding>
                    <ListItemButton onClick={() => navigate(page.url)} sx={{ backgroundColor: isActive ? activeColor : "" }}>
                        <ListItemIcon sx={{ color: isActive ? activeIconColor : "" }}>{page.icon}</ListItemIcon>
                        <ListItemText primary={<Typography sx={{ color: isActive ? activeIconColor : "", fontWeight: isActive ? "bold" : "normal" }}>{page.title}</Typography>} />
                    </ListItemButton>
                </ListItem>
            );
        }
    });
}


const AppToolbar = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { establishments, user, selectedEstablishment, setSelectedEstablishment, handleClickLogout, customer, currentReferential_ImID, notifications, isAuthorized, lists } = useUser();
    const { paths, getCurrentPathObject } = usePaths();
    const { isLeftMenuOpened: open, setIsLeftMenuOpened: setOpen, leftMenuWidth: drawerWidth } = useLeftMenu();
    const [userPopover, setUserPopover] = useState({ isOpen: false, anchorEl: undefined });

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const referential_ImID_url = searchParams.get('referential_ImID');
    const criterion_ImID_url = searchParams.get('criterion_ImID');


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const currentPathObject = getCurrentPathObject();

    //filter establishments depending on the current path and on authorizations
    const authorizedEstablishments = useMemo(() => establishments?.filter(e => {
        if (!user || !establishments) return [];
        let anyAuthForEstablishment = user?.authorizations?.some(auth => {
            if (auth?.establishment_id === e._id &&
                (
                    currentPathObject?.accesses?.length === 0 ||
                    currentPathObject?.accesses?.some(acceptableAccess => {
                        if (acceptableAccess.module === auth.module && auth.baseActions?.includes(acceptableAccess.baseAction)) {
                            return true
                        } else return false
                    })
                )
            ) {
                return auth;
            }
        });
        return anyAuthForEstablishment;
    }), [user, establishments, location.pathname])

    const getPageTitle = () => {
        return currentPathObject?.title || "";
    }


    // Doc for appPagesData
    // Example of a page with all possible fields
    // {
    //     title: paths.evalList.title, //mandatory
    //     url: paths.evalList.app({ establishment_id: selectedEstablishment?._id, customer_hid: customer.human_id }), //mandatory
    //     icon: <AssignmentIcon />, //mandatory
    //     authorizations: { module: lists?.LICENSE_MODULE_VALUES["AEV"], baseAction: lists?.BASE_ACTION_VALUES["RDO"] } //optionnal. If none, authorization is granted no matter what.
    // }
    // Example of a page container with all possible fields
    // {
    //     title: paths.evalList.title, //mandatory
    //     icon: <AssignmentIcon />, //mandatory
    //     children: an array that contains nested pages or nested pages containers
    //     this container page will only be visible if at least one child has been granted authorization
    // }

    const appPagesData = [
        {
            title: paths.home.title,
            url: paths.home.app({ customer_hid: customer?.human_id }),
            icon: <HomeIcon />,
        },
        {
            title: paths.evalList.title,
            url: paths.evalList.app({ establishment_id: selectedEstablishment?._id, customer_hid: customer?.human_id }),
            icon: <AssignmentIcon />,
            authorizations: { module: lists?.LICENSE_MODULE_VALUES["AEV"], baseAction: lists?.BASE_ACTION_VALUES["RDO"] }
        },
        {
            title: "Évaluation en cours",
            icon: <AssessmentIcon />,
            children: [
                {
                    title: paths.evalCockpit.title,
                    url: paths.evalCockpit.app({ establishment_id: selectedEstablishment?._id, customer_hid: customer?.human_id, referential_ImID: currentReferential_ImID }),
                    icon: <AdsClickIcon />,
                    authorizations: { module: lists?.LICENSE_MODULE_VALUES["AEV"], baseAction: lists?.BASE_ACTION_VALUES["RDO"] }
                },
                {
                    title: paths.evalDashboard.title,
                    url: paths.evalDashboard.app({ establishment_id: selectedEstablishment?._id, customer_hid: customer?.human_id, referential_ImID: currentReferential_ImID }),
                    icon: <DonutSmallIcon />,
                    authorizations: { module: lists?.LICENSE_MODULE_VALUES["AEV"], baseAction: lists?.BASE_ACTION_VALUES["RDO"] }
                }
            ]
        },
        {
            title: paths.paq.title,
            url: paths.paq.app({ establishment_id: selectedEstablishment?._id, customer_hid: customer?.human_id }),
            icon: <AssignmentTurnedInIcon />,
            authorizations: { module: lists?.LICENSE_MODULE_VALUES["PAQ"], baseAction: lists?.BASE_ACTION_VALUES["RDO"] }
        }
    ];

    const appPages = useMemo(() => {
        if (!lists || !customer || !currentReferential_ImID || !selectedEstablishment) {
            return [appPagesData[0]]; //accueil
        }

        const filterPages = (pagesData) => {
            let array = [];
            for (let pageData of pagesData) {
                if (pageData?.children?.length > 0) {
                    let subArray = filterPages(pageData.children);
                    if (subArray?.length > 0) {
                        array.push({ ...pageData, children: subArray, childrenUrls: subArray.flatMap(e => (e.childrenUrl ? e.childrenUrls : [e.url])) });
                    }
                } else {
                    if (!pageData?.authorizations || (pageData.authorizations && isAuthorized(pageData.authorizations))) {
                        array.push(pageData);
                    }
                }
            }
            return array;
        }

        const filteredPages = filterPages(appPagesData);

        return filteredPages;

    }, [appPagesData]);

    return (
        <>
            <CssBaseline />
            <AppBar open={open} drawerWidth={drawerWidth} >
                <Toolbar sx={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center", flexGrow: "1" }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            {getPageTitle()}
                        </Typography>
                    </Box>
                    {establishments &&
                        <Autocomplete
                            options={authorizedEstablishments}
                            getOptionLabel={option => option.name}
                            disableClearable
                            value={selectedEstablishment}
                            onChange={(e, value) => {
                                setSelectedEstablishment(value)
                                let currentPathObject = getCurrentPathObject();
                                if (currentPathObject) {
                                    navigate(currentPathObject.app({
                                        establishment_id: value._id,
                                        user_id: user._id,
                                        customer_hid: customer.human_id,
                                        referential_ImID: referential_ImID_url,
                                        criterion_ImID: criterion_ImID_url
                                    }))
                                }
                            }}
                            sx={{
                                width: "300px",
                                backgroundColor: "white",
                                borderRadius: "5px",
                                '& .MuiOutlinedInput-root': {
                                    height: 'auto',
                                    padding: 0,
                                    '& .MuiInputBase-input': {
                                        height: '45px',
                                        padding: '0px 14px',
                                        color: theme.palette.orange.primary.main,
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent', // Hide the border
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        // borderColor: theme.palette.orange.primary.main, // Hide border on hover
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        // borderColor: 'transparent', // Hide border when focused
                                    },
                                },
                            }}
                            renderInput={(params) => (
                                <>
                                    <TextField {...params} InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                                                <ApartmentIcon style={{ color: theme.palette.orange.primary.main }} />
                                            </InputAdornment>
                                        ),
                                    }} />
                                </>
                            )}
                        />
                    }
                    <Box sx={{ display: "flex", alignItems: "center", height: "100%", flexGrow: "1", justifyContent: "right" }}>
                    </Box>

                    {/*----------------------- NOTIFICATIONS --------------------------*/}
                    <ErrorBoundary fallback={<div>Something went wrong</div>} onError={(err, info) => { debugger; }}>
                        <Box
                            sx={{
                                backgroundColor: theme.palette.greenLemon.primary.main,
                                borderRadius: "0 20px 0 20px",
                                width: "35px",
                                height: "35px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <ToobarNotifications notifications={notifications} customer_hid={customer?.human_id} />
                        </Box>
                    </ErrorBoundary>
                    {/*----------------------- NOTIFICATIONS END --------------------------*/}

                    <Button
                        sx={{
                            color: theme.palette.orange.primary.main,
                            backgroundColor: "white",
                            borderRadius: "20px 0 20px 0",
                            height: "35px",
                            padding: "3px 10px 3px 10px",
                            textTransform: "none"
                        }}
                        // variant="text"
                        startIcon={
                            <PersonIcon
                                sx={{ width: "24px", height: "24px" }}
                            />}
                        onClick={(event) => {
                            setUserPopover({ isOpen: true, anchorEl: event.currentTarget });
                        }}>{user?.firstname?.slice(0, 1)}.{user?.lastname}</Button>
                    <Popover
                        id={"userPopover"}
                        open={userPopover.isOpen}
                        anchorEl={userPopover.anchorEl}
                        onClose={() => { setUserPopover({ isOpen: false, anchorEl: undefined }) }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    // transformOrigin={{
                    //     vertical: "top",
                    //     horizontal: "right"
                    // }}
                    >
                        <List>
                            <ListItem onClick={handleClickLogout}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText>Déconnexion</ListItemText>
                            </ListItem>
                        </List>
                    </Popover>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "20px", paddingLeft: "16px" }}>
                        <img src={EqpLogo} style={{ height: "56px", width: "auto" }} />
                        <img src={EqpLogoText} style={{ height: "24px", width: "auto" }} />
                    </Box>
                    <IconButton onClick={handleDrawerClose}>
                        {<ChevronLeftIcon style={{ color: theme.palette.forest.primary.main }} />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Typography color="primary.light" sx={{ margin: "12px 0 0 20px" }}>Application</Typography>
                <List>
                    {MenuItemsRenderer(appPages)}
                </List>
            </Drawer>
            <Main open={open} drawerWidth={drawerWidth}>
                <Outlet />
            </Main>
        </>
    );
}

export { AppToolbar };
