import { Box } from "@mui/material";
import { useLeftMenu } from "../context/LeftMenuProvider"
import { styled } from '@mui/material/styles';




const StyledToolbar = styled(Box, { shouldForwardProp: (prop) => !['open', 'drawerWidth'].includes(prop) })(
    ({ theme, open, drawerWidth }) => ({
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        transition: theme.transitions.create(['margin-left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && drawerWidth && {
            marginLeft: `${drawerWidth}px`,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['margin-left', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }),
);


const BottomFixedToolbar = ({ children, sx = {}, ...props } = {}) => {
    const { isLeftMenuOpened, leftMenuWidth } = useLeftMenu();

    const defaultSx = {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: "64px",
        background: 'white',
        boxShadow: 3,
        p: `0 2 0 2`,
    };

    return (
        <StyledToolbar
            sx={{ ...defaultSx, ...sx }}
            open={isLeftMenuOpened}
            drawerWidth={leftMenuWidth}
        >
            {children}
        </StyledToolbar>
    )
}


export { BottomFixedToolbar }