//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Dialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Drawer from "@mui/material/Drawer";
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkIcon from '@mui/icons-material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import HistoryIcon from '@mui/icons-material/History';
import PersonIcon from '@mui/icons-material/Person';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from '@mui/icons-material/Info';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useFormContext, useWatch } from "react-hook-form";
import { MRT_Localization_FR } from 'material-react-table/locales/fr';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { usePaths } from "../../../context/PathsProvider";
import { useHttp } from "../../../context/HttpProvider";
import { useToast } from "../../../context/ToastProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { BlobDialog } from "./BlobDialog";
import { AuthorizationChecker } from "../../../components/AuthorizationChecker";

//*********************************************************************************************************************************************//
//                                                             CUSTOM HOOKS imports                                                            //
//*********************************************************************************************************************************************//
import useNestedListOpenStateManager from "../../../hooks/useNestedListOpenStateManager";
import useFileDownloader from "../../../hooks/useFileDownloader";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../../config";
import { getFileIcon, getDateString, removeAccents } from "../../../lib/lib";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, FormControl, FormControlLabel, FormLabel, Input, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Modal, Radio, RadioGroup, Slider, Stack, TextareaAutosize, Tooltip } from "@mui/material";
import { Blob } from "../../../models/Blob";




const LinkProofDocDialog = ({ isOpen, onClose, responseNumber, observation_ImID, initFilterValue }) => {

  const theme = useTheme();
  const { httpRequest } = useHttp();
  const { selectedEstablishment, lists } = useUser();
  const { presentToast } = useToast();
  const { downloadFileUrl } = useFileDownloader();
  const [isLoading, setIsLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const emptyBlobDialogState = { isOpen: false, title: "", blob: undefined };
  const [blobDialogState, setBlobDialogState] = useState(emptyBlobDialogState);
  const [userFiles, setUserFiles] = useState([]);
  const { customer_hid } = useParams();
  const openStateManager = useNestedListOpenStateManager();
  // const { filesListManager } = useContext(FilesListManagerContext);

  const groupMargin = theme.spacing(4);
  const elementMargin = theme.spacing(1);
  const borderMargin = theme.spacing(2);

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const minWidth = theme.breakpoints.values.sm.toString() + "px";

  const { getValues, setValue, control } = useFormContext();

  useEffect(() => {
    setFilterValue(initFilterValue);
  }, [initFilterValue])

  //add watch on linked_proof_docs array to trigger rerender each time it is updated
  //onClick on linkBlob or unlinkBlob => icon gets colored uncolored
  const lpd = useWatch({
    control,
    name: `proof/${observation_ImID}/${responseNumber}`,
  })

  const linkUserFile = (userFile) => {
    let fieldName = `proof/${observation_ImID}/${responseNumber}`;
    let linked_proof_docs = getValues(fieldName);
    //If file is already linked, do nothing
    if (linked_proof_docs.some(el => el.userFile?._id === userFile._id)) return;
    linked_proof_docs.push({ userFile: userFile, user_file_id: userFile._id });
    setValue(fieldName, linked_proof_docs);

  }

  const unlinkUserFile = (userFile) => {
    let fieldName = `proof/${observation_ImID}/${responseNumber}`;
    let linked_proof_docs = getValues(fieldName).filter(el => el.userFile._id !== userFile._id);
    setValue(fieldName, linked_proof_docs);
  }

  const fetchExistingFiles = useCallback(async () => {
    setIsLoading(true);
    let response = await httpRequest({
      url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/user_files/fetch`,
      method: 'get',
      showLoading: false,
      withCredentials: true
    });
    if (response.status === 200) {
      let listInitialOpenState = {};
      let userFiles = response?.data?.userFiles?.map(userFile => {
        if (userFile._id) listInitialOpenState[userFile._id] = false;
        return userFile;
        // return new Blob(userFile)
      });
      openStateManager.setInitialState(listInitialOpenState);
      // userFiles.sort((a, b) => a.userFileKeys[0].value?.localeCompare(b.userFileKeys[0].value));
      setUserFiles(userFiles);
    }
    setIsLoading(false);
  }, [customer_hid, selectedEstablishment]);

  useEffect(() => {
    if (!isOpen || !customer_hid || !selectedEstablishment) return;
    fetchExistingFiles();
  }, [isOpen, customer_hid, selectedEstablishment]);


  const isLinked = (userFile) => {
    let fieldName = `proof/${observation_ImID}/${responseNumber}`;
    let linkedUserFiles = getValues(fieldName);
    return linkedUserFiles?.some(el => el.userFile?._id === userFile._id);
  }

  const handleClickUpdate = async (event, blob) => {
    event.stopPropagation();
    setBlobDialogState({
      isOpen: true,
      title: `Modifier fichier "${blob?.userFileKeys?.[0]?.value}"`,
      blob: new Blob(blob)
    });
  }

  const handleClickDownload = async (event, userFile) => {
    event.stopPropagation();
    //fetch signed url for this blob
    let response = await httpRequest({
      url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/user_files/${userFile?.name}/get_signed_url`,
      method: 'get',
      showLoading: true,
      withCredentials: true
    });
    if (response.status === 200) {
      const signedUrl = response.data?.signedUrl;
      if (signedUrl) {
        downloadFileUrl(signedUrl);
      } else {
        presentToast({
          message: "Erreur impossible de télécharger le fichier",
          severity: "error"
        })
      }
    }
  }

  const handleClickLink = (event, userFile) => {
    event.stopPropagation();
    //if blob is not linked, we link it
    if (!isLinked(userFile)) {
      linkUserFile(userFile);
    } else {
      //else if blob is already linked, we unlink it
      unlinkUserFile(userFile);
    }
  }

  const handleClickHistory = (blob) => {

  }

  const handleClickNewFile = () => {
    setBlobDialogState({
      isOpen: true,
      title: "Nouveau fichier",
      blob: new Blob({ clientAction: "new" })
    });
  }


  const filterBlobCallback = (userFile) => {
    //if there is no filterValue, truen true
    const isFilterValue = filterValue !== "";
    if (!isFilterValue) return true;

    //split and clean search terms
    const searchTerms = removeAccents(filterValue?.toLowerCase())?.split(" ");

    const termMatches = (text1, text2) => {
      const cleanedText1 = removeAccents(text1.toLowerCase());
      const cleanedText2 = removeAccents(text2.toLowerCase());
      return searchTerms.every(term => cleanedText1.includes(term) || cleanedText2.includes(term));
    };

    let match = termMatches(getDateString(userFile?.created?.on), userFile?.name);

    return match ? true : false;
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
        sx={{ minWidth: minWidth }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ display: "flex", justifyContent: "space-between" }}>
          <span>Gestion fichiers preuves - mes fichiers</span>
          <Button
            onClick={onClose}
          >
            Fermer
          </Button>
        </DialogTitle>
        <DialogContent sx={{ maxHeight: "calc(100vh - 190px)", minWidth: minWidth }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "100%" }}>
            {/* SearchBar */}
            <TextField
              value={filterValue}
              onChange={(event) => setFilterValue(event.target.value)}
              variant="standard"
              placeholder="Rechercher"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {userFiles?.filter(b => filterBlobCallback(b)).map((userFile) => {
              const linked = isLinked(userFile);
              return (
                <List
                  sx={{ width: '100%', overFlowY: "auto" }}
                  component="nav"
                  key={userFile._id}
                >
                  <ListItemButton onClick={() => openStateManager.toggleItemOpen(userFile?._id)}>
                    <Tooltip title={`fichier "${userFile.fileType}"`} arrow>
                      <ListItemIcon sx={{ minWidth: "36px" }}>
                        {getFileIcon(userFile.fileType, { color: "rgba(0, 0, 0, 0.54)", height: "24px" })}
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={<Tooltip title="Nom du fichier" arrow><span>{userFile.name}</span></Tooltip>}
                      secondary={<Tooltip title="Crée le" arrow><span>{getDateString(userFile.created.on)}</span></Tooltip>} />

                    {/* <AuthorizationChecker
                      module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                      baseAction={lists?.BASE_ACTION_VALUES["WRT"]}
                    >
                      <Tooltip title={`Modifier fichier`} arrow>
                        <IconButton sx={{ width: "48px" }} onClick={(event) => handleClickUpdate(event, userFile)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </AuthorizationChecker> */}

                    <Tooltip title={`Télécharger fichier`} arrow>
                      <IconButton sx={{ width: "48px" }} onClick={(event) => handleClickDownload(event, userFile)}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </Tooltip>

                    <AuthorizationChecker
                      module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                      baseAction={lists?.BASE_ACTION_VALUES["WRT"]}
                    >
                      <Tooltip title={linked ? "Dissocier fichier" : `Associer fichier`} arrow>
                        <IconButton sx={{ width: "48px", color: linked ? theme.palette.primary.main : "" }} onClick={(event) => handleClickLink(event, userFile)}>
                          <LinkIcon />
                        </IconButton>
                      </Tooltip>
                    </AuthorizationChecker>
                    {/* {openStateManager.isOpen?.[userFile?._id] ? <ExpandLess sx={{ width: "64px" }} /> : <ExpandMore sx={{ width: "64px" }} />} */}
                  </ListItemButton>
                  {/* <Collapse in={openStateManager.isOpen?.[userFile?._id]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Version: ${userFile?.versions?.[0]?.number}`} />
                        <Tooltip title={`Historique`} arrow>
                          <IconButton sx={{ width: "48px" }} onClick={(blob) => handleClickHistory(blob)}>
                            <HistoryIcon />
                          </IconButton>
                        </Tooltip>
                        <Box sx={{ width: "64px" }} />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary={`${userFile?.lastUpdated?.by?.firstname} ${userFile?.lastUpdated?.by?.lastname} / ${userFile?.lastUpdated?.by?.job} ${userFile?.lastUpdated?.by?.organization ? userFile?.lastUpdated?.by?.organization : ""}`} />
                      </ListItemButton>
                    </List>
                  </Collapse> */}
                </List>
              );
            })}
            {userFiles?.length === 0 &&
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", paddingTop: groupMargin }}>

                <Typography> {isLoading ? "Chargement" : "Aucun fichier existant"}</Typography>
              </Box>
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <AuthorizationChecker
            module={lists?.LICENSE_MODULE_VALUES["AEV"]}
            baseAction={lists?.BASE_ACTION_VALUES["WRT"]}
          >
            <Button
              onClick={handleClickNewFile}
            >
              Nouveau fichier
            </Button>
          </AuthorizationChecker>
        </DialogActions>
      </Dialog>
      <BlobDialog
        isOpen={blobDialogState.isOpen}
        onClose={() => {
          setBlobDialogState(emptyBlobDialogState)
          fetchExistingFiles();
        }}
        title={blobDialogState.title}
        blob={blobDialogState.blob}
      />
    </div >
  );
}

export { LinkProofDocDialog }