import { UserActionInfo } from "./UserActionInfo";
import { compareObjects } from "../lib/lib";
import { Blob } from "./Blob";
import { getEmptyDelta, isEmptyDelta } from "../components/RichTextEditor/RichTextEditor";


class Response {
    constructor(r) {
        this._id = r?._id;
        this.establishment_id = r?.establishment_id;
        this.observation_immutable_id = r?.observation_immutable_id;
        this.criterion_immutable_id = r?.criterion_immutable_id;
        this.referential_immutable_id = r?.referential_immutable_id;
        this.sequence_number = r?.sequence_number;
        this.text = {
            html: r?.text?.html || "",
            delta: r?.text?.delta || getEmptyDelta(),
            created: new UserActionInfo(r?.text?.created)
        };
        this.progress = {
            value: r?.progress?.value,
            created: new UserActionInfo(r?.progress?.created)
        };
        this.level = {
            value: r?.level?.value,
            created: new UserActionInfo(r?.level?.created)
        };
        this.linked_proof_docs = r?.linked_proof_docs?.map((linked_proof_doc) => {
            return {
                userFile: linked_proof_doc?.userFile,
                user_file_id: linked_proof_doc?.userFile?._id,
                created: new UserActionInfo(linked_proof_doc.created)
            }
        });
        this.status = r?.status;
    }
}

//store user response here before send to backend.
class UserResponse {
    constructor(ur) {
        this._id = ur?._id;
        this.sequence_number = ur?.sequence_number;
        this.observation_immutable_id = ur?.observation_immutable_id;
        this.text = {
            html: ur?.text?.html || "",
            delta: ur?.text?.delta || getEmptyDelta(),
        };
        this.progress = {
            value: ur?.progress?.value,
        };
        this.level = {
            value: ur?.level?.value,
        };
        this.linked_proof_docs = ur?.linked_proof_docs?.filter(lp => lp.userFile).map((linked_proof_doc) => {
            return {
                user_file_id: linked_proof_doc.userFile._id,
            }
        });
        this.status = { value: ur?.status?.value };
    }

    //compare userResponse with response sent by backend
    //return true if responses are different, false otherwise
    isModified(initialResponse) {
        let userResponse = { ...this };
        //compareObjects will only compare obj1 lvl1 keys because initialResponse have additionnal fields compared to userResponse
        let modified = !compareObjects({ obj1: userResponse, obj2: initialResponse, falsyEquals: true });
        return modified;
    }

    //is used to check if user response is empty
    isEmpty() {
        if (isEmptyDelta(this.text.delta) && !this.progress.value && !this.level.value && this.linked_proof_docs.length === 0) {
            return true;
        }
        return false;
    }
}


export { Response, UserResponse };