import { styled } from '@mui/material/styles';
import muiAppBar from '@mui/material/AppBar';


const getLeafSvgString = (color) => {
    const svg = `<svg width='55' height='55' xmlns='http://www.w3.org/2000/svg'><path d="M27.5,2.5c-15,0-25,22-25,22s10,22,25,22s25-22,25-22S42.5,2.5,27.5,2.5z" fill='${color}'/></svg>`;
    const encodedSvg = encodeURIComponent(svg);
    return `data:image/svg+xml,${encodedSvg}`;
};


const StyledAppBar = styled(muiAppBar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    height: theme.appbarHeight,
    // position: 'relative', // Needed for absolute positioning of pseudo-elements
    // overflow: 'hidden', // To ensure the rotated rectangles don't overflow
    // backgroundImage: `url(${getLeafSvgString(theme.palette.greenLemon.light2)})`,
    // backgroundSize: '55px 55px', // Match the SVG size or adjust as needed
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center', // Center the SVG in the AppBar
    // Define your pseudo-elements here
    // '&::before, &::after, &::nth-child(1), &::nth-child(2)': {
    //     content: '""', // Required for pseudo-elements
    //     position: 'absolute',
    // },
    // // This is the first rectangle, you can customize it as needed
    // '&::before': {
    //     top: '-30px',
    //     left: '130px',
    //     width: '55px',
    //     height: '55px',
    //     borderRadius: "0 28px 0 28px",
    //     backgroundColor: theme.palette.greenLemon.light2, // Replace with your desired color
    //     transform: 'rotate(283.9deg)',
    //     zIndex: -1, // Ensure it's behind the AppBar content
    // },
    // // This is the second rectangle, you can add more as needed
    // '&::after': {
    //     top: '15px',
    //     left: '220px',
    //     width: '102px',
    //     height: '102px',
    //     borderRadius: "0 50px 0 50px",
    //     backgroundColor: theme.palette.greenLemon.light2, // Replace with your desired color
    //     // transform: 'rotate(-15deg)',
    //     zIndex: -1, // Ensure it's behind the AppBar content
    // },
    // '&::nth-child(1)': {
    //     top: '15px',
    //     left: '420px',
    //     width: '102px',
    //     height: '102px',
    //     borderRadius: "0 50px 0 50px",
    //     backgroundColor: theme.palette.greenLemon.light2, // Replace with your desired color
    //     // transform: 'rotate(-15deg)',
    //     zIndex: -1, // Ensure it's behind the AppBar content
    // },
    // '&::nth-child(2)': {
    //     top: '15px',
    //     left: '720px',
    //     width: '102px',
    //     height: '102px',
    //     borderRadius: "0 50px 0 50px",
    //     backgroundColor: theme.palette.greenLemon.light2, // Replace with your desired color
    //     // transform: 'rotate(-15deg)',
    //     zIndex: -1, // Ensure it's behind the AppBar content
    // },
}));


export { StyledAppBar }