//*********************************************************************************************************************************************//
//                                                               IONIC imports                                                                 //
//*********************************************************************************************************************************************//
// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonImg, IonInput, IonButton, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
// import { eyeOutline, eyeOffOutline } from 'ionicons/icons';

//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';



//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useHttp } from "../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
// import { LoginModal } from "../components/LoginModal";
// import { IdleModal } from "../components/IdleModal";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
// import logo from "../img/val_logo.png";
import { getServerURL } from "../config";
import { parseCustomerHid } from "../lib/lib";
import { User } from "../models/User";





const UserContext = createContext();

const UserProvider = ({ children }) => {

    const { setIsLoggedIn, isLoggedIn, httpRequest, shouldReloadUser, setShouldReloadUser } = useHttp();
    const [user, setUser] = useState(undefined);
    const [establishments, setEstablishments] = useState(undefined);
    const [selectedEstablishment, setSelectedEstablishment] = useState(undefined);
    const [notifications, setNotifications] = useState(undefined);
    const [customer, setCustomer] = useState(undefined);
    const [currentReferential_ImID, setCurrentReferential_ImID] = useState(undefined);
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [isUserPwdTemporary, setIsUserPwdTemporary] = useState(undefined);
    const [loginFlag, setLoginFlag] = useState(true);
    const [lists, setLists] = useState(undefined);
    const [alertDialog, setAlertDialog] = useState({ isOpen: false, message: "" });

    const location = useLocation();
    const navigate = useNavigate();



    useEffect(() => {
        if (!user || isUserPwdTemporary || location.pathname === `/${customer_hid}/login`) return;
        if (hasNoAuthorizations()) {
            setAlertDialog({ isOpen: true, message: "Votre utilisateur n'a aucun accès valide. Veuillez vous rapprocher de votre administrateur." });
        }
    }, [user, location.pathname, isUserPwdTemporary])


    //outside of Route context, useParams doen't work. So we parse manually the customer_hid from the location object
    const customer_hid = parseCustomerHid(location.pathname);

    //fetch user populated with its permissions and establishments in which user has any valid permission
    const fetch_user_and_establishments = async (customer_hid) => {
        setIsUserLoading(true);
        let response = await httpRequest({
            url: `${getServerURL()}/customer/${customer_hid}/init_data`,
            method: "get",
            withCredentials: true
        });
        if (response?.status === 200) {
            setCurrentReferential_ImID(response.data.referential_ImID);
            setUser(response.data.user);
            setCustomer(response.data.customer);
            setEstablishments(response.data.establishments);
            setNotifications(response.data.notifications);
            setIsUserPwdTemporary(response.data.user.password?.is_temporary ? true : false);

            //if there is a selected establishment in the url, then select this one, otherwise select the first one
            const searchParams = new URLSearchParams(location.search);
            const urlEstablishment_id = searchParams.get('establishment_id');
            let randomEstablishment = response.data.establishments?.[0];
            let urlEstablishment = response.data.establishments?.find(e => e._id === urlEstablishment_id);

            if (!urlEstablishment_id && randomEstablishment) {
                searchParams.set('establishment_id', randomEstablishment._id);

                // Update the browser's URL without reloading the page
                //use window object instead of useLocation because there is a lag with useLocation
                //this is a temporary fix
                const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
                navigate(newUrl, { replace: true });
            }
            setSelectedEstablishment(urlEstablishment ? urlEstablishment : randomEstablishment);
            setIsLoggedIn(true);
            setLoginFlag(false);
            //if user has been set to be reloaded, set it to false
            if (shouldReloadUser) {
                setShouldReloadUser(false);
            }
        }
        setIsUserLoading(false);
    }

    const fetchLists = useCallback(async (customer_hid) => {
        let response = await httpRequest({
            url: `${getServerURL()}/customer/${customer_hid}/lists_by_keys`,
            data: [
                "ACTIVITY_HANDLING_PAQ_LIST",
                "ACTIVITY_LIST",
                "BASE_ACTIONS_LIST",
                "BASE_ACTION_VALUES",
                "CRITERION_RESPONSE_STATUSES",
                "CUSTOMER_CARE_PAQ_LIST",
                "DETECTABILITY_PAQ_LIST",
                "ENVIRONMENTAL_DEMAND_PAQ_LIST",
                "FIELD_STATUSES",
                "FREQUENCY_PAQ_LIST",
                "INFORMATION_SYS_PAQ_LIST",
                "JOB_GROUP_LIST",
                "JOB_LIST",
                "LICENSE_MODULE_VALUES",
                "OBJECTIVE_PAQ_LIST",
                "OBSERVATION_GROUP_LIST",
                "OCCURENCE_PAQ_LIST",
                "PEOPLE_RISK_PAQ_LIST",
                "RESPONSE_LEVEL_LIST",
                "RESPONSE_STATUSES",
                "SOURCE_PAQ_LIST",
                "STATUS_PAQ_LIST",
            ],
            method: "post",
            withCredentials: true
        })
        if (response?.status === 200) {
            let parsedLists = JSON.parse(response.data);
            setLists(parsedLists);
        }
    }, [customer_hid])

    //always make urlEstablishment match selectedEstablishment
    useEffect(() => {
        if (!customer_hid) return;
        const searchParams = new URLSearchParams(location.search);
        const urlEstablishment_id = searchParams.get('establishment_id');
        if (urlEstablishment_id || !establishments) return;
        if (urlEstablishment_id !== selectedEstablishment?._id) {
            const urlEstablishment = establishments.find(e => e._id === urlEstablishment_id);
            if (urlEstablishment) {
                setSelectedEstablishment(urlEstablishment);
            }
        }
    }, [location]);


    //if customer_hid changes, reload base app data 
    useEffect(() => {
        if (!customer_hid) return;
        fetch_user_and_establishments(customer_hid);
        fetchLists(customer_hid);
    }, [customer_hid, shouldReloadUser]);


    //if shouldReloadUser is set to true, reload base app data
    //shouldReloadUser can be set to true by httpProvider when an access is denied by server
    //it happens when an admin downgrades user access during user open session
    //if so we reload user with its new authorizations set
    useEffect(() => {
        if (!customer_hid || !shouldReloadUser) return;
        fetch_user_and_establishments(customer_hid);
    }, [customer_hid, shouldReloadUser]);

    const loginUser = async (customer_hid) => {
        fetchLists(customer_hid);
        await fetch_user_and_establishments(customer_hid);
    }

    const logoutUser = async () => {
        let response = await httpRequest({
            url: `${getServerURL()}/customer/${customer_hid}/logout`,
            method: "get",
            withCredentials: true
        })
        if (response.data) {
            setIsLoggedIn(false);
        }
    }

    //returns true if authorization is granted, false otherwise
    //Any couple of the array matches with one of user authorizations and it's granted.
    //accepts either an array of accesses = [{baseAction, module}], or a couple baseAction / module
    const isAuthorized = ({ baseAction, module, accesses }) => {
        if (!user?.authorizations) return false;
        let authorizationGranted = false;
        //evaluate couple baseActions / module
        const isCADM = module === lists?.LICENSE_MODULE_VALUES["CADM"];
        authorizationGranted = user.authorizations?.some(e => {
            return (
                e?.module === module &&
                e?.baseActions?.includes(baseAction) &&
                (isCADM || e?.establishment_id === selectedEstablishment?._id)
            )
        });
        if (authorizationGranted) return true;

        //evaluate accesses
        if (!accesses) return false;
        if (accesses?.length === 0) return true;
        for (let access of accesses) {
            const isCADM = access?.module === lists?.LICENSE_MODULE_VALUES["CADM"];
            authorizationGranted = user.authorizations?.some(e => {
                return (
                    e?.module === access?.module &&
                    e?.baseActions?.includes(access?.baseAction) &&
                    (isCADM || e?.establishment_id === selectedEstablishment?._id)
                )
            });
            if (authorizationGranted) return true;
        }
        return false;
    }

    const hasNoAuthorizations = () => {
        if (!user?.authorizations || user?.authorizations?.length === 0) return true;
    }

    const handleClickLogout = () => {
        setLoginFlag(true);
        logoutUser();
        //if the user clicks on disconnect button, then we do not preserve environment and set loginFlag to true -> user will be redirected to login page
    }

    const destroyUser = () => {
        setUser(undefined);
    }

    return (
        <UserContext.Provider
            value={{
                user,
                loginUser,
                logoutUser,
                handleClickLogout,
                lists,
                isUserLoading,
                isLoggedIn,
                loginFlag,
                setEstablishments,
                establishments,
                setSelectedEstablishment,
                selectedEstablishment,
                customer,
                fetch_user_and_establishments,
                currentReferential_ImID,
                notifications,
                isAuthorized,
                hasNoAuthorizations,
                customer_hid,
                destroyUser,
                isUserPwdTemporary
            }}>
            <>
                <Dialog
                    // fullScreen={fullScreen}
                    open={alertDialog.isOpen}
                >
                    <DialogContent>
                        {alertDialog.message}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setAlertDialog(s => ({ ...s, isOpen: false }));
                            }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                {children}
            </>
        </UserContext.Provider >
    );
}

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };