import { UserActionInfo } from "./UserActionInfo";

const getLastUpdated = (b) => {
    let lastVersion = new UserActionInfo(b?.versions?.[b?.versions?.length - 1]?.created);
    let lastUserFileKey = new UserActionInfo(b?.userFileKeys?.[b?.userFileKeys?.length - 1]?.created);
    let lastStatus = new UserActionInfo(b?.statuses?.[b?.statuses?.length - 1]?.created);
    let lastUpdated = undefined;
    if (lastVersion) lastUpdated = lastVersion;
    if (lastUserFileKey && new Date(lastUserFileKey.on) < new Date(lastUpdated.on)) lastUpdated = lastUserFileKey;
    if (lastStatus && new Date(lastStatus.on) < new Date(lastUpdated.on)) lastUpdated = lastStatus;
    return lastUpdated;
}


class Blob {
    constructor(b) {
        this._id = b?._id;
        this.establishment_id = b?.establishment_id;
        this.signedUrl = b?.signedUrl;
        this.versions = b?.versions?.map(version => {
            return {
                number: version?.number,
                blobName: version?.blobName,
                fileType: version?.fileType,
                created: new UserActionInfo(version?.created)
            }
        });
        this.userFileKeys = b?.userFileKeys?.map(userFileKey => {
            return {
                value: userFileKey?.value,
                created: new UserActionInfo(userFileKey?.created)
            }
        });
        this.statuses = b?.statuses?.map(status => {
            return {
                value: status?.value,
                created: new UserActionInfo(status?.created)
            }
        });
        this.lastUpdated = getLastUpdated(b);
        this.clientAction = b?.clientAction;
    }
}


export { Blob };