//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useForm } from "react-hook-form";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { usePaths } from "../../context/PathsProvider";
import { useHttp } from "../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../config";
import { User, Role } from "../../models/User";
import { EstablishmentUsersDialog } from "./EstablishmentUsersDialog";


//show list of all users that have any permission in the selected establishment, should it be validated or not

const EstablishmentUsers = () => {

    const { httpRequest } = useHttp();
    const { isLoggedIn, user, establishments, selectedEstablishment, lists } = useUser();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const [establishmentUsers, setEstablishmentUsers] = useState(undefined);
    const emptyEstablishmentUsersDialogState = { isOpen: false, title: "", user: undefined };
    const [establishmentUsersDialogState, setEstablishmentUsersDialogState] = useState(emptyEstablishmentUsersDialogState);
    const { customer_hid } = useParams();

    const fetchusers = useCallback(async () => {
        setIsLoading(true);
        let response = await httpRequest({
            url: `${getServerURL()}/customer/${customer_hid}/user/fetch`,
            data: { establishment_id: selectedEstablishment._id },
            headers: { "Content-type": "Application/json" },
            method: 'post',
            showLoading: false,
            withCredentials: true
        });
        if (response.status === 200) {
            setEstablishmentUsers(response.data.users);
        }
        setIsLoading(false);
    }, [])

    useEffect(() => {
        if (!isLoggedIn || !user) return;
        fetchusers();
    }, [isLoggedIn, user, selectedEstablishment])

    const establishmentNames = useMemo(() => {
        let arr = [];
        for (let establishment of establishments) {
            arr.push(establishment.name);
        }
        return arr;
    }, [establishments])

    const getFilterSelectOptions = (accessKey) => {
        switch (accessKey) {
            case "role":
                return lists?.ROLES_LIST?.french || [];
            case "establishment":
                return establishmentNames;
        }
    }

    const columns = useMemo(() => [
        {
            accessorKey: 'firstname',
            header: 'Prénom',
            enableGrouping: false,
            enableColumnFilterModes: false,
            size: 150,
        },
        {
            accessorKey: 'lastname',
            header: 'Nom',
            enableGrouping: false,
            enableColumnFilterModes: false,
            size: 150,
        },
        {
            accessorKey: 'job',
            header: 'Poste',
            enableGrouping: true,
            enableColumnFilterModes: false,
            size: 150,
        },
        {
            id: 'role',
            accessorFn: (row) => {
                let index = lists?.ROLES_LIST?.english?.findIndex(e => e === row.permissions[selectedEstablishment?._id]?.role);
                return index !== -1 ? lists?.ROLES_LIST?.french[index] : "";
            },
            header: 'Rôle',
            enableGrouping: true,
            enableColumnFilterModes: false,
            filterVariant: "multi-select",
            filterSelectOptions: getFilterSelectOptions("role"),
            size: 150,
        },
        {
            accessorKey: 'establishment_id.name',
            header: 'Employeur',
            enableGrouping: true,
            enableColumnFilterModes: false,
            filterVariant: "multi-select",
            filterSelectOptions: getFilterSelectOptions("establishment"),
            size: 150,
        },
    ], [establishmentUsers]);

    const toolbar = (<Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "end" }}>
        <Button
            variant="outlined"
            onClick={() => setEstablishmentUsersDialogState({
                isOpen: true,
                user: new User({ clientAction: "new", customer_id: selectedEstablishment.customer_id, establishment_id: selectedEstablishment._id }),
                title: `Nouvel utilisateur dans l'établissement "${selectedEstablishment?.name}"`
            })}
        >Nouvel utilisateur</Button>
    </Box>);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <MaterialReactTable
                    columns={columns}
                    data={establishmentUsers ? establishmentUsers : []}
                    enableColumnOrdering
                    enableGlobalFilter //Material React Table global filter will only work if empty string instead of undefined
                    enableGrouping
                    enableEditing
                    enablePinning
                    enableColumnResizing
                    enableColumnFilterModes
                    editingMode="modal"
                    initialState={{ density: 'comfortable', showColumnFilters: true, columnPinning: { right: ["mrt-row-actions"] } }}
                    state={{ showSkeletons: isLoading }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: 'Editer', //change "Actions" to "Editer"
                            Cell: ({ row }) => (
                                <IconButton
                                    onClick={() => setEstablishmentUsersDialogState({
                                        isOpen: true,
                                        user: row?.original,
                                        title: `Modifier utilisateur ${row?.original?.firstname} ${row?.original?.lastname}`
                                    })}
                                >
                                    <EditIcon />
                                </IconButton>
                            )
                        }
                    }}
                    muiTableBodyCellProps={{ align: "center" }}
                    renderEmptyRowsFallback={() => {
                        return <div style={{ width: "200px", textAlign: "center", position: "fixed", left: "calc(50vw - 100px)" }}><h3><em>{establishmentUsers?.length > 0 ? 'Aucun résultat.' : 'Aucun utilisateur.'}</em></h3></div>
                    }}
                    // enableDensityToggle={false}
                    localization={MRT_Localization_FR}
                    enablePagination={false}
                    enableRowVirtualization
                    rowVirtualizerProps={{
                        overscan: 5, //adjust the number or rows that are rendered above and below the visible area of the table
                        estimateSize: () => establishmentUsers?.length, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
                    }}
                    renderBottomToolbarCustomActions={({ table, row }) => toolbar}
                    muiTableContainerProps={({ table }) => ({
                        sx: {
                            maxHeight: `calc(100vh - ${theme.appbarHeight} - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`
                        }
                    })}
                />
            </Box>
            <EstablishmentUsersDialog
                isOpen={establishmentUsersDialogState.isOpen}
                onClose={async () => {
                    setEstablishmentUsersDialogState(emptyEstablishmentUsersDialogState);
                    fetchusers();
                }}
                title={establishmentUsersDialogState.title}
                user={establishmentUsersDialogState.user}
            />
        </>
    );
};

export default EstablishmentUsers;
