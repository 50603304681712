
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";


const CheckedRadioIcon = () => {
    const theme = useTheme();
    return (
        <Box sx={{ width: "30px", height: "24px" }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 37 30"
                fill="none"
                style={{ display: "block" }}
            >
                <rect width={28} height={28} x={1.208} y={0.5} stroke={"currentColor"} rx={14} strokeWidth="2" />
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m8.708 13 6.5 8.5 20-19.5"
                />
            </svg>
        </Box>
    )
}


export default CheckedRadioIcon;