import { createContext, useContext, useState, useRef, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useHttp } from "./HttpProvider";
import { useUser } from "./UserProvider";
import { Typography } from '@mui/material';
import { getServerURL } from '../config';
import { parseCustomerHid } from "../lib/lib";



const MfaContext = createContext();

const MfaProvider = ({ children }) => {
    const [mfaStatus, setMfaStatus] = useState({ isMfaVerified: false, expireAt: undefined });
    const emptyTwoFADialogState = false;
    const [twoFADialog, setTwoFADialog] = useState(emptyTwoFADialogState);
    const emptyEmailAuthDialogState = false;
    const [emailAuthDialogState, setEmailAuthDialogState] = useState(emptyEmailAuthDialogState);
    const { httpRequest } = useHttp();
    const codeRef = useRef(null);
    const onCompleted = useRef(null);
    const { isLoggedIn, isUserPwdTemporary, isUserLoading } = useUser()
    const location = useLocation();

    //outside of Route context, useParams doen't work. So we parse manually the customer_hid from the location object
    const customer_hid = parseCustomerHid(location.pathname);

    useEffect(() => {
        if (!isLoggedIn || isUserPwdTemporary || isUserLoading) {
            return;
        }

        const checkBackendMfaStatus = async () => {
            let response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/check_mfa_status`,
                method: "get",
                withCredentials: true,
            });
            if (response.status === 200) {
                setMfaStatus(response.data);
            }
        }

        checkBackendMfaStatus();

    }, [isLoggedIn, isUserPwdTemporary, isUserLoading])

    const requestEmailMfa = async (callback) => {
        if (!isMfaAuthed()) {
            let response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/req_mfa}`,
                method: "get",
                withCredentials: true
            });
            if (response.status === 200) {
                onCompleted.current = callback;
                setTwoFADialog(true);
            }
        } else {
            callback();
        }
    }

    // const requestEmailAuth = async (callback, email, username) => {
    //     let response = await httpRequest({
    //         url: `${getServerURL()}/customer/${customer_hid}/req_email_auth}`,
    //         method: "post",
    //         data: { email: email, username: username },
    //         headers: { "Content-type": "Application/json" },
    //         withCredentials: true
    //     });
    //     if (response.status === 200) {
    //         onCompleted.current = callback;
    //         setEmailAuthDialogState(true);
    //     }
    // }


    const isMfaAuthed = () => {
        if (mfaStatus.isMfaVerified && mfaStatus.expireAt && Date.now() < mfaStatus.expireAt) {
            return true;
        }
        return false;
    }


    const handleVerify = async () => {
        let response = await httpRequest({
            url: `${getServerURL()}/customer/${customer_hid}/check_mfa_code}`,
            data: { code: codeRef.current.value },
            method: "post",
            headers: { "Content-type": "Application/json" },
            withCredentials: true,
        })
        if (response.status === 200) {
            setMfaStatus({ isMfaVerified: true, expireAt: response.data?.expireAt });
            onCompleted.current();
            onCompleted.current = undefined;
        }
        setTwoFADialog(emptyTwoFADialogState);
    }


    const dismissTwoFADialog = (event, reason) => {
        if (reason !== "backdropClick") {
            setTwoFADialog(emptyTwoFADialogState);
        }
    }

    // const dismissEmailAuthDialog = (event, reason) => {
    //     if (reason !== "backdropClick") {
    //         setEmailAuthDialogState(emptyEmailAuthDialogState);
    //     }
    // }


    return (
        <MfaContext.Provider
            value={{
                isMfaAuthed,
                requestEmailMfa,
                // requestEmailAuth
            }}>
            <>
                <Dialog open={twoFADialog} onClose={dismissTwoFADialog}>
                    <DialogTitle>Authentification à 2 facteurs requise</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Un code vous a été envoyé par email. Veuillez le saisir ci-dessous afin de procéder à votre authentification à 2 facteurs.
                        </Typography>
                        <TextField
                            autoFocus
                            label="Code"
                            variant="standard"
                            inputRef={codeRef}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={dismissTwoFADialog}>Annuler</Button>
                        <Button onClick={handleVerify} variant="contained">Confirmer</Button>
                    </DialogActions>
                </Dialog>
                {/* <Dialog open={emailAuthDialogState} onClose={dismissEmailAuthDialog}>
                    <DialogTitle>Reset pwd</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Vous pouvez réaliser cette procédue en autonomie si vous avez associé une adresse email à votre utilisateur. Dans le cas contraire, veuillez contacter votre administrateur.
                        </Typography>
                        <TextField
                            autoFocus
                            label="Code"
                            variant="standard"
                            inputRef={codeRef}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={dismissEmailAuthDialog}>Annuler</Button>
                        <Button onClick={handleVerify} variant="contained">Confirmer</Button>
                    </DialogActions>
                </Dialog> */}
                {children}
            </>
        </MfaContext.Provider>
    );
}

const useMfa = () => useContext(MfaContext);

export { MfaProvider, useMfa };