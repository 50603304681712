import { createContext, useContext, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import axios from 'axios';
import { useToast } from "./ToastProvider";
import CONFIG from "../config/default";
import { parseCustomerHid } from "../lib/lib";


const HttpContext = createContext();

const HttpProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [shouldReloadUser, setShouldReloadUser] = useState(false);
    const { presentToast } = useToast();
    const TIMEOUT = 600000;
    const MAX_ATTEMPTS = 10;
    const VALID_HTTP_STATUSES = [200, 401];
    const navigate = useNavigate();
    const location = useLocation();

    //outside of Route context, useParams doen't work. So we parse manually the customer_hid from the location object
    const customer_hid = parseCustomerHid(location.pathname);

    const httpRequest = async ({ showLoading = true, getCompleteResponse = false, attempt = 1, ...req } = { req }) => {
        showLoading && setIsLoading(true);
        return axios({
            ...req,
            validateStatus: function (status) {
                return getCompleteResponse || VALID_HTTP_STATUSES.includes(status);
            },
            timeout: TIMEOUT,
            transitional: {
                clarifyTimeoutError: true
            }
        }).then(response => {
            if (response.status === 401) setIsLoggedIn(false);
            if (response.data?.toastMessage) {
                presentToast({
                    severity: "error",
                    message: response.data?.toastMessage,
                    duration: 5000
                });
            }
            return getCompleteResponse ? response : { data: response.data, status: response.status };
        }).catch(err => {
            if (err.code === "ETIMEDOUT" && attempt < MAX_ATTEMPTS) {
                let attemptNumber = attempt + 1;
                return httpRequest({ ...req, showLoading: showLoading, getCompleteResponse: getCompleteResponse, attempt: attemptNumber });
            } else if (err.code === "ETIMEDOUT" && attempt >= MAX_ATTEMPTS) {
                presentToast({
                    severity: "error",
                    message: "Impossible de communiquer avec le serveur, veuillez relancer l'application"
                })
                return { data: undefined, status: "timeout" };
            } else if (err?.response?.status === 403) {
                setShouldReloadUser(true);
                navigate(`/${customer_hid}/app/home`);
                presentToast({
                    severity: "error",
                    message: err?.response?.data?.toastMessage ? err?.response?.data?.toastMessage : "Erreur",
                    duration: 50000
                });
                return { data: undefined, status: "erreur" };
            }
            else {
                presentToast({
                    severity: "error",
                    message: err?.response?.data?.toastMessage ? err?.response?.data?.toastMessage : "Erreur",
                    duration: err?.response?.data?.toastMessage ? 50000 : 5000
                });
                return { data: undefined, status: "erreur" };
            }
        }).finally(() => {
            showLoading && setIsLoading(false);
        })
    }

    // const httpRequest = async (req, showLoading = true, getCompleteResponse = false) => {
    //     try {
    //         showLoading && setIsLoading(true);
    //         const response = await axios({
    //             ...req,
    //             validateStatus: function (status) {
    //                 return status === 200 || status === 401 || getCompleteResponse;
    //             },
    //             timeout: TIMEOUT
    //         });
    //         if (response.status === 401) {
    //             setIsLoggedIn(false);
    //         } if (response.status !== 200) {
    //             console.log(req.url + " http-status: " + response.status);
    //             return getCompleteResponse ? response : false;
    //         } else if (response.status === 200) {
    //             return getCompleteResponse ? response : response.data;
    //         }
    //     } catch (err) {
    //         console.log(req.url + " " + err);
    //         presentToast({
    //             severity: "error",
    //             message: err.message.indexOf("timeout") !== -1 ? "erreur: vérifiez votre connexion internet" : "erreur"
    //         })
    //         return false;
    //     } finally {
    //         showLoading && setIsLoading(false);
    //     }
    // }

    return (
        <HttpContext.Provider
            value={{
                // fetchData,
                // sendData,
                // fetchDataPromise,
                setIsLoggedIn,
                isLoggedIn,
                httpRequest,
                shouldReloadUser,
                setShouldReloadUser
            }}>
            <>
                {isLoading &&
                    <Backdrop open={isLoading} sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
                        <CircularProgress size={48} color="primary" />
                    </Backdrop>
                }
                {children}
            </>
        </HttpContext.Provider>
    );
}

const useHttp = () => useContext(HttpContext);

export { HttpProvider, useHttp };