//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Tooltip from "@mui/material/Tooltip";
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import BarChartIcon from '@mui/icons-material/BarChart';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';//dashboard

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useForm } from "react-hook-form";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { usePaths } from "../../context/PathsProvider";
import { useHttp } from "../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { CircularProgressBar } from "../../components/CircularPorgressBar";
import { AuthorizationChecker } from "../../components/AuthorizationChecker";


//*********************************************************************************************************************************************//
//                                                           custom HOOKS imports                                                              //
//*********************************************************************************************************************************************//
import useFileDownloader from "../../hooks/useFileDownloader";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../config";
import { getDateString } from "../../lib/lib";


//show list of all users that have any permission in the selected establishment, should it be validated or not

const EvalList = () => {

    const { httpRequest } = useHttp();
    const { isLoggedIn, user, establishments, selectedEstablishment, lists } = useUser();
    const { paths } = usePaths();
    const navigate = useNavigate();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const { customer_hid } = useParams();
    const [evalsList, setEvalsList] = useState(undefined);
    const { downloadFileBuffer } = useFileDownloader();

    const fetchEvals = useCallback(async () => {
        setIsLoading(true);
        let response = await httpRequest({
            url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/list`,
            method: 'get',
            showLoading: true,
            withCredentials: true
        });
        if (response.status === 200) {
            setEvalsList(response.data.sort((a, b) => new Date(b.HAS_date) - new Date(a.HAS_date)));
        }
        setIsLoading(false);
    }, [selectedEstablishment])

    useEffect(() => {
        if (!isLoggedIn || !user) return;
        fetchEvals();
    }, [isLoggedIn, user, selectedEstablishment])

    const columns = useMemo(() => [
        {
            id: "name",
            accessorFn: (row) => (<div>{row.evalName}<br />{row.referentialName} {getDateString(row.HAS_date)}</div>),
            header: 'Evaluation',
            enableGrouping: false,
            enableColumnFilterModes: false,
        },
        {
            id: "status",
            accessorFn: (row) => row.is_archived ? "Archivé" : "En cours",
            header: 'Statut',
            enableGrouping: false,
            enableColumnFilterModes: false,
        },
        {
            accessorKey: 'progress',
            header: 'Taux de remplissage',
            Cell: ({ renderedCellValue }) => <CircularProgressBar progress={Number(renderedCellValue * 100)} />,
            enableGrouping: true,
            enableColumnFilterModes: false,
        },
    ], [evalsList]);

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <Box sx={{ minWidth: "700px", width: '60%', maxWidth: "1000px", marginTop: "24px" }}>
                    <MaterialReactTable
                        columns={columns}
                        data={evalsList || []}
                        enableColumnOrdering={false}
                        enableGlobalFilter={false}
                        enableGrouping={false}
                        enableEditing
                        enablePinning={false}
                        enableColumnActions={false}
                        enableColumnResizing={false}
                        enableColumnFilterModes={false}
                        enableTopToolbar={false} //hide top toolbar
                        enableBottomToolbar={false} //hide bottom toolbar
                        initialState={{ density: 'comfortable', showColumnFilters: false, columnPinning: { right: ["mrt-row-actions"] } }}
                        state={{ showSkeletons: isLoading }}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: 'Actions',
                                Cell: ({ row }) => (
                                    <>
                                        <AuthorizationChecker
                                            module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                            baseAction={lists?.BASE_ACTION_VALUES["RDO"]}
                                        >
                                            <Tooltip title="Accéder au tableau de bord de l'auto-évaluation">
                                                <IconButton
                                                    onClick={() => {
                                                        return navigate(paths.evalDashboard.app({
                                                            establishment_id: selectedEstablishment._id,
                                                            customer_hid: customer_hid,
                                                            referential_ImID: row.original.immutable_id
                                                        }))
                                                    }}
                                                >
                                                    <DonutSmallIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </AuthorizationChecker>
                                        <AuthorizationChecker
                                            module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                            baseAction={lists?.BASE_ACTION_VALUES["RDO"]}
                                        >
                                            <Tooltip title={"Accéder à l'auto-évaluation"}>
                                                <IconButton
                                                    onClick={() => navigate(paths.evalCockpit.app({
                                                        establishment_id: selectedEstablishment._id,
                                                        customer_hid: customer_hid,
                                                        referential_ImID: row.original.immutable_id
                                                    }))}
                                                >
                                                    {row.original?.is_archived?.value ? <VisibilityIcon /> : <EditIcon />}
                                                </IconButton>
                                            </Tooltip>
                                        </AuthorizationChecker>
                                        <AuthorizationChecker
                                            module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                            baseAction={lists?.BASE_ACTION_VALUES["RDO"]}
                                        >
                                            {user?.job_groups?.value?.some(e => (["0", "1"].includes(e))) && //job groups qualité & direction
                                                <Tooltip title="Exporter auto-évaluation">
                                                    <IconButton
                                                        onClick={async () => {
                                                            let response = await httpRequest({
                                                                url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${row.original.immutable_id}/export`,
                                                                method: 'get',
                                                                responseType: "blob",
                                                                showLoading: true,
                                                                withCredentials: true
                                                            });
                                                            if (response.status === 200) {
                                                                const today = new Date();
                                                                const year = today.getFullYear();
                                                                const month = String(today.getMonth() + 1).padStart(2, '0');
                                                                const day = String(today.getDate()).padStart(2, '0');
                                                                const formattedDate = `${year}/${month}/${day}`;
                                                                downloadFileBuffer(response.data, `${formattedDate}_autoEval_export.docx`);
                                                            }
                                                        }}
                                                    >
                                                        <CloudDownloadIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </AuthorizationChecker>
                                    </>
                                )
                            }
                        }}
                        renderEmptyRowsFallback={() => {
                            return <div style={{ width: "200px", textAlign: "center", position: "fixed", left: "calc(50vw - 100px)" }}><h3><em>{[].length > 0 ? 'Aucun résultat.' : 'Aucun référentiel.'}</em></h3></div>
                        }}
                        localization={MRT_Localization_FR}
                        enablePagination={false}
                        rowVirtualizerProps={{
                            overscan: 5, //adjust the number or rows that are rendered above and below the visible area of the table
                            // estimateSize: () => establishmentUsers?.length, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
                        }}
                        muiTablePaperProps={{
                            elevation: 2, //change the mui box shadow
                            //customize paper styles
                            sx: {
                                borderRadius: '0',
                                border: '5px solid #e0e0e0',
                            },
                        }}
                        muiTableHeadCellProps={{ align: "center", sx: { border: "1px solid #e0e0e0", boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' } }}
                        muiTableBodyCellProps={{ align: "center", sx: { border: "1px solid #e0e0e0", boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' } }}
                    />
                </Box>
            </Box >
        </>
    )
};

export default EvalList;
