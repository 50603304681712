import { createTheme } from '@mui/material/styles';
import { Zoom } from '@mui/material';
import { getServerURL } from '../config';

const getEnv = () => {
    const serverUrl = getServerURL();
    let env = "LOCAL";
    if (serverUrl.startsWith("https://api.e-qualiplus")) {
        env = "PROD";
    } else if (serverUrl.startsWith("https://api.qual")) {
        env = "QUAL"
    } else if (serverUrl.startsWith("https://api.dev")) {
        env = "DEV"
    }
    return env;
}


const colors = {
    orange: {
        primary: {
            main: 'rgb(255, 158, 41)',
            shades: {
                "50%": 'rgba(255, 158, 41, 0.5)'
            }
        },
        secondary: {
            tint1: {
                main: "#f7aa4b"
            },
            tint2: {
                main: "#f9d0a0"
            },
            tint3: {
                main: "#fce4c7"
            }
        }
    },
    forest: {
        primary: {
            main: 'rgb(18, 125, 120)',
            shades: {
                "50%": "rgba(18, 125, 120, 0.5)"
            }
        },
        secondary: {
            tint1: {
                main: "#20b3aa"
            },
            tint2: {
                main: "#9fd5da",
                shades: {
                    "40%": "rgba(159, 213, 218, 0.4)",
                    "20%": "rgba(159, 213, 218, 0.2)",
                }
            },
            tint3: {
                main: "#cce8ec",
                shades: {
                    "40%": "rgba(204, 232, 236, 0.4)"
                }
            }
        }
    },
    azure: {
        primary: {
            main: 'rgb(46, 150, 204)',
            shades: {
                "35%": "rgba(46, 150, 204, 0.35)",
                "20%": "rgba(46, 150, 204, 0.20)",
                "10%": "rgba(46, 150, 204, 0.10)",
            }
        },
    },
    pink: {
        secondary: {
            tint1: { main: 'rgb(235, 90, 126)' }
        },
    },
    greenLemon: {
        primary: {
            main: 'rgb(143, 184, 31)',
            shades: {
                "70%": 'rgba(143, 184, 31, 0.70)',
                "60%": 'rgba(143, 184, 31, 0.60)',
                "35%": 'rgba(143, 184, 31, 0.35)',
                "20%": 'rgba(143, 184, 31, 0.2)',
                "15%": 'rgba(143, 184, 31, 0.15)',
                "10%": 'rgba(143, 184, 31, 0.1)',
                "5%": 'rgba(143, 184, 31, 0.05)',
            },
        },
        secondary: {
            tint1: { main: "#bad157" },
            tint2: { main: "#e3ebba" },
            tint3: { main: "#f1f5dc" }
        }
    },

};


const getMainColor = () => {
    const env = getEnv();
    switch (env) {
        case "PROD": return colors.forest.primary.main;
        case "QUAL": return colors.azure.primary.main;
        case "DEV": return colors.orange.primary.main;
        default: return colors.greenLemon.primary.main;
    }
}

const theme = createTheme({
    appbarHeight: "64px",
    palette: {
        primary: { main: getMainColor() },
        greenLemon: { ...colors.greenLemon },
        azure: { ...colors.azure },
        forest: { ...colors.forest },
        orange: { ...colors.orange },
        pink: { ...colors.pink },
    },
    components: {
        MuiTooltip: {
            defaultProps: {
                TransitionComponent: Zoom,
                arrow: true,
                disableInteractive: true
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontSize: "15px",
                    padding: '4px 16px',
                },
            },
        },
    },
    typography: {
        fontFamily: 'montserrat, Arial, sans-serif',
        fontWeightRegular: 450,
        fontWeightBold: 700
    },
});

export default theme;