//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useLayoutEffect, useEffect, useRef, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { usePaths } from "../../../context/PathsProvider";
import { useHttp } from "../../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { CircularProgressBar } from "../../../components/CircularPorgressBar";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../../config";



const EvalProgressRate = () => {

    const { lists, customer_hid, selectedEstablishment, isLoggedIn } = useUser();
    const { httpRequest } = useHttp();

    const [isLoading, setIsLoading] = useState(undefined);
    const [graphData, setGraphData] = useState(undefined);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const referential_ImID = params.get('referential_ImID');

    useLayoutEffect(() => {
        if (!isLoggedIn || !customer_hid || !selectedEstablishment || !referential_ImID) return;
        const fetchGraphData = async () => {
            setIsLoading(true);
            let response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${referential_ImID}/dashboard/chapterProgress`,
                method: 'get',
                showLoading: false,
                withCredentials: true
            });
            if (response.status === 200) {
                const progressData = response.data.progressData;
                setGraphData(progressData.averageProgress);
            }
            setIsLoading(false);
        }
        fetchGraphData();
    }, [customer_hid, selectedEstablishment, referential_ImID, isLoggedIn])



    return (
        <>
            {isLoading ?
                <>
                    <Box sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <CircularProgress />
                    </Box>
                </>
                :
                <>
                    {typeof graphData === 'number' && !isNaN(graphData) ?
                        <Box sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px"
                        }} >
                            <Typography sx={{ color: '#666', fontSize: "18", fontWeight: "bold" }}>
                                Progression évaluation
                            </Typography>
                            <CircularProgressBar progress={graphData} size={70} fontSize={"16px"} />
                        </Box >
                        :
                        <Typography>Une erreur est survenue</Typography>
                    }
                </>
            }
        </>
    );
};

export { EvalProgressRate };