import { Box } from "@mui/material";


const CountIcon = ({ number }) => {

    return (
        <Box sx={{ width: "24px", height: "24px" }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 28 28"
                style={{ display: "block" }}
            >
                <rect width={28} height={28} x={0.286} y={0.671} fill="#FF9E29" rx={14} />
                <text
                    x="50%" // Position the text in the middle of the SVG
                    y="16px" // Position the text in the middle of the SVG
                    dominantBaseline="middle" // Vertically center the text
                    textAnchor="middle" // Horizontally center the text
                    fill="white" // Text color
                    fontSize="18" // Text size, adjust as needed
                    fontWeight="bold"
                >
                    {number}
                </text>
            </svg>
        </Box >
    )
}


export default CountIcon;