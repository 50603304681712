//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import datalabelsPlugin from 'chartjs-plugin-datalabels';


//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { usePaths } from "../../../context/PathsProvider";
import { useHttp } from "../../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { RadarChart } from "./RadarChart";
import { BarChartProgress } from "./BarChartProgress";
import { DoughnutChart } from "./DoughnutChart";
import { RefData } from "./RefData";
import { QuestEvaluatifData } from "./QuestEvaluatifData";
import { EvalProgressRate } from "./EvalProgressRate";
import { EvalActionButtons } from "./EvalActionButtons";
import { BarChartWeakStr } from "./BarChartWeakStr";
import { BarChartAevActions } from "./BarChartAevActions";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../../config";
import { User, Role } from "../../../models/User";


//padding is page padding, space is space between elements.
//maxHeight is maximum height of children elements
const ResponsiveContainer = ({ gap = 0, maxHeight = undefined, aspectRatio = "100%", children }) => {
    // let aspectRatioVal = aspectRatio.split("%")?.[0] || 0;
    return (
        <Box sx={{
            position: 'relative',
            width: '100%',
            maxHeight: `calc(${maxHeight} - ${gap}px)`, //respects aspectRatio until maxHeight is reached. Used to allow a width increase of the component without increasing the height.
            '&::before': {
                content: '""',
                display: 'block',
                paddingTop: `calc(${aspectRatio} - ${gap}px)` // aspectRatio is controlled with this property. 100% is 1, 50% is 0.5 etc...
            },
            '& > .children-content': {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        }}>
            <div className="children-content">
                {children}
            </div>
        </Box>
    )
};


const EvalDashboard = () => {

    const { httpRequest } = useHttp();
    const { isLoggedIn, user, establishments, selectedEstablishment, lists } = useUser();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const { customer_hid } = useParams();

    const margin = theme.spacing(2); //stored ad "16px"
    const marginVal = parseInt(theme.spacing(2), 10); //stored as 16
    const innerPadding = theme.spacing(2); //stored ad "16px"
    const innerPaddingVal = parseInt(theme.spacing(2), 10); //stored as 16

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const referential_ImID = params.get('referential_ImID');

    //register chartJS elements
    ChartJS.register(
        ...registerables,
        annotationPlugin,
    );

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    maxHeight: `100%`,
                    background: '#f7f7f7',
                    overflowY: "auto",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box sx={{
                    maxWidth: "1536px",
                    width: "100%",
                    padding: innerPadding,
                    '&::after': { // Trick to have padding inside scroll area of the container
                        content: '""',
                        display: 'block',
                        height: innerPadding,
                        width: '100%'
                    }
                }}>
                    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "start", gap: margin }} >
                        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: margin }} >
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }} >
                                <Box sx={{ display: "flex", width: "100%", height: "auto", gap: innerPadding, flexWrap: "wrap" }}>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `calc(50% - ${innerPaddingVal / 2}px)` }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal}>
                                            <RefData />
                                        </ResponsiveContainer>
                                    </Paper>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `calc(50% - ${innerPaddingVal / 2}px)` }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal}>
                                            <QuestEvaluatifData />
                                        </ResponsiveContainer>
                                    </Paper>
                                </Box>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }} >
                                <Box sx={{ display: "flex", width: "100%", height: "auto", gap: innerPadding, flexWrap: "wrap" }}>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `calc(50% - ${innerPaddingVal / 2}px)` }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal}>
                                            <EvalProgressRate />
                                        </ResponsiveContainer>
                                    </Paper>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `calc(50% - ${innerPaddingVal / 2}px)` }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal}>
                                            <EvalActionButtons />
                                        </ResponsiveContainer>
                                    </Paper>
                                </Box>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }} >
                                <Box sx={{ display: "flex", width: "100%", height: "auto", gap: innerPadding, flexWrap: "wrap" }}>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `100%` }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal}>
                                            <BarChartWeakStr />
                                        </ResponsiveContainer>
                                    </Paper>
                                </Box>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }} >
                                <Box sx={{ display: "flex", width: "100%", height: "auto", gap: innerPadding, flexWrap: "wrap" }}>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `100%` }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal}>
                                            <BarChartAevActions />
                                        </ResponsiveContainer>
                                    </Paper>
                                </Box>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }} >
                                <Box sx={{ display: "flex", width: "100%", height: "auto", gap: innerPadding, flexWrap: "wrap" }}>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: "100%" }}>
                                        <ResponsiveContainer aspectRatio={"50%"} gap={innerPaddingVal * 1.5}>
                                            <BarChartProgress />
                                        </ResponsiveContainer>
                                    </Paper>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `calc(50% - ${innerPaddingVal / 2}px)` }}>
                                        <ResponsiveContainer aspectRatio={"100%"}>
                                            <DoughnutChart onlyImperatives={true} />
                                        </ResponsiveContainer>
                                    </Paper>
                                    <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center", width: `calc(50% - ${innerPaddingVal / 2}px)` }}>
                                        <ResponsiveContainer aspectRatio={"100%"} >
                                            <DoughnutChart onlyImperatives={false} />
                                        </ResponsiveContainer>
                                    </Paper>
                                </Box>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }}>
                                <Paper elevation={3} sx={{ padding: innerPadding }}>
                                    <ResponsiveContainer aspectRatio={"100%"} maxHeight={`calc(600px - ${innerPadding} * 3 )`}>
                                        <RadarChart chapterNumber={1} />
                                    </ResponsiveContainer>
                                </Paper>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }}>
                                <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center" }}>
                                    <ResponsiveContainer aspectRatio={"100%"}>
                                        <RadarChart chapterNumber={2} />
                                    </ResponsiveContainer>
                                </Paper>
                            </Box>
                            <Box sx={{ width: { xs: `100%`, sm: `calc(600px - ${innerPadding})` } }} >
                                <Paper elevation={3} sx={{ padding: innerPadding, display: "flex", justifyContent: "center" }}>
                                    <ResponsiveContainer aspectRatio={"100%"}>
                                        <RadarChart chapterNumber={3} />
                                    </ResponsiveContainer>
                                </Paper>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box >
        </>
    );
};

export default EvalDashboard;
