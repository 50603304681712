//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Switch from '@mui/material/Switch';
import FormControlLabel from "@mui/material/FormControlLabel";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FilterListIcon from '@mui/icons-material/FilterList';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { MaterialReactTable, useMaterialReactTable, getMRT_RowSelectionHandler } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { isWithinInterval, parseISO } from "date-fns";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { PaqEntryDialog } from "./PaqEntryDialog";
import { PaqEntriesDialog } from "./PaqEntriesDialog";
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getElapsedTimeInDays, getDateString } from '../../lib/lib';

//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { getPriorityScore, PaqEntry } from '../../models/Paq';
import { AuthorizationChecker } from "../../components/AuthorizationChecker";
import { AutocompleteWithAllOption } from "../../components/AutocompleteWithAllOption";

import "./PaqComponent.css";
import GridHelper from "../../helpers/GridHelper";
import { Badge, Chip } from "@mui/material";

const getTopToolbar = (table) => {
    let filters = table.getState().columnFilters;
    let column = table.getColumn("imperative_criterion");
    const handleExportData = async () => {
        await GridHelper.exportTableToExcel(table);
    }

    return (
        <>
            <FormControlLabel
                control={
                    <Switch
                        checked={!!filters.find(e => e.id === "imperative_criterion")}
                        onChange={(event) => {
                            column.setFilterValue((event.target.checked ? "oui" : ""))
                        }}
                    />
                }
                label="Critères impératifs"
            />
            <Button
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
            >
                Exporter vers excel
            </Button>
        </>
    )
}

const BottomToolbarRenderer = (table, establishment_id, setPaqEntryDialog, setPaqEntriesDialog) => {
    const { lists, isAuthorized } = useUser();
    let canWrite = isAuthorized({
        module: lists?.LICENSE_MODULE_VALUES["PAQ"],
        baseAction: lists?.BASE_ACTION_VALUES["WRT"],
    });
    let selectedRows = table.getSelectedRowModel().rows;
    const totalRows = table.getFilteredRowModel().rows.length;
    return (
        <Box sx={{ display: "flex", width: "100%", height: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <Typography>Nombre d'actions: {totalRows}</Typography>
            <Box sx={{ display: "flex", justifyContent: "end", gap: "12px" }}>
                {(canWrite && selectedRows?.length > 1) &&
                    <Button
                        onClick={() => setPaqEntriesDialog({ isOpen: true, editedPaqEntries: selectedRows.map(e => new PaqEntry({ ...e.original, establishment_id: establishment_id, assignee: e.original?.assignee?._id })), title: `Éditer ${selectedRows.length} actions` })}
                        variant="contained"
                    >
                        Éditer sélection ({selectedRows.length})
                    </Button>
                }
                {canWrite &&
                    <Button
                        onClick={() => setPaqEntryDialog({ isOpen: true, paqEntry: new PaqEntry({ clientAction: 'new', establishment_id: establishment_id }), title: 'Nouvelle action' })}
                        variant="contained"
                    >
                        Nouvelle action
                    </Button>
                }
            </Box>
        </Box>
    );
}

const PaqComponent = ({ paqEntries, setPaqEntries, establishment_id, mode, initialFilters, isPaqLoaded }) => {
    const theme = useTheme();
    const { lists, isAuthorized } = useUser();
    const paqEntryDialogEmptyState = { isOpen: false, paqEntry: undefined, title: '' }
    const [paqEntryDialog, setPaqEntryDialog] = useState(paqEntryDialogEmptyState);
    const paqEntriesDialogEmptyState = { isOpen: false, editedPaqEntries: undefined, title: '' }
    const [paqEntriesDialog, setPaqEntriesDialog] = useState(paqEntriesDialogEmptyState);
    const boxContainerRef = useRef(null);
    const [columnFilters, setColumnFilters] = useState([]);
    const hasInitialFiltersBeenSet = useRef(false);


    const getFilterSelectOptions = (accessKey) => {
        let arr = [];
        if (!listPaq) {
            return arr;
        }
        for (let key in listPaq[accessKey]) {
            arr.push(listPaq[accessKey][key].label);
        }
        return arr;
    }

    const dateFilterSelectOptions = useMemo(() => {
        let arr = [
            "dernier mois",
            "3 derniers mois",
            "6 derniers mois",
            "12 derniers mois",
            "prochain mois",
            "3 prochains mois",
            "6 prochains mois",
            "12 prochains mois"
        ];
        return arr;
    }, [])

    //if initialFilters is provided, setColumnFilters state accordingly, but only for first time value is defined
    useEffect(() => {
        if (Array.isArray(initialFilters) && hasInitialFiltersBeenSet.current === false) {
            setColumnFilters(initialFilters);
            hasInitialFiltersBeenSet.current = true;
        }
    }, [initialFilters])

    //return start and en dates depending on filterValue
    const getDatesInterval = (filterValue) => {
        let startInterval = new Date();
        let endInterval = new Date();
        switch (filterValue) {
            case "dernier mois":
                startInterval.setMonth(endInterval.getMonth() - 1);
                break;
            case "3 derniers mois":
                startInterval.setMonth(endInterval.getMonth() - 3);
                break;
            case "6 derniers mois":
                startInterval.setMonth(endInterval.getMonth() - 6);
                break;
            case "12 derniers mois":
                startInterval.setMonth(endInterval.getMonth() - 12);
                break;
            case "prochain mois":
                endInterval.setMonth(startInterval.getMonth() + 1);
                break;
            case "3 prochains mois":
                endInterval.setMonth(startInterval.getMonth() + 3);
                break;
            case "6 prochains mois":
                endInterval.setMonth(startInterval.getMonth() + 6);
                break;
            case "12 prochains mois":
                endInterval.setMonth(startInterval.getMonth() + 12);
                break;
        }
        startInterval.setHours(0, 0, 0, 0);
        endInterval.setHours(23, 59, 59, 999);
        return { startInterval, endInterval };
    }

    const dateFilterFunction = (row, id, filterValue) => {
        const { startInterval, endInterval } = getDatesInterval(filterValue);
        return isWithinInterval(parseISO(row.original[id]), { start: startInterval, end: endInterval });
    }

    const listPaq = useMemo(() => {
        let list = {
            objective: lists.OBJECTIVE_PAQ_LIST,
            source: lists.SOURCE_PAQ_LIST,
            status: lists.STATUS_PAQ_LIST,
            environmental_demand: lists.ENVIRONMENTAL_DEMAND_PAQ_LIST,
            information_system_handling: lists.INFORMATION_SYS_PAQ_LIST,
            activity_handling: lists.ACTIVITY_HANDLING_PAQ_LIST,
            people_risk: lists.PEOPLE_RISK_PAQ_LIST,
            customer_care: lists.CUSTOMER_CARE_PAQ_LIST,
            frequency: lists.FREQUENCY_PAQ_LIST,
            occurence: lists.OCCURENCE_PAQ_LIST,
            detectability: lists.DETECTABILITY_PAQ_LIST
        }
        return list;
    }, [lists])



    const columns = [
        {
            accessorKey: 'action_number',
            header: 'N°',
            enableGrouping: false,
            enableEditing: false,
            enableColumnFilterModes: false,
            filterFn: "equals",
            size: 150,
        },
        {
            id: 'objective',
            accessorFn: (row) => {
                const test = listPaq.objective?.[row?.objective]?.label || ""
                return test;
            },//Material React Table global filter will only work if empty string instead of undefined
            header: 'Objectif',
            filterVariant: "multi-select",
            filterSelectOptions: getFilterSelectOptions("objective"),
            enableColumnFilterModes: false,
            size: 250,
            muiTableHeadCellFilterTextFieldProps: { placeholder: "filtrer" },
        },
        {
            id: 'action',
            accessorFn: (row) => row?.action,
            Cell: ({ cell }) => <Box sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 10,
                textAlign: "left"
            }}>{cell.getValue()}</Box>,
            header: 'Action',
            enableGrouping: false,
            enableColumnFilterModes: false,
            filterFn: "contains",
            size: 250,
        },
        {
            id: 'source',
            accessorFn: (row) => {
                if (!Array.isArray(row?.source)) {
                    return [];
                }
                let str = "";
                let i = 1;
                for (let value of row.source) {
                    let tmp = listPaq.source[value]?.label;
                    if (tmp) {
                        if (i < row.source.length) str += tmp + ", ";
                        else str += tmp;
                    }
                    i++;
                }
                return str;
            },
            header: 'Source',
            filterVariant: "multi-select",
            filterSelectOptions: getFilterSelectOptions("source"),
            enableColumnFilterModes: false,
            size: 200,
        },
        {
            id: 'assignee',
            accessorFn: (row) => {
                return `${row?.assignee?.firstname} ${row?.assignee?.lastname} ${lists?.JOB_LIST[row?.assignee?.job]?.label}`
            },
            Cell: ({ cell, row }) => {
                const assignee = row?.original?.assignee;
                return (
                    <Box>
                        <Typography sx={{ fontSize: "inherit" }}>{assignee?.firstname} {assignee?.lastname}</Typography>
                        <Typography sx={{ fontSize: "inherit" }}>{lists?.JOB_LIST?.[assignee?.job]?.label}</Typography>
                    </Box>
                )
            },
            header: 'Pilote',
            enableColumnFilterModes: false,
            size: 200,
        },
        {
            id: 'imperative_criterion',
            accessorFn: (row) => row?.imperative_criterion ? "oui" : "non",
            header: 'Critère impératif',
            filterVariant: "select",
            filterSelectOptions: ["oui", "non"],
            enableColumnFilterModes: false,
            size: 200,
        },
        {
            accessorKey: 'priority',
            header: 'Priorité',
            enableGrouping: false,
            enableColumnFilterModes: false,
            filterFn: "greaterThan",
        },
        {
            id: 'created',
            Cell: ({ cell, row }) => {
                const created = row?.original?.created;
                return (
                    <Box>
                        <Typography sx={{ fontSize: "inherit" }}>Par {created?.by?.firstname} {created?.by?.lastname}</Typography>
                        <Typography sx={{ fontSize: "inherit" }}>Le {getDateString(created?.on)}</Typography>
                    </Box>
                )
            },
            accessorFn: (row) => {
                return `${row?.created?.by?.firstname} ${row?.created?.by?.lastname} ${getDateString(row?.created?.on)}`
            }, //necessary otherwise filter function won't work. However, text highlight not enabled when done this way.
            header: 'Créé',
            enableGrouping: false,
            enableColumnFilterModes: false,
        },
        {
            id: 'updated',
            Cell: ({ cell, row }) => {
                const updated = row?.original?.updated;
                return (
                    <Box>
                        <Typography sx={{ fontSize: "inherit" }}>Par {updated?.by?.firstname} {updated?.by?.lastname}</Typography>
                        <Typography sx={{ fontSize: "inherit" }}>Le {getDateString(updated?.on)}</Typography>
                    </Box>
                )
            },
            accessorFn: (row) => {
                return `${row?.created?.by?.firstname} ${row?.created?.by?.lastname} ${getDateString(row?.created?.on)}`
            },
            header: 'Mis à jour',
            enableGrouping: false,
            enableColumnFilterModes: false,
        },
        {
            id: 'start_date',
            Cell: ({ cell }) => getDateString(cell.getValue()),
            accessorFn: (row) => row?.start_date ? new Date(row.start_date) : "",
            header: 'Début',
            enableGrouping: false,
            enableColumnFilterModes: false,
            filterVariant: "select",
            filterSelectOptions: dateFilterSelectOptions,
            filterFn: "customDateFilterFunction"
        },
        {
            id: 'due_date',
            Cell: ({ cell }) => getDateString(cell.getValue()),
            accessorFn: (row) => row?.due_date ? new Date(row.due_date) : "",
            header: 'Échéance',
            enableGrouping: false,
            enableColumnFilterModes: false,
            filterVariant: "select",
            filterSelectOptions: dateFilterSelectOptions,
            filterFn: "customDateFilterFunction"
        },
        {
            id: 'completion_date',
            Cell: ({ cell }) => getDateString(cell.getValue()),
            accessorFn: (row) => row?.completion_date ? new Date(row.completion_date) : "",
            header: 'Clôture',
            enableGrouping: false,
            enableColumnFilterModes: false,
            filterVariant: "select",
            filterSelectOptions: dateFilterSelectOptions,
            filterFn: "customDateFilterFunction"
        },
        {
            id: 'delay',
            accessorFn: (row) => row?.due_date ? getElapsedTimeInDays(new Date(row?.due_date), row?.completion_date ? new Date(row?.completion_date) : undefined) : "",
            header: 'Retard',
            enableGrouping: false,
            enableColumnFilterModes: false,
            filterFn: "greaterThan",
        },
        {
            accessorKey: 'comment',
            header: 'Commentaires',
            enableGrouping: false,
            enableColumnFilterModes: false,
            filterFn: "contains",
        },
        {
            id: 'status',
            accessorFn: (row) => lists?.STATUS_PAQ_LIST[row?.status]?.label,
            header: 'Statut',
            filterVariant: "multi-select",
            filterSelectOptions: getFilterSelectOptions("status"),
            enableColumnFilterModes: false,
        },
        {
            accessorKey: 'reason',
            header: 'Raison',
            enableGrouping: false,
            enableColumnFilterModes: false,
            filterFn: "contains",
        },
    ];


    const table = useMaterialReactTable({
        columns,
        data: isPaqLoaded ? paqEntries : [],
        enableColumnOrdering: true,
        enableGlobalFilter: true, // Material React Table global filter will only work if empty string instead of undefined
        enableGrouping: false,
        columnFilterDisplayMode: "popover",
        enableRowSelection: true,
        muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({//enable row selection y a click anywhere on the line, outside the checkbox
            onClick: (event) =>
                getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event), //import this helper function from material-react-table
            sx: { cursor: 'pointer' },
        }),
        positionToolbarAlertBanner: 'hide', //hide alert toolbar that shows up when any row is selected
        enableEditing: isAuthorized({
            module: lists?.LICENSE_MODULE_VALUES["PAQ"],
            baseAction: lists?.BASE_ACTION_VALUES["WRT"],
        }),
        enableColumnPinning: true,
        enableColumnResizing: true,
        enableColumnFilterModes: true,
        filterFns: {
            customDateFilterFunction: (row, id, filterValue) => dateFilterFunction(row, id, filterValue),
        },
        editDisplayMode: "modal",
        enableDensityToggle: false,
        initialState: {
            density: 'comfortable',
            showColumnFilters: true,
            columnPinning: { right: ["mrt-row-actions"] },
            // columnFilters: Array.isArray(initialFilters) ? initialFilters : [],
            columnVisibility: {
                "imperative_criterion": false,
                "updated": false
            },
        },
        state: { showSkeletons: !isPaqLoaded, columnFilters: columnFilters },
        onColumnFiltersChange: setColumnFilters,
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: 'Editer', // change "Actions" to "Editer"
                Cell: ({ row }) => (
                    <IconButton
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setPaqEntryDialog({
                                isOpen: true,
                                title: `Modifier action n°${row?.original?.action_number}`,
                                paqEntry: { ...row.original },
                            })
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                ),
            },
        },
        muiTableBodyCellProps: { align: "center" },
        renderEmptyRowsFallback: () => (
            <div style={{
                width: "200px",
                textAlign: "center",
                position: "sticky",
                left: `calc(${boxContainerRef?.current?.offsetWidth / 2}px - 100px)`,
            }}>
                <h3><em>{paqEntries.length > 0 ? 'Aucun résultat' : `Plan d'actions vide`}</em></h3>
            </div>
        ),
        enableColumnActions: false,
        localization: MRT_Localization_FR,
        enablePagination: false,
        enableRowVirtualization: true,
        rowVirtualizerProps: {
            overscan: 5, // adjust the number or rows that are rendered above and below the visible area of the table
            estimateSize: () => paqEntries.length, // if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
        },
        renderTopToolbarCustomActions: ({ table }) => getTopToolbar(table),
        renderBottomToolbarCustomActions: ({ table }) => BottomToolbarRenderer(table, establishment_id, setPaqEntryDialog, setPaqEntriesDialog),
        muiTableContainerProps: ({ table }) => ({
            sx: {
                maxHeight: mode === "eval" ?
                    `calc(100vh - ${theme.appbarHeight} - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px - 64px - 64px)`
                    :
                    `calc(100vh - ${theme.appbarHeight} - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
            },
        }),
    });


    return (
        <Box ref={boxContainerRef} >
            <MaterialReactTable table={table} />
            {paqEntryDialog?.paqEntry &&
                <PaqEntryDialog
                    isOpen={paqEntryDialog.isOpen}
                    onClose={() => setPaqEntryDialog(paqEntryDialogEmptyState)}
                    title={paqEntryDialog.title}
                    paqEntry={paqEntryDialog.paqEntry}
                    setPaqEntries={(val) => setPaqEntries(val)}
                    listPaq={listPaq}
                />
            }
            {paqEntriesDialog?.editedPaqEntries?.length > 0 &&
                <PaqEntriesDialog
                    isOpen={paqEntriesDialog.isOpen}
                    onClose={() => setPaqEntriesDialog(paqEntriesDialogEmptyState)}
                    title={paqEntriesDialog.title}
                    editedPaqEntries={paqEntriesDialog.editedPaqEntries}
                    setPaqEntries={(val) => setPaqEntries(val)}
                    listPaq={listPaq}
                />
            }
        </Box>
    );
}

export { PaqComponent }