//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";
import { useImmer } from 'use-immer';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { Accordion, AccordionSummary } from "../../components/Accordion";
import { AutocompleteWithAllOption } from "../../components/AutocompleteWithAllOption";
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../config";
import { getElapsedTimeInDays, compareObjectKeyValuePairs } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { AccordionDetails, Autocomplete, Checkbox, Chip, Divider, FilledInput, FormHelperText, Input, InputBase, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Stack } from "@mui/material";
import { User, Permission } from "../../models/User";

//incremental number used in otherRoles.
//ensures that useform name is known at click validate.
//ensures that otherRoles map key is always the same during renders
let incrementalOtherRolesNumber = 0;
//avoid scroll at dialog opening
let scrollFlag = false;

const EstablishmentUsersDialog = ({ isOpen, onClose, title, user }) => {
    const theme = useTheme();
    const { lists, establishments, selectedEstablishment } = useUser();
    const { httpRequest } = useHttp();
    const { presentToast } = useToast();
    const { presentAlertDialog } = useDialog();
    const { control, handleSubmit, reset, getValues, unregister, watch, formState } = useForm({
        mode: "all"
    });
    //roles in other establishments. data structure: { increment: undefined, selectedEstablishments: [] }
    const [otherRoles, setOtherRoles] = useState([]);
    //add ref to bottom button to scroll to bottom when clicked
    const addButton = useRef(null);
    const { customer_hid } = useParams();


    const parseOtherPermissions = useCallback(() => {
        let permissionsTmp = [];
        if (user?.permissions) {
            for (let key of Object.keys(user?.permissions)) {
                if (key !== selectedEstablishment._id) {
                    let index = permissionsTmp.findIndex(e => e.role === user?.permissions[key]?.role)
                    if (index === -1) {
                        //if role was not found then we add it
                        permissionsTmp.push({
                            selectedEstablishments: [[...establishments].find(e => e._id === key)],
                            role: user?.permissions[key]?.role
                        })
                    } else {
                        //if role was found, then we add establishment to the array
                        permissionsTmp[index].selectedEstablishments.push([...establishments].find(e => e._id === key));
                    }
                }
            }
        }
        return permissionsTmp;
    }, [user]);

    useEffect(() => {
        reset();
        incrementalOtherRolesNumber = 0;
        //parse other permissions from user
        let permissionsTmp = parseOtherPermissions();
        //init otherRoles depending on what's in permissions
        let otherRolesTmp = [];
        for (incrementalOtherRolesNumber; incrementalOtherRolesNumber < permissionsTmp.length; incrementalOtherRolesNumber++) {
            otherRolesTmp.push({ increment: incrementalOtherRolesNumber, selectedEstablishments: permissionsTmp[incrementalOtherRolesNumber].selectedEstablishments })
        }
        setOtherRoles(otherRolesTmp);
    }, [user])

    useEffect(() => {
        //avoid scroll at dialog opening
        if (scrollFlag) {
            //scrolls to addButton (bottom) when addButton is clicked
            addButton?.current?.scrollIntoView({
                behavior: 'smooth',
            });
        }
        scrollFlag = true;
    }, [otherRoles])

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleClickCancel = () => {
        onClose();
    }

    const handleClickValidate = handleSubmit(async (data) => {

        //save password the right way, depending if user is created or modified
        let password = {
            is_temporary: user?.clientAction === "new" ? true : user?.password?.is_temporary,
            clear: data?.password
        }
        let formValues = { ...data };
        //override password
        formValues.password = password;
        //copy user then override all the key values of user with the ones from formValues
        let userTmp = new User({ ...user, ...formValues });
        //now handle permissions with mandatory role from current establishment
        let permissionsTmp = [];
        permissionsTmp.push({ establishment_id: selectedEstablishment._id, role: formValues.role.value });
        //then sanitize and push each valid permission in permissionTmp
        for (let otherRole of otherRoles) {
            //if both establishment and role fields are filled in
            if (formValues[`establishments_${otherRole.increment}`] && formValues[`role_${otherRole.increment}`]) {
                //then we remove selectAll value and push in permissionTmp
                for (let est of formValues[`establishments_${otherRole.increment}`]) {
                    permissionsTmp.push({
                        establishment_id: est._id,
                        role: formValues[`role_${otherRole.increment}`].value
                    })
                }
            }
        }
        let response = undefined;
        if (user?.clientAction === "new") {
            response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/user/create`,
                data: { user: userTmp, permissions: permissionsTmp },
                method: "post",
                headers: { "Content-type": "Application/json" },
                withCredentials: true
            }, true, true);
        } else {
            //TODO: compare old user and new user, and make API call only if there is a difference
            response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/user/update`,
                data: { user: userTmp, permissions: permissionsTmp },
                method: "post",
                headers: { "Content-type": "Application/json" },
                withCredentials: true
            }, true, true);
        }
        if (response?.status === 200) {
            presentToast({
                severity: "success",
                message: `${title}: succès`
            })
            if (user?.clientAction === "new") {
                presentAlertDialog({
                    message: (<div>Veuillez svp noter ces informations afin de les communiquer plus tard à l'intéressé.<br />
                        Identifiant: <strong>{response.data.username}</strong><br />
                        Mot de passe: <strong>{response.data.password}</strong>
                    </div>),
                    buttons: [{
                        text: "OK",
                        handler: () => {
                            onClose();
                        }
                    }]
                })
            }
            onClose();
        }
    })

    const rolesList = useMemo(() => {
        let arr = [];

        return arr;
    }, []);


    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                open={isOpen}
                aria-labelledby="responsive-dialog-title"
            // onClose={ }
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleClickValidate}
                >
                    <DialogContent sx={{ maxHeight: "calc(100vh - 190px)", overFlowY: "auto" }}>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Informations utilisateur</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="firstname"
                                        rules={{ required: "Prénom requis" }}
                                        defaultValue={user?.firstname || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Prénom"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="lastname"
                                        rules={{ required: "Nom requis" }}
                                        defaultValue={user?.lastname || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Nom"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="email"
                                        rules={{
                                            pattern: {
                                                value: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                                                message: "Adresse email invalide"
                                            }
                                        }}
                                        defaultValue={user?.email || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Email"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="job"
                                        rules={{ required: "Poste requis" }}
                                        defaultValue={user?.job || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Poste"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="job_groups"
                                        rules={{ required: "Groupe(s) métier(s) requis" }}
                                        defaultValue={user?.job_groups || []}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <AutocompleteWithAllOption
                                                        options={lists?.JOB_GROUP_LIST}
                                                        getOptionLabel={(option) => lists?.JOB_GROUP_LIST[option].label}
                                                        value={value}
                                                        onChange={onChange}
                                                        label="Groupe(s) métier(s)"
                                                        ref={ref}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name={"role"}
                                        rules={{ required: "Rôle requis" }}
                                        defaultValue={user?.permissions ? rolesList.find(e => e.value === user?.permissions[selectedEstablishment._id]?.role) : null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        options={rolesList}
                                                        value={value}
                                                        isOptionEqualToValue={(option, value) => {
                                                            return option?.value === value.value
                                                        }}
                                                        getOptionLabel={option => {
                                                            return option.label;
                                                        }}
                                                        onChange={(event, selectedOption) => onChange(selectedOption)}
                                                        renderInput={(params) => {
                                                            return <TextField {...params} label="Rôle" variant="standard" inputRef={ref} />
                                                        }}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    {(user?.password?.is_temporary || user?.clientAction === "new") &&
                                        <Controller
                                            name="password"
                                            defaultValue={user?.password?.clear || ""}
                                            rules={{ required: "Mot de passe requis", minLength: { value: 6, message: "6 caractères minimum" } }}
                                            control={control}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, value, ref } = field;
                                                return (
                                                    <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                        <TextField
                                                            label="Mot de passe"
                                                            variant="standard"
                                                            value={value}
                                                            onChange={event => onChange(event?.target?.value)}
                                                            inputRef={ref}
                                                            error={error?.message ? true : false}
                                                        />
                                                        <FormHelperText>{error?.message}</FormHelperText>
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                    }
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary>
                                <Typography>Rôle(s) autre(s) établissement(s)</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    {otherRoles.map((otherRole, otherRoleIndex) => {
                                        return (
                                            // careful the key MUST NOT CHANGE between renders otherwise the displayed values are lost
                                            <div key={otherRole.increment}>
                                                <Controller
                                                    name={"establishments_" + otherRole.increment}
                                                    rules={{}}
                                                    defaultValue={otherRole?.selectedEstablishments || []}
                                                    control={control}
                                                    render={({ field, fieldState: { error } }) => {
                                                        const { onChange, value, ref } = field;
                                                        //init options and filter establishments depending on other establishments values
                                                        //because user can only select ONE ROLE per establishment
                                                        let toBeFilteredEstablishments = [selectedEstablishment];
                                                        for (let i = 0; i < otherRoles.length; i++) {
                                                            if (i === otherRoleIndex) continue;
                                                            for (let j = 0; j < otherRoles[i]?.selectedEstablishments?.length; j++) {
                                                                toBeFilteredEstablishments.push(otherRoles[i]?.selectedEstablishments[j]);
                                                            }
                                                        }
                                                        const establishmentsOptions = [...establishments].filter(e => {
                                                            for (let tbf of toBeFilteredEstablishments) {
                                                                if (tbf._id === e._id) return false;
                                                            }
                                                            return true;
                                                        });

                                                        return (
                                                            <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                                <AutocompleteWithAllOption
                                                                    options={establishmentsOptions}
                                                                    getOptionLabel={option => option.name}
                                                                    isOptionEqualToValue={(option, value) => {
                                                                        return option._id === value._id
                                                                    }}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    ref={ref}
                                                                    label="Etablissement(s)"
                                                                />
                                                                <FormHelperText>{error?.message}</FormHelperText>
                                                            </FormControl>
                                                        )
                                                    }}
                                                />
                                                <Controller
                                                    name={"role_" + otherRole.increment}
                                                    rules={{}}
                                                    defaultValue={() => {
                                                        let tmp = user?.permissions ? rolesList.find(e => e.value === user?.permissions[otherRole?.selectedEstablishments[0]?._id]?.role) : null;
                                                        return tmp ? tmp : null;
                                                    }}
                                                    control={control}
                                                    render={({ field, fieldState: { error } }) => {
                                                        const { onChange, value, ref } = field;
                                                        return (
                                                            <>
                                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                                    <Autocomplete
                                                                        options={rolesList}
                                                                        value={value}
                                                                        getOptionLabel={option => {
                                                                            return option.label;
                                                                        }}
                                                                        isOptionEqualToValue={(option, value) => {
                                                                            return option?.value === value?.value
                                                                        }}
                                                                        onChange={(event, value) => onChange(value)}
                                                                        renderInput={(params) => {
                                                                            return <TextField {...params} label="Rôle" variant="standard" inputRef={ref} />
                                                                        }}
                                                                    />
                                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                                </FormControl>
                                                                <Button sx={{ paddingLeft: "0", paddingRight: "0" }} onClick={() => {
                                                                    let otherRolesTmp = [...otherRoles];
                                                                    let otherRolesIndexTmp = otherRolesTmp.findIndex(e => e.increment === otherRole.increment);
                                                                    otherRolesTmp.splice(otherRolesIndexTmp, 1);
                                                                    unregister(`establishments_${otherRole.increment}`);
                                                                    unregister(`role_${otherRole.increment}`);
                                                                    setOtherRoles(otherRolesTmp);
                                                                }}>Supprimer</Button>
                                                            </>
                                                        )
                                                    }}
                                                />
                                                <Divider sx={{ height: "1px", backgroundColor: "black", border: "none" }} />
                                            </div>
                                        );
                                    })}
                                    <Button
                                        ref={addButton}
                                        onClick={() => {
                                            let otherRolesTmp = [...otherRoles];
                                            incrementalOtherRolesNumber++;
                                            otherRolesTmp.push({ increment: incrementalOtherRolesNumber, selectedEstablishments: [] });
                                            setOtherRoles(otherRolesTmp);
                                        }}>Ajouter</Button>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClickCancel}
                        >
                            Annuler
                        </Button>
                        <Button
                            autoFocus
                            type="submit"
                        >
                            Valider
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}

export { EstablishmentUsersDialog }