import { createContext, useContext, useState } from 'react';


const LeftMenuContext = createContext();

const LeftMenuProvider = ({ children }) => {
    const [isLeftMenuOpened, setIsLeftMenuOpened] = useState(false);
    const leftMenuWidth = 300;

    return (
        <LeftMenuContext.Provider
            value={{
                isLeftMenuOpened,
                setIsLeftMenuOpened,
                leftMenuWidth
            }}>
            <>
                {children}
            </>
        </LeftMenuContext.Provider>
    );
}

const useLeftMenu = () => useContext(LeftMenuContext);

export { LeftMenuProvider, useLeftMenu };