//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { useHttp } from "../../../context/HttpProvider";
import { useToast } from "../../../context/ToastProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { Accordion, AccordionSummary } from "../../../components/Accordion";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../../config";
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { AccordionDetails, FormHelperText, Stack, Tooltip } from "@mui/material";


const BlobDialog = ({ isOpen, onClose, title, blob }) => {
    const theme = useTheme();
    const { selectedEstablishment } = useUser();
    const { httpRequest } = useHttp();
    const { presentToast } = useToast();
    const { control, handleSubmit, reset } = useForm({
        mode: "all"
    });
    const { customer_hid } = useParams();

    const [selectedFileName, setSelectedFileName] = useState('');

    const maxFileSize = 10; //in Mo

    const allowedFiletypes = [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "png",
        "jpg",
        "ppt",
        "pptx"
    ];

    //we use MIME to determine extension, as for each extension below, MIME matches ony ONE extension. And MIME is much more reliable that extension, that can be modified by a user.
    const mime_extension_match = [
        { mime: "application/pdf", ext: "pdf" },
        { mime: "application/msword", ext: "doc" },
        { mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", ext: "docx" },
        { mime: "application/vnd.ms-excel", ext: "xls" },
        { mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ext: "xlsx" },
        { mime: "application/vnd.ms-powerpoint", ext: "ppt" },
        { mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation", ext: "pptx" },
        { mime: "image/png", ext: "png" },
        { mime: "image/jpeg", ext: "jpg" },
    ];

    const isMimeAcceptable = (file) => {
        const isOK = mime_extension_match.map(e => e.mime).includes(file.type);
        return isOK;
    }

    const isFileSizeOK = (file) => {
        const isOK = file.size < maxFileSize * 1024 * 1024;
        return isOK;
    }

    const handleFileChange = (e) => {
        if (e.target.files.length > 0 && isMimeAcceptable(e.target.files[0])) {
            setSelectedFileName(e.target.files[0].name);  // Update the state with the name of the selected file
        } else {
            setSelectedFileName("");
        }
    };


    useEffect(() => {
        if (!isOpen) return;
        reset();
        setSelectedFileName("");
    }, [blob, isOpen])

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickCancel = () => {
        onClose();
    }

    const handleClickValidate = (event) => {
        event.stopPropagation();
        handleSubmit(async (data) => {

            const formData = new FormData();
            formData.append("file", data.file);
            formData.append("metaData", JSON.stringify({ userFileKey: data.userFileKey }));

            let response = undefined;
            if (blob?.clientAction === "new") {
                response = await httpRequest({
                    url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/user_files/new`,
                    data: formData,
                    method: "post",
                    withCredentials: true
                }, true, true);
            } else {
                //TODO: compare old blob and new blob, and make API call only if there is a difference
                response = await httpRequest({
                    url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/blobs/update`,
                    data: formData,
                    method: "post",
                    withCredentials: true
                });
            }
            if (response?.status === 200) {
                presentToast({
                    severity: "success",
                    message: `${title}: succès`
                })
                onClose();
            }
        })(event)
    }


    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                maxWidth="md"
                open={isOpen}
            >
                <DialogTitle>
                    {title}
                </DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleClickValidate}
                >
                    <DialogContent sx={{ maxHeight: "calc(100vh - 190px)", overFlowY: "auto" }}>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography sx={{ fontSize: "13px" }}>{`Types: ${allowedFiletypes.join(", ")}`}</Typography>
                                    <Typography sx={{ fontSize: "13px" }}>{`Taille max: ${maxFileSize}Mo`}</Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="userFileKey"
                                        rules={{ required: "Nom du fichier requis" }}
                                        defaultValue={blob?.userFileKeys?.[0]?.value || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Nom unique du fichier"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            );
                                        }}
                                    />
                                    <Controller
                                        name="file"
                                        rules={{
                                            required: blob?.clientAction === "new" ? "Fichier requis" : false,
                                            validate: value => {
                                                //here we check if filetype is allowed
                                                const isMimeOK = isMimeAcceptable(value);
                                                if (!isMimeOK) {
                                                    return `Types acceptés: ${allowedFiletypes.join(", ")}`
                                                }
                                                const isSizeOK = isFileSizeOK(value);
                                                if (!isSizeOK) {
                                                    return `Taille max acceptée: ${maxFileSize}Mo`;
                                                }
                                            }
                                        }}
                                        control={control}
                                        defaultValue=""
                                        render={({ field, fieldState: { error } }) => {
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <input
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.files[0]);
                                                            handleFileChange(e);
                                                        }}
                                                        onBlur={field.onBlur}
                                                        id="contained-button-file"
                                                    />
                                                    <label htmlFor="contained-button-file">
                                                        <Tooltip title={blob?.clientAction !== "new" ? "Sélectionnez un nouveau fichier si vous voulez le mettre à jour" : ""} arrow>
                                                            <Button variant="contained" startIcon={<CloudUploadIcon />} component="span">
                                                                Sélectionner fichier
                                                            </Button>
                                                        </Tooltip>
                                                    </label>
                                                    <FormHelperText color="red">{error?.message}</FormHelperText>
                                                    {selectedFileName && (
                                                        <Typography variant="subtitle2">
                                                            {selectedFileName}
                                                        </Typography>
                                                    )}
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClickCancel}
                        >
                            Annuler
                        </Button>
                        <Button
                            autoFocus
                            type="submit"
                        >
                            Valider
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}

export { BlobDialog }