import { useState } from "react";

function useNestedListOpenStateManager() {
    const [isOpen, setIsOpen] = useState({});

    const setInitialState = (initialState) => {
        setIsOpen(initialState);
    }

    const toggleItemOpen = (id) => {
        setIsOpen((prevOpenState) => ({
            ...prevOpenState,
            [id]: !prevOpenState[id],
        }));
    };

    return {
        setInitialState,
        isOpen,
        toggleItemOpen,
    };
}


export default useNestedListOpenStateManager;