//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useLocation, useParams, useOutletContext } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from "@mui/material/Drawer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useForm, FormProvider } from "react-hook-form";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { useToast } from "../../../context/ToastProvider";
import { useHttp } from "../../../context/HttpProvider";
import { useDialog } from "../../../context/DialogProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { DrawerRenderer } from "./DrawerRenderer";
import { ObservationRenderer } from "./ObservationRenderer";
import { getEmptyDelta, isEmptyDelta } from "../../../components/RichTextEditor/RichTextEditor";
import { AuthorizationChecker } from "../../../components/AuthorizationChecker";


//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../../config";
import { getScrollbarWidth } from "../../../lib/lib";
import { CriterionResponse } from "../../../models/CriterionResponse";
import { Response, UserResponse } from "../../../models/Response";
import { Observation } from "../../../models/Observation";
import EvalPaq from "./EvalPaq";
import { Divider, Tooltip } from "@mui/material";
import Approved from "../../../img/Approved";
import useVerticalScrollbarDetection from "../../../hooks/useVerticalScrollbarDetection";
import { BottomFixedToolbar } from "../../../components/BottomFixedToolbar";




const EvalCriterion = () => {

    const { httpRequest } = useHttp();
    const { isLoggedIn, user, selectedEstablishment, lists } = useUser();
    const theme = useTheme();
    const { presentToast } = useToast();
    const { presentAlertDialog } = useDialog();
    const [isLoading, setIsLoading] = useState(true);
    const { customer_hid } = useParams();
    const [scrollbarWidth, setScrollbarWidth] = useState(getScrollbarWidth());
    const [criterion, setCriterion] = useState(undefined);
    const [observations, setObservations] = useState(undefined);
    const [criterionResponse, setCriterionResponse] = useState(undefined);
    const [responses, setResponses] = useState(undefined);
    const [paqEntries, setPaqEntries] = useState(undefined);
    const [obsGroupValue, setObsGroupValue] = useState(null); //Tab value
    const [tabValues, setTabValues] = useState([]);
    const [forceRerender, setForceRerender] = useState(0);
    const RHF = useForm({
        mode: "all",
    });
    const { handleSubmit, reset } = RHF;

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const referential_ImID = params.get('referential_ImID');
    const criterion_ImID = params.get('criterion_ImID');

    const marginVal = theme.spacing(2); //stored ad "16px"
    const margin = parseInt(theme.spacing(2), 10); //stored as 16
    const bottomToolbarHeight = "64px";
    const innerPadding = theme.spacing(2);

    const mainColor = theme.palette.forest.primary.main;

    const [drawerState, setDrawerState] = useState({
        width: 440,
        previousWidth: null,
    });
    const userIsResizing = useRef(false);

    const [hasScrollbar, setHasScrollbar] = useState(false);

    const onScrollbarStatusChange = useCallback((isScrollbarPresent) => {
        if (isScrollbarPresent !== hasScrollbar) {
            setHasScrollbar(isScrollbarPresent);
        }
    }, [hasScrollbar]);

    const mainBoxRef = useVerticalScrollbarDetection(onScrollbarStatusChange);
    const evalBoxRef = useRef(null);

    const isCriterionApproved = criterionResponse?.status?.value === lists?.CRITERION_RESPONSE_STATUSES["APPROVED"];


    const handleData = (data) => {
        let { observations, ...criterionTmp } = data?.criterion;
        let observationsTmp = observations.map(o => new Observation(o));
        let criterionResponseTmp = new CriterionResponse(data?.criterionResponse);
        let responsesTmp = Object.entries(data?.responses).reduce((acc, [key, value]) => {
            if (!acc[key]) {
                acc[key] = {};
            }
            //value is an array of responses, 1 for each person if nb_responses = 3, only 1 element otherwise
            for (let el of value) {
                acc[key][el.sequence_number] = new Response(el);
            }
            return acc;
        }, {});

        //initialize RHF values before we set state and obsGroupValues (Tabs)
        let defaultValues = {};
        for (let observation of observationsTmp) {
            //store if establishment is concerned by this observation. If not, select NC as default value
            let establishmentIsConcernedByActivity = observation?.concerned_activities?.includes(selectedEstablishment?.activity);
            for (let sequence_number = 1; sequence_number <= observation.nb_responses; sequence_number++) {
                defaultValues[`text/${observation.immutable_id}/${sequence_number}`] = responsesTmp?.[observation.immutable_id]?.[sequence_number]?.text || { html: "", delta: getEmptyDelta() };
                defaultValues[`progress/${observation.immutable_id}/${sequence_number}`] = responsesTmp?.[observation.immutable_id]?.[sequence_number]?.progress?.value || 0;
                defaultValues[`level/${observation.immutable_id}/${sequence_number}`] = establishmentIsConcernedByActivity ? (responsesTmp?.[observation.immutable_id]?.[sequence_number]?.level?.value || null) : "0";
                defaultValues[`proof/${observation.immutable_id}/${sequence_number}`] = Array.isArray(responsesTmp?.[observation.immutable_id]?.[sequence_number]?.linked_proof_docs) ? responsesTmp?.[observation.immutable_id]?.[sequence_number]?.linked_proof_docs : [];
                defaultValues[`status/${observation.immutable_id}`] = responsesTmp?.[observation.immutable_id]?.[sequence_number]?.status || { value: lists?.RESPONSE_STATUSES["DRAFT"] };
            }
        }
        defaultValues["synthesis"] = criterionResponseTmp?.synthesis;
        defaultValues["strengths"] = (Array.isArray(criterionResponseTmp?.strengths) && criterionResponseTmp.strengths.length > 0) ? [...criterionResponseTmp?.strengths].map(item => item ? item : { html: "", delta: getEmptyDelta() }) : [{ html: "", delta: getEmptyDelta() }];
        defaultValues["weaknesses"] = (Array.isArray(criterionResponseTmp?.weaknesses) && criterionResponseTmp.weaknesses.length > 0) ? [...criterionResponseTmp?.weaknesses].map(item => item ? item : { html: "", delta: getEmptyDelta() }) : [{ html: "", delta: getEmptyDelta() }]
        defaultValues["synthesisStatus"] = criterionResponseTmp?.synthesisStatus || { value: lists?.FIELD_STATUSES["DRAFT"] };
        defaultValues["strengthsStatus"] = criterionResponseTmp?.strengthsStatus || { value: lists?.FIELD_STATUSES["DRAFT"] };
        defaultValues["weaknessesStatus"] = criterionResponseTmp?.weaknessesStatus || { value: lists?.FIELD_STATUSES["DRAFT"] };
        defaultValues["status"] = criterionResponseTmp?.status || { value: lists?.CRITERION_RESPONSE_STATUSES["DRAFT"] };
        reset(defaultValues);

        const tabValuesTmp = observations
            ?.filter(obs => {
                return obs.group
            })
            .reduce((unique, obs) => {
                if (!unique.some(o => o.group === obs.group)) {
                    unique.push(obs);
                }
                return unique;
            }, [])
            .map((obs) => {
                return <Tab key={obs.group} label={lists?.OBSERVATION_GROUP_LIST?.[obs.group]?.label} value={obs.group} />
            });
        if (tabValuesTmp?.length !== 1) {
            tabValuesTmp.unshift(<Tab key={"all"} label={"TOUS"} value={"all"} />);
        }
        setTabValues(tabValuesTmp);
        //prevent TAB value from changing at page reload
        if (!obsGroupValue) {
            setObsGroupValue(observationsTmp?.find(e => e?.group)?.group || "all");
        }
        setObservations(observationsTmp);
        setCriterion(criterionTmp);
        setCriterionResponse(criterionResponseTmp);
        setResponses(responsesTmp);
    }

    const fetchCriterionData = useCallback(async () => {
        setIsLoading(true);
        let response = await httpRequest({
            url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${referential_ImID}/criterion/${criterion_ImID}/fetch_data`,
            method: 'get',
            showLoading: false,
            withCredentials: true
        });
        if (response.status === 200) {
            //transform data / set states / populate form
            handleData(response?.data)
        }

        setIsLoading(false);
    }, [customer_hid, selectedEstablishment, referential_ImID, criterion_ImID])

    useEffect(() => {
        if (!isLoggedIn || !user) return;
        fetchCriterionData();
    }, [isLoggedIn, user, selectedEstablishment, customer_hid, referential_ImID, criterion_ImID])


    const handlePointerDown = () => {
        //used to apply transition if resize comes from drawer expand / collapse button
        userIsResizing.current = true;
        document.addEventListener('pointermove', handlePointerMove);
        document.addEventListener('pointerup', handlePointerUp);
    };

    const handlePointerMove = (e) => {
        e.preventDefault();
        let scrollBarWidthTmp = hasScrollbar ? scrollbarWidth : 0;
        const newWidth = window.innerWidth - e.clientX - margin - scrollBarWidthTmp;
        if (newWidth > 350 && newWidth < window.innerWidth - 400) {
            setDrawerState({
                width: newWidth,
                previousWidth: null
            });
        }
    };

    const handlePointerUp = () => {
        //used to apply transition if resize comes from drawer expand / collapse button
        userIsResizing.current = false;
        document.removeEventListener('pointermove', handlePointerMove);
        document.removeEventListener('pointerup', handlePointerUp);
    };


    const handleClickValidate = handleSubmit(async (data) => {

        //copy formValues
        let formValues = { ...data };

        //will store only modifiedResponses here
        let modifiedUserResponses = [];

        //compare initial responses to current values and store all modified responses in modifiedResponses
        for (let observation of observations) {
            for (let sequence_number = 1; sequence_number <= observation.nb_responses; sequence_number++) {
                //create new userResponse
                let userResponse = new UserResponse({
                    _id: responses?.[observation.immutable_id]?.[sequence_number]?._id,
                    sequence_number: sequence_number,
                    observation_immutable_id: observation.immutable_id,
                    text: formValues?.[`text/${observation.immutable_id}/${sequence_number}`],
                    progress: {
                        value: formValues?.[`progress/${observation.immutable_id}/${sequence_number}`],
                    },
                    level: {
                        value: formValues?.[`level/${observation.immutable_id}/${sequence_number}`],
                    },
                    linked_proof_docs: formValues?.[`proof/${observation.immutable_id}/${sequence_number}`],
                    status: formValues?.[`status/${observation.immutable_id}`],
                });

                //if there was no response registered in DB and response is not empty, save userResponse
                let responseExist = responses?.[observation.immutable_id]?.[sequence_number] !== undefined;
                let isUserResponseEmpty = userResponse.isEmpty();
                if (!responseExist && !isUserResponseEmpty) {
                    modifiedUserResponses.push(userResponse);
                    continue;
                } else if (!responseExist && isUserResponseEmpty) {
                    continue;
                } else {
                    //if there was a response registered in DB and response is modified, then save userResponse
                    let isModified = userResponse.isModified(responses?.[observation.immutable_id]?.[sequence_number]);
                    if (isModified) {
                        modifiedUserResponses.push(userResponse);
                    }
                }
            }
        }

        //create new userCriterionResponse, filter empty data
        let userCriterionResponse = {
            _id: criterionResponse._id,
            synthesis: formValues?.synthesis,
            strengths: formValues?.strengths?.filter(s => s && !isEmptyDelta(s.delta)) || [],
            weaknesses: formValues?.weaknesses?.filter(w => w && !isEmptyDelta(w.delta)) || [],
            synthesisStatus: formValues?.synthesisStatus,
            strengthsStatus: formValues?.strengthsStatus,
            weaknessesStatus: formValues?.weaknessesStatus,
            status: formValues?.status
        };

        //then call service to save all that needs to be saved
        let response = await httpRequest({
            url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${referential_ImID}/criterion/${criterion_ImID}/save_data`,
            data: { responses: modifiedUserResponses, criterionResponse: userCriterionResponse },
            method: "post",
            headers: { "Content-type": "Application/json" },
            withCredentials: true,
            showLoading: true
        });
        if (response.status === 200) {
            //transform / set data into states / repopulate form

            handleData(response.data);
            presentToast({
                severity: "success",
                message: `sauvegarde effectuée avec succès`
            });
        }
    });

    //returns true if all statuses are verified, false otherwise
    const areAllStatusesVerified = () => {

        if (!lists || isLoading) return false;

        //handle criterionResponse statuses
        if (criterionResponse?.synthesisStatus?.value !== lists.FIELD_STATUSES["VERIFIED"]) return false;
        if (criterionResponse?.strengthsStatus?.value !== lists.FIELD_STATUSES["VERIFIED"]) return false;
        if (criterionResponse?.weaknessesStatus?.value !== lists.FIELD_STATUSES["VERIFIED"]) return false;

        //handle responses statuses
        if (!observations || observations?.length === 0) return false;

        //arrange responses by key / value pairs in respKV like respKV[obs.immutable_id][sequence_number] = response.status.value
        // let respKV = {};
        // for (let response of responses) {
        //     if (!respKV[response.observation_immutable_id]) {
        //         respKV[response.observation_immutable_id] = {};
        //         respKV[response.observation_immutable_id][response.sequence_number] = response.status.value;
        //     } else {
        //         respKV[response.observation_immutable_id][response.sequence_number] = response.status.value;
        //     }
        // }

        //check that for each observation there is a response that has a VERIFIED status
        for (let obs of observations) {
            for (let sequence_number = 1; sequence_number <= obs.nb_responses; sequence_number++) {
                if (responses?.[obs.immutable_id]?.[sequence_number]?.status?.value !== lists?.RESPONSE_STATUSES["VERIFIED"]) {
                    return false;
                }
            }
        }
        return true;
    }

    const handleApprove = async () => {
        let response = await httpRequest({
            url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${referential_ImID}/criterion/${criterion_ImID}/approve`,
            method: "get",
            withCredentials: true,
        });
        if (response.status === 200) {
            //transform / set data into states / repopulate form

            handleData(response.data);
            presentToast({
                severity: "success",
                message: `sauvegarde effectuée avec succès`
            });
        }
    }

    const handleClickApprove = async () => {

        //check if all statuses are VERIFIED
        let statusCheckOK = areAllStatusesVerified();

        if (!statusCheckOK) {
            //if not, presentDialog explaining why criterionResponse cannot be APPROVED
            presentAlertDialog({
                title: (<div>Vérification nécessaire pour approuver</div>),
                message: (<div>
                    Afin d'approuver un critère, veuillez vérifier et sauvegarder:
                    <ul>
                        <li>tous les constats</li>
                        <li>la synthèse</li>
                        <li>les points forts</li>
                        <li>les points d'efforts</li>
                    </ul>
                </div>),
                buttons: [{
                    text: "J'ai compris",
                    type: "cancel"
                }]
            });
        } else {
            presentAlertDialog({
                title: (<div>Approuver le critère {criterion?.chapterData?.number}.{criterion?.objectiveData?.number}.{criterion?.number} ?</div>),
                message: (<div>Cette action réversible empêchera toute modification ultérieure de l'évaluation de ce critère.</div>),
                buttons: [
                    {
                        text: "Annuler",
                        type: "cancel"
                    },
                    {
                        text: "Approuver",
                        handler: async () => await handleApprove(),
                        type: "confirm"
                    }
                ]
            });
        }
    }

    const handleUnapprove = async () => {
        let response = await httpRequest({
            url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${referential_ImID}/criterion/${criterion_ImID}/unapprove`,
            method: "get",
            withCredentials: true,
        });
        if (response.status === 200) {
            //transform / set data into states / repopulate form

            handleData(response.data);
            presentToast({
                severity: "success",
                message: `sauvegarde effectuée avec succès`
            });
        }
    }

    const handleClickUnapprove = () => {
        presentAlertDialog({
            title: (<div>Révoquer l'approbation du critère {criterion?.chapterData?.number}.{criterion?.objectiveData?.number}.{criterion?.number} ?</div>),
            message: (<div>Cette action rendra de nouveau possible la modification de l'évaluation de ce critère.</div>),
            buttons: [
                {
                    text: "Annuler",
                    type: "cancel"
                },
                {
                    text: "Désapprouver",
                    handler: async () => await handleUnapprove(),
                    type: "confirm"
                }
            ]
        });
    }

    const getPaqInitialFilters = () => {
        let value = criterion?.objectivePaqMatch?.map(val => {
            return lists?.OBJECTIVE_PAQ_LIST?.[val]?.label
        });
        if (!value) {
            return undefined;
        }
        return [{ id: "objective", value: value }];
    }


    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: 'start',
                    alignItems: 'start',
                    width: '100%',
                    maxHeight: `calc(100% - ${bottomToolbarHeight})`,
                    background: '#f7f7f7',
                    gap: marginVal,
                    overflowY: "auto"
                }}
                component="form"
                onSubmit={handleClickValidate}
                ref={mainBoxRef}
            >
                {(isLoading || (!isLoading && criterion && criterionResponse)) &&
                    <>
                        {/* wrap the complete page with FormProvider to create a React-Hook-Form context that will provide all the RHF methods */}
                        < FormProvider {...RHF}>
                            <Box
                                sx={{
                                    p: innerPadding,
                                    background: 'white',
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    height: isLoading ? "50%" : "auto",
                                    margin: `${marginVal} 0 0 ${marginVal}`,
                                    width: `calc(100% - 3 * ${marginVal} - ${drawerState.width}px)`,
                                    transition: !userIsResizing.current ? 'width 0.3s ease-in-out' : "",
                                }}
                                ref={evalBoxRef}
                            >
                                {isLoading ?
                                    <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <CircularProgress size={48} color="primary" />
                                    </Box>
                                    :
                                    <>
                                        <Tabs
                                            value={obsGroupValue}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            sx={{
                                                position: "sticky",
                                                top: 0,
                                                height: "32px",
                                                zIndex: 900,
                                                background: 'white',
                                                margin: `0 -${marginVal} ${marginVal} -${marginVal}`,
                                                '& .MuiTabs-indicator': {// targets the line below selected Tab
                                                    backgroundColor: theme.palette.greenLemon.primary.main,
                                                },
                                                '& .Mui-selected': {// targets the selected Tab
                                                    color: theme.palette.greenLemon.primary.main,
                                                },
                                            }}
                                            onChange={(event, newValue) => setObsGroupValue(newValue)}
                                        >
                                            {tabValues}
                                        </Tabs>
                                        {/******************************** EVAL CONTENT ************************************************************************/}

                                        {observations?.map((obs, obsIndex) => {
                                            let establishmentIsConcernedByActivity = obs?.concerned_activities?.includes(selectedEstablishment?.activity);
                                            let obsGroupInTabValue = obsGroupValue === "all" ? true : obs.group === obsGroupValue;
                                            return (
                                                <div
                                                    key={obs.immutable_id}
                                                >
                                                    {establishmentIsConcernedByActivity &&

                                                        <ObservationRenderer
                                                            criterion={criterion}
                                                            observation={obs}
                                                            observationIndex={obsIndex}
                                                            activity={selectedEstablishment?.activity}
                                                            hidden={!obsGroupInTabValue}
                                                            isCriterionApproved={criterionResponse?.status?.value === lists?.CRITERION_RESPONSE_STATUSES["APPROVED"]}
                                                        />
                                                    }
                                                </div>
                                            )
                                        })}

                                        {/****************************************************************************************************************************/}
                                    </>
                                }
                            </Box>

                            {/********************************************** PAQ BOX **********************************************************/}

                            <Box sx={{
                                p: innerPadding,
                                background: 'white',
                                borderRadius: 2,
                                boxShadow: 3,
                                height: isLoading ? "50%" : "auto",
                                margin: `0 0 0 ${marginVal}`,
                                width: `calc(100% - 3 * ${marginVal} - ${drawerState.width}px)`,
                                transition: !userIsResizing.current ? 'width 0.3s ease-in-out' : "",
                            }}>
                                <AuthorizationChecker
                                    module={lists?.LICENSE_MODULE_VALUES["PAQ"]}
                                    baseAction={lists?.BASE_ACTION_VALUES["RDO"]}
                                    fallback={
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                                            Vous n'avez pas la permission d'accéder au PAQ. Votre administrateur peut vous aider.
                                        </Box>
                                    }
                                >
                                    <EvalPaq
                                        paqEntries={paqEntries}
                                        setPaqEntries={(val) => setPaqEntries(val)}
                                        initialFilters={getPaqInitialFilters()}
                                        isCriterionLoaded={criterion ? true : false}
                                    />
                                </AuthorizationChecker>
                            </Box>

                            {/********************************************************* DRAWER *************************************************************/}

                            <Drawer
                                variant="permanent"
                                anchor="right"
                                PaperProps={{
                                    sx: {
                                        width: drawerState.width,
                                        position: 'fixed',
                                        right: hasScrollbar ? `calc(${marginVal} + ${scrollbarWidth}px)` : marginVal,
                                        top: `calc(${marginVal} + ${theme.appbarHeight})`,
                                        height: `calc(100% - ${theme.appbarHeight} - 2 * ${marginVal} - ${bottomToolbarHeight})`,
                                        background: 'white',
                                        borderRadius: 2,
                                        boxShadow: 3,
                                        transition: !userIsResizing.current ? 'width 0.3s ease-in-out' : "",
                                        zIndex: 900
                                    }
                                }}
                            >

                                {/********************************************** RESIZE BOX FOR DRAWER **********************************************************/}

                                <Box
                                    onPointerDown={handlePointerDown}
                                    sx={{
                                        cursor: 'ew-resize',
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        bottom: 0,
                                        width: '10px'
                                    }}
                                />

                                {/********************************************** DRAWER EXPAND / COLLAPSE BUTTON ***************************************************/}

                                <Box sx={{ height: "48px" }}>
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: theme.spacing(1),
                                            right: '0',
                                            marginRight: theme.spacing(1),
                                            zIndex: 1,
                                            color: theme.palette.greenLemon.primary.main
                                        }}
                                        onClick={() => {
                                            if (drawerState.previousWidth === null) {
                                                let prev = drawerState.width;
                                                setDrawerState({
                                                    width: 50,
                                                    previousWidth: prev
                                                });
                                            } else {
                                                let width = drawerState.previousWidth;
                                                setDrawerState({
                                                    width: width,
                                                    previousWidth: null
                                                });
                                            }
                                        }}
                                    >
                                        {drawerState.previousWidth === null ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                    </IconButton>
                                </Box>

                                {/********************************************** DRAWER CONTENT ***************************************************/}

                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        padding: `0 ${innerPadding} ${innerPadding} ${innerPadding}`,
                                        //below to avoid content adjustment during collapse / expansion
                                        opacity: drawerState.previousWidth ? 0 : 1,
                                        visibility: drawerState.previousWidth ? "hidden" : "visible",
                                        transition: drawerState.previousWidth ? "opacity 0.5s" : "opacity 0.5s, visibility 0s 0.5s",
                                        overflowY: "auto"
                                    }}>
                                    {isLoading ?
                                        <Box sx={{ height: "inherit", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <CircularProgress size={48} color="primary" />
                                        </Box>
                                        :
                                        <DrawerRenderer
                                            isCriterionApproved={criterionResponse?.status?.value === lists?.CRITERION_RESPONSE_STATUSES["APPROVED"]}
                                        />
                                    }
                                </Box>

                            </Drawer>

                            {/*************************************************** BOTTOM TOOLBAR ********************************************************/}
                            <BottomFixedToolbar
                                sx={{
                                    p: `0 ${innerPadding} 0 ${innerPadding}`,
                                    borderTop: `2px solid ${mainColor}`
                                }}
                            >
                                <Box sx={{ display: "flex", width: "100%", height: "100%", justifyContent: "space-between", alignItems: "center", gap: innerPadding }}>
                                    <Box sx={{ display: "flex", width: "100%", height: "100%", justifyContent: "space-between", alignItems: "center", gap: innerPadding }}>
                                        {!isLoading && criterion &&
                                            <>
                                                <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", height: "100%" }}>
                                                    <Typography sx={{ color: "white", fontWeight: "bold", padding: "10px", backgroundColor: mainColor, borderRadius: "5px" }}>
                                                        {criterion?.chapterData?.number}.{criterion?.objectiveData?.number}.{criterion?.number}
                                                    </Typography>
                                                    <Tooltip title={criterion?.name}>
                                                        <Typography
                                                            sx={{
                                                                color: mainColor,
                                                                fontWeight: "bold",
                                                                paddingLeft: innerPadding,
                                                                display: '-webkit-box',
                                                                WebkitLineClamp: 2,
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                textAlign: "justify"
                                                            }}>
                                                            {criterion?.name}
                                                        </Typography>
                                                    </Tooltip>
                                                    {isCriterionApproved &&
                                                        <Box sx={{ height: "100%", aspectRatio: 1, width: "auto" }}>
                                                            <Approved />
                                                        </Box>
                                                    }
                                                </Box>

                                            </>
                                        }
                                    </Box>
                                    <AuthorizationChecker
                                        module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                        baseAction={lists?.BASE_ACTION_VALUES["WRT"]}
                                    >
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                    </AuthorizationChecker>
                                    <Box sx={{ display: "flex", gap: innerPadding }}>
                                        <AuthorizationChecker
                                            module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                            baseAction={lists?.BASE_ACTION_VALUES["APV"]}
                                        >
                                            <Button
                                                onClick={() => isCriterionApproved ? handleClickUnapprove() : handleClickApprove()}
                                                variant="contained"
                                                disabled={isLoading}
                                            >
                                                {isCriterionApproved ? "Désapprouver" : "Approuver"}
                                            </Button>
                                        </AuthorizationChecker>
                                        <AuthorizationChecker
                                            module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                            baseAction={lists?.BASE_ACTION_VALUES["WRT"]}
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                disabled={isLoading}
                                            >
                                                Sauvergarder
                                            </Button>
                                        </AuthorizationChecker>
                                    </Box>
                                </Box>
                            </BottomFixedToolbar>
                        </FormProvider>
                    </>
                }
            </Box >
        </>
    );
};

export default EvalCriterion;
