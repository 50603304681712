//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useLayoutEffect, useEffect, useRef, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Bar } from 'react-chartjs-2';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { usePaths } from "../../../context/PathsProvider";
import { useHttp } from "../../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../../config";


const BarChartAevActions = () => {

    const { lists, customer_hid, selectedEstablishment, isLoggedIn } = useUser();
    const { httpRequest } = useHttp();

    const [isLoading, setIsLoading] = useState(undefined);
    const [graphData, setGraphData] = useState(undefined);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const referential_ImID = params.get('referential_ImID');

    useLayoutEffect(() => {
        if (!isLoggedIn || !customer_hid || !selectedEstablishment || !referential_ImID) return;
        const fetchGraphData = async () => {
            setIsLoading(true);
            let response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${referential_ImID}/dashboard/eval_paq_stats`,
                method: 'get',
                showLoading: false,
                withCredentials: true
            });
            if (response.status === 200) {
                const responseTmp = response.data;
                let data = {};
                data.datasets = [
                    {
                        type: "bar",
                        // label: "Points forts et points d'efforts",
                        data: [responseTmp.AEV, responseTmp.ImpCrit],
                        backgroundColor: [
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                        ],
                        borderColor: [
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 99, 132, 1)',
                        ],
                        borderWidth: 1,
                    }
                ]
                data.labels = ["Tous critères", "Critères impératifs"];
                let maxVal = Math.max(...data?.datasets?.[0]?.data);
                data.maxYAxisValue = Math.ceil(maxVal * 1.1);
                setGraphData(data);
            }
            setIsLoading(false);
        }
        fetchGraphData();
    }, [customer_hid, selectedEstablishment, referential_ImID, isLoggedIn])

    const options = {
        scales: {
            y: { // 'y' for vertical scales in Chart.js 3
                beginAtZero: true,
                max: graphData?.maxYAxisValue || 100, // Ensures the y-axis scale goes from 0 to 100%
            },
        },
        plugins: {
            legend: {
                display: false, // Set to true if you want to display the legend
            },
            title: {
                display: true,
                text: "Actions en cours issues de l'auto-évaluation", // Your title text
                padding: {
                    top: 0,
                    bottom: 20
                },
                font: {
                    size: 18 // You can set the font size here
                },
                color: '#666' // You can set the title color
            },
        },
        layout: {
            padding: 0
        },
        responsive: true,
        maintainAspectRatio: true, // Allow the chart to scale in both dimensions
    };

    return (
        <>
            {isLoading &&
                <>
                    <Box sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <CircularProgress />
                    </Box>
                </>
            }
            {!isLoading && graphData &&
                <Box sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }} >
                    <Bar data={graphData} options={options} />
                </Box >
            }
            {!isLoading && !graphData &&
                <Typography>Une erreur est survenue</Typography>
            }
        </>
    );
};

export { BarChartAevActions };