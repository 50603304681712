import { compareObjectKeyValuePairs } from "../lib/lib";
import { UserActionInfo } from "./UserActionInfo";
import { getEmptyDelta } from "../components/RichTextEditor/RichTextEditor";

class CriterionResponse {
    constructor(cr) {
        this._id = cr?._id;
        this.establishment_id = cr?.establishment_id;
        this.criterion_immutable_id = cr?.criterion_immutable_id;
        this.referential_immutable_id = cr?.referential_immutable_id;
        this.progress = cr?.progress;
        this.grade = cr?.grade;
        this.synthesis = {
            html: cr?.synthesis?.html || "",
            delta: cr?.synthesis?.delta || getEmptyDelta(),
            created: new UserActionInfo(cr?.synthesis?.created)
        };
        this.synthesisStatus = cr?.synthesisStatus;
        this.strengths = cr?.strengths?.map((strength) => {
            return ({
                html: strength?.html || "",
                delta: strength?.delta || getEmptyDelta(),
                created: new UserActionInfo(strength?.created),
            })
        });
        this.strengthsStatus = cr?.strengthsStatus;
        this.weaknesses = cr?.weaknesses?.map((weakness) => {
            return ({
                html: weakness?.html || "",
                delta: weakness?.delta || getEmptyDelta(),
                created: new UserActionInfo(weakness?.created),
            })
        });
        this.weaknessesStatus = cr?.weaknessesStatus;
        this.status = cr?.status;
    }
};



export { CriterionResponse };