import * as React from "react"
const CheckedLocked = ({ checkColor, lockColor, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 150 150"
        {...props}
    >
        <path
            fill={checkColor}
            d="M38.984 56.348a8.894 8.894 0 0 0-.707 11.773l29.825 38a4.357 4.357 0 0 0 3.437 1.672h5.016a4.369 4.369 0 0 0 3.46-1.703l68.727-89.184c3.27-4.242 2.29-10.363-2.14-13.375a9.308 9.308 0 0 0-12.575 1.989L77.844 77.832c-1.672 2.148-4.88 2.266-6.7.246L51.876 56.684c-3.41-3.782-9.29-3.934-12.89-.336"
        />
        <path
            fill={checkColor}
            d="M134.336 73.41c0 32.73-26.629 59.36-59.36 59.36-32.73 0-59.355-26.63-59.355-59.36 0-32.726 26.625-59.355 59.356-59.355 12.972 0 24.984 4.187 34.761 11.273l8.653-11.133C105.875 4.98 90.809 0 74.977 0c-19.61 0-38.043 7.637-51.91 21.504-13.868 13.863-21.5 32.3-21.5 51.906 0 19.61 7.632 38.047 21.5 51.914 13.867 13.864 32.3 21.5 51.91 21.5 19.609 0 38.043-7.637 51.91-21.5 13.867-13.867 21.504-32.304 21.504-51.914 0-11.976-2.856-23.52-8.235-33.847l-9.742 12.64a59.016 59.016 0 0 1 3.922 21.207"
        />
        <path
            fill={lockColor}
            d="M87.621 36.047h-1.226v-5.871a11.544 11.544 0 0 0-3.371-8.023 11.57 11.57 0 0 0-5.809-3.133c-.73-.149-1.469-.23-2.215-.235a11.401 11.401 0 0 0-4.344.895 11.492 11.492 0 0 0-6.152 6.156c-.29.687-.512 1.394-.66 2.125-.153.73-.23 1.469-.239 2.215v5.87H62.38c-.258 0-.5.052-.734.145a1.902 1.902 0 0 0-1.184 1.773V55.23A1.908 1.908 0 0 0 61.645 57c.234.098.476.148.734.148H87.62c.254 0 .5-.05.734-.148a1.902 1.902 0 0 0 1.036-1.035c.097-.238.148-.48.148-.735V37.965c0-.254-.05-.5-.148-.735a1.843 1.843 0 0 0-.414-.62c-.18-.18-.387-.32-.622-.419a1.968 1.968 0 0 0-.734-.144Zm-20.18-5.871c0-.496.047-.985.145-1.473a7.48 7.48 0 0 1 1.129-2.723c.277-.414.59-.796.941-1.148.348-.348.73-.664 1.145-.937a7.48 7.48 0 0 1 2.722-1.13c.489-.097.98-.144 1.477-.144a7.72 7.72 0 0 1 2.875.606c.453.191.887.425 1.297.703.41.273.789.586 1.137.937a7.502 7.502 0 0 1 1.64 2.438 7.41 7.41 0 0 1 .605 2.871v5.87H67.442Zm9.477 18.992c0 .254-.05.5-.148.734a1.913 1.913 0 0 1-1.035 1.04c-.239.093-.481.144-.735.144s-.5-.05-.734-.145a1.93 1.93 0 0 1-1.04-1.039 1.894 1.894 0 0 1-.144-.734v-4.625c0-.254.047-.496.145-.73a1.879 1.879 0 0 1 1.039-1.04 1.897 1.897 0 0 1 1.468 0 1.902 1.902 0 0 1 1.036 1.04c.097.234.148.476.148.73Zm0 0"
        />
    </svg>
)
export default CheckedLocked