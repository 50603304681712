//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { useHttp } from "../../../context/HttpProvider";


//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { PaqComponent } from '../../Paq/PaqComponent';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//

//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { PaqEntry } from '../../../models/Paq';
import { getServerURL } from "../../../config";

const EvalPaq = ({ paqEntries, setPaqEntries, initialFilters, isCriterionLoaded }) => {

    const { httpRequest } = useHttp();
    const [isPaqLoaded, setIsPaqLoaded] = useState(false);
    const { lists, selectedEstablishment } = useUser();
    const navigate = useNavigate();
    const { customer_hid } = useParams();
    const theme = useTheme();

    useEffect(() => {
        if (!lists || !selectedEstablishment?._id) return;
        const fetchPaqData = async () => {
            setIsPaqLoaded(false);
            let response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/paq/fetch_paq/`,
                method: 'get',
                withCredentials: true,
                showLoading: false
            });
            if (response?.status === 200) {
                let tmp2 = response.data.paqEntries.map(paqEntry => new PaqEntry(paqEntry, lists));
                setPaqEntries(tmp2);
                setIsPaqLoaded(true);
            }
        }
        fetchPaqData();
    }, [lists, selectedEstablishment])

    return (
        <Box sx={{ width: '100%' }}>
            <PaqComponent
                establishment_id={selectedEstablishment._id}
                paqEntries={paqEntries}
                setPaqEntries={(val) => setPaqEntries(val)}
                mode="eval"
                initialFilters={initialFilters}
                isPaqLoaded={isPaqLoaded && isCriterionLoaded}
            />
        </Box>
    );
};

export default EvalPaq;