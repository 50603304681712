import { createContext, useContext, useState, useEffect } from 'react';
import { Toast } from "../components/Toast";

const ToastContext = createContext();

let id = 1;

const ToastProvider = ({ children }) => {
    const emptyToastState = { isOpen: false, severity: undefined, title: undefined, message: "", duration: undefined };
    // const [toastStates, setToastStates] = useState([]);
    const [toastState, setToastState] = useState(emptyToastState);

    // useEffect(() => {
    //     //clean closed toastStates
    //     let toastStatesTmp = [...toastStates];
    //     for (let i = 0; i < toastStatesTmp.length; i++) {
    //         if (!toastStatesTmp[i].isOpen) {
    //             toastStatesTmp.splice(i, 1);
    //         }
    //     }
    // }, [toastStates])

    // const presentToast = (props) => {
    //     setToastStates(toasts => [
    //         ...toasts,
    //         { ...props, id: id++ }
    //     ])
    // }

    // const dismissToast = (id) => {
    //     let toastStatesTmp = [...toastStates];
    //     let index = toastStatesTmp.findIndex(t => t.id === id);
    //     if (index !== -1) {
    //         toastStatesTmp[index] = emptyToastState;
    //     }
    // }

    const presentToast = (props) => {
        setToastState({ ...props, isOpen: true });
    }

    const dismissToast = () => {
        setToastState(toast => ({ ...toast, isOpen: false }));
    }

    return (
        <ToastContext.Provider
            value={{
                presentToast,
                dismissToast
            }}>
            <>
                {children}
                <Toast
                    isOpen={toastState.isOpen}
                    severity={toastState.severity}
                    title={toastState?.title}
                    message={toastState.message}
                    duration={toastState?.duration}
                    handleClose={dismissToast}
                />
                {/* {toastStates.map(toastState => {
                    return (
                        <Toast
                            key={toastState.id}
                            isOpen={toastState.isOpen}
                            severity={toastState.severity}
                            title={toastState?.title}
                            message={toastState.message}
                            duration={toastState?.duration}
                            id={toastState.id}
                            handleClose={(id) => dismissToast(id)}
                        />
                    )
                })} */}
            </>
        </ToastContext.Provider>
    );
}

const useToast = () => useContext(ToastContext);

export { ToastProvider, useToast };