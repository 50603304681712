//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useLayoutEffect, useEffect, useRef, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import ArchiveIcon from '@mui/icons-material/Archive';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import VerifiedIcon from '@mui/icons-material/Verified';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { usePaths } from "../../../context/PathsProvider";
import { useHttp } from "../../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//


//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../../config";
import { getDateString } from "../../../lib/lib";


const QuestEvaluatifData = () => {

    const { customer_hid, selectedEstablishment, isLoggedIn } = useUser();
    const { httpRequest } = useHttp();

    const [isLoading, setIsLoading] = useState(undefined);
    const [graphData, setGraphData] = useState(undefined);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const referential_ImID = params.get('referential_ImID');

    useLayoutEffect(() => {
        if (!isLoggedIn || !customer_hid || !referential_ImID) return;
        const fetchGraphData = async () => {
            setIsLoading(true);
            let response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${referential_ImID}/dashboard/quest_eval_date`,
                method: 'get',
                showLoading: false,
                withCredentials: true
            });
            if (response.status === 200) {
                setGraphData(response.data);
            }
            setIsLoading(false);
        }
        fetchGraphData();
    }, [customer_hid, referential_ImID, selectedEstablishment, isLoggedIn])


    return <>
        {isLoading &&
            <>
                <Box sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress />
                </Box>
            </>
        }
        {!isLoading && graphData &&
            <Tooltip
                title={<span>Version et dernière date de MAJ du questionnement évaluatif e-Qualiplus</span>}
                arrow
                TransitionComponent={Zoom}
                followCursor
            >
                <Box sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    paddingTop: "0px",
                    gap: "10%"
                }} >

                    <Typography sx={{ color: '#666', fontSize: "18", fontWeight: "bold" }}>
                        Questionnement évaluatif
                    </Typography>
                    <Chip sx={{ width: "150px", height: "30px", fontWeight: "bold", color: '#666' }} icon={<EventAvailableIcon />} label={getDateString(graphData.last_modified)} />
                    <Chip sx={{ width: "150px", height: "30px", fontWeight: "bold", color: '#666' }} icon={<VerifiedIcon />} label={`Version ${graphData.version}`} />

                </Box >
            </Tooltip>
        }
        {!isLoading && !graphData &&
            <Typography>Une erreur est survenue</Typography>
        }
    </>;
};



export { QuestEvaluatifData }