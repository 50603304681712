import { useRef, useEffect } from 'react';



const useVerticalScrollbarDetection = (onScrollbarStatusChange) => {
    const ref = useRef();

    useEffect(() => {
        const element = ref.current;

        const observer = new ResizeObserver(() => {
            const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
            onScrollbarStatusChange(hasVerticalScrollbar);
        });

        observer.observe(element);

        return () => observer.disconnect();
    }, [onScrollbarStatusChange]);

    return ref;
}

export default useVerticalScrollbarDetection;