
class User {
    constructor(u) {
        this.clientAction = u?.clientAction;
        this._id = u?._id;
        this.firstname = u?.firstname;
        this.lastname = u?.lastname;
        this.username = u?.username;
        this.type = {
            value: u?.type?.value,
        };
        this.email = {
            value: u?.email?.value,
        };
        this.status = {
            value: u?.status?.value,
        };
        this.job = u?.job;
        this.job_groups = {
            value: u?.job_groups?.value,
        };
        this.organization = u?.organization;
        this.password = {
            is_temporary: u?.password?.is_temporary,
            clear: u?.password?.clear
        };
    }
}

class Permission {
    constructor(p) {
        this.establishment_id = p?.establishment_id;
        this.role = p?.role;
    }
}

export { User, Permission };