import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from './UserProvider';


const PathsContext = createContext();

const PathsProvider = ({ children }) => {
    const location = useLocation();
    const { lists } = useUser();

    const paths = {
        login: {
            router: "/:customer_hid/login",
            app: ({ customer_hid }) => `/${customer_hid}/login`,
            title: "Connexion",
            accesses: []
        },
        updatePwd: {
            router: "/:customer_hid/update_pwd",
            app: ({ customer_hid }) => `/${customer_hid}/update_pwd`,
            title: "Mise à jour mot de passe",
            accesses: []
        },
        home: {
            router: "/:customer_hid/app/home",
            app: ({ customer_hid, establishment_id }) => `/${customer_hid}/app/home${establishment_id ? "?establishment_id=" + establishment_id : ""}`,
            title: "Accueil",
            accesses: []
        },
        establishmentUsers: {
            router: "/:customer_hid/app/admin/users",
            app: ({ establishment_id, customer_hid }) => `/${customer_hid}/app/admin/users?establishment_id=${establishment_id}`,
            title: 'Liste utilisateurs',
            accesses: lists?.LICENSE_MODULE_VALUES ? [{ module: lists.LICENSE_MODULE_VALUES["EADM"], baseAction: lists.BASE_ACTION_VALUES["RDO"] }] : undefined
        },
        evalList: {
            router: "/:customer_hid/app/eval/list",
            app: ({ establishment_id, customer_hid }) => `/${customer_hid}/app/eval/list?establishment_id=${establishment_id}`,
            title: "Liste des évaluations",
            accesses: lists?.LICENSE_MODULE_VALUES ? [{ module: lists.LICENSE_MODULE_VALUES["AEV"], baseAction: lists.BASE_ACTION_VALUES["RDO"] }] : undefined
        },
        evalDashboard: {
            router: "/:customer_hid/app/eval/dashboard",
            app: ({ establishment_id, customer_hid, referential_ImID }) => `/${customer_hid}/app/eval/dashboard?establishment_id=${establishment_id}&&referential_ImID=${referential_ImID}`,
            title: "Tableau de bord évaluation",
            accesses: lists?.LICENSE_MODULE_VALUES ? [{ module: lists.LICENSE_MODULE_VALUES["AEV"], baseAction: lists.BASE_ACTION_VALUES["RDO"] }] : undefined
        },
        evalCockpit: {
            router: "/:customer_hid/app/eval/cockpit",
            app: ({ establishment_id, customer_hid, referential_ImID }) => `/${customer_hid}/app/eval/cockpit?establishment_id=${establishment_id}&&referential_ImID=${referential_ImID}`,
            title: "Vue d'ensemble",
            accesses: lists?.LICENSE_MODULE_VALUES ? [{ module: lists.LICENSE_MODULE_VALUES["AEV"], baseAction: lists.BASE_ACTION_VALUES["RDO"] }] : undefined
        },
        evalCriterion: {
            router: "/:customer_hid/app/eval/criterion",
            app: ({ customer_hid, establishment_id, referential_ImID, criterion_ImID }) => `/${customer_hid}/app/eval/criterion?&&establishment_id=${establishment_id}&&referential_ImID=${referential_ImID}&&criterion_ImID=${criterion_ImID}`,
            title: "Evaluation critère",
            accesses: lists?.LICENSE_MODULE_VALUES ? [{ module: lists.LICENSE_MODULE_VALUES["AEV"], baseAction: lists.BASE_ACTION_VALUES["RDO"] }] : undefined
        },
        paq: {
            router: "/:customer_hid/app/paq",
            app: ({ establishment_id, customer_hid }) => `/${customer_hid}/app/paq?establishment_id=${establishment_id}`,
            title: "Plan d'actions",
            accesses: lists?.LICENSE_MODULE_VALUES ? [{ module: lists.LICENSE_MODULE_VALUES["PAQ"], baseAction: lists.BASE_ACTION_VALUES["RDO"] }] : undefined
        }
    }

    //returns current path object. Example: for "/login", returns login = {router: "/:customer_hid/login", app: ({ customer_hid }) => `/${customer_hid}/login`,title: "Connexion", accesses: []}
    const getCurrentPathObject = () => {
        if (location?.pathname) {
            const splittedLocation = location.pathname.split("/");
            for (let pathname in paths) {
                const splittedPath = paths[pathname].router.split("/");
                if (splittedLocation.length !== splittedPath.length) continue;
                for (let i = 0; i < splittedLocation.length; i++) {
                    if (splittedPath[i].startsWith(":")) {
                        if ((i === splittedLocation.length - 1)) return paths[pathname];
                        continue;
                    }
                    if (splittedPath[i] !== splittedLocation[i]) break;
                    if (i === splittedLocation.length - 1) return paths[pathname];
                }
            }
        }
        return {};
    }

    return (
        <PathsContext.Provider
            value={{
                paths,
                getCurrentPathObject,
            }}>
            <>
                {children}
            </>
        </PathsContext.Provider>
    );
}

const usePaths = () => useContext(PathsContext);

export { PathsProvider, usePaths };